import React, { useEffect, useContext } from 'react'
import { Box } from '@material-ui/core'

import { useStyles } from '../utils/styled'
import { Logos } from '../utils/images'
import AppContext from '../Services/AppContext'
import CenterBlockLayout from '../Components/Pages/CenterBlockLayout'

function RedirectCallback() {
  const classes = useStyles()
  const appContext = useContext(AppContext.Context)

  useEffect(() => {
    appContext.actions.navigateRedirectUrl()
  }, [])

  return (
    <CenterBlockLayout>
      <Box textAlign="center">
        <img
          src={Logos.poplive}
          alt="POP LIVE"
          className={`${classes.mb3}
          ${classes.imgResponsive}`}
        />
        <div>Redirecting...</div>
      </Box>
    </CenterBlockLayout>
  )
}

export default RedirectCallback
