import { useContext } from 'react'
import numeral from 'numeral'
import { makeStyles, Chip } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SessionContext from '../Services/SessionContext'
import { SessionViewersType, ThemeSessionType } from '../utils/type'

interface ViewersCount {
  viewersIncreament: number
  sessionId: string
}

const useStyles = makeStyles((theme: ThemeSessionType) => ({
  root: {
    ...theme.viewersCount.root,
    paddingLeft: '6px',
    paddingRight: '6px',
    height: '1.8em',
    fontSize: 12,
    borderRadius: '4px',
    fontWeight: 'normal',
    fontFamily: 'Arial'
  },
  icon: {
    marginRight: 12,

    [theme.breakpoints.down('sm')]: {
      marginRight: 0
    }
  },
  textNumber: {
    fontSize: 12
  }
}))

const ViewersCount = ({ viewersIncreament, sessionId }: ViewersCount) => {
  const classes = useStyles()
  const sessionContext = useContext(SessionContext.Context)
  const { eventViewers } = sessionContext
  const findSessionViewers = eventViewers.find(
    (sessionViewer: SessionViewersType) => sessionViewer.sessionId === sessionId
  )
  const sumViewers = (findSessionViewers?.viewers ?? 0) + viewersIncreament
  let viewer = (findSessionViewers?.viewers ?? 0) + viewersIncreament

  if (sumViewers > 999) {
    viewer = numeral(Math.floor(sumViewers)).format('0.0a')
  }

  return (
    <Chip
      icon={<FontAwesomeIcon icon={['far', 'users']} />}
      label={`${viewer}`}
      size="small"
      className={classes.root}
    />
  )
}

export default ViewersCount
