import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback
} from 'react'
import { navigate } from '@reach/router'
import _debounce from 'lodash/debounce'
import {
  setInterval as workerSetInterVal,
  clearInterval as workerClearInterval
} from 'worker-timers'
import { useQuery } from '@tanstack/react-query'
import { onSnapshot } from 'firebase/firestore'

import EventService from './EventService'
import AppContext from './AppContext'
import PathHelper from '../utils/PathHelper'
import { TimeIsBetweenStarAtAndEndAt } from '../utils/helper'
import {
  updateTimeVideoRemaining,
  getImageUrlByImageRef
} from './SessionService'

const EventContext = React.createContext()

// HOC
const EventContextProvider = ({ slug, children }) => {
  const appContext = useContext(AppContext.Context)
  const { timeRemainingSeconds, setTimeRemainingSeconds, ticketType } =
    appContext
  const [event, setEvent] = useState()
  const [sessions, setSessions] = useState()
  const [eventBanners, setEventBanners] = useState()
  const [membership, setMembership] = useState()
  const [reloadPolls, setReloadPolls] = useState(false)
  const [isNotFound, setIsNotFound] = useState(false)
  const [isShowBadgeOnPollTab, setIsShowBadgeOnPollTab] = useState(false)
  const [isPlayedVideo, setIsPlayedVideo] = useState(false)
  const playerRef = useRef(null)

  const tick = useRef()
  const [start, setStart] = useState(false)
  const time = useRef(timeRemainingSeconds) /// for track time remaining

  const updateTime = useCallback(
    _debounce(() => {
      if (ticketType?.onDemand === 'limited') {
        updateTimeVideoRemaining(time.current, appContext.currentUser)
      }
    }, 1000),
    [start]
  )

  const { data: timeCurrent } = useQuery({
    queryKey: ['timeCurrent'],
    queryFn: async () => {
      const getTime = await EventService.getTimeFromServer()
      return getTime.timeCurrent
    }
  })

  const { data: emojis = [] } = useQuery({
    queryKey: ['emojis', event?.slug],
    queryFn: async () => {
      const getEmojis = await EventService.getEmojisByEventSlug({
        slug: event?.slug
      })
      return getEmojis
    },
    enabled: !!event?.slug
  })

  useEffect(() => {
    let intervalId
    if (start && time.current >= 0 && ticketType?.onDemand === 'limited') {
      tick.current = workerSetInterVal(() => {
        setTimeRemainingSeconds((timer) => {
          time.current = timer - 1
          if (time.current === 0) {
            playerRef.current.pause()
            playerRef.current.exitFullscreen()
            setStart(false)
            updateTimeVideoRemaining(time.current, appContext.currentUser)
            workerClearInterval(tick.current)
            tick.current = null
          }
          return time.current
        })
      }, 1000)

      intervalId = workerSetInterVal(() => {
        if (time.current <= 0) {
          workerClearInterval(intervalId)
          intervalId = null
        }
        updateTimeVideoRemaining(time.current, appContext.currentUser)
      }, 5000)
    }
    return () => {
      if (tick.current) {
        workerClearInterval(tick.current)
        tick.current = null
        workerClearInterval(intervalId)
        intervalId = null
      }
    }
  }, [start])

  // Load the event
  useEffect(() => {
    const reload = async () => {
      await actions.loadEvent(slug)
    }

    if (timeCurrent) {
      reload()
    }
  }, [timeCurrent])

  useEffect(() => {
    let unsubscribe = () => {}

    if (event && event.id) {
      unsubscribe = onSnapshot(
        EventService.getEventDoc(event.id),
        async (doc) => {
          const eventById = doc.data()
          setSessions(eventById.sessions)
        }
      )
    }

    return () => {
      unsubscribe()
    }
  }, [event?.id])

  const actions = {
    loadEvent: async (slug) => {
      const event = await EventService.getEventWithSlug(slug)
      actions.setIsNotFound(event === undefined)

      if (event !== undefined) {
        actions.setIsNotFound(false)
        const membershipData = await EventService.getEventMembership(
          event.id,
          appContext.currentUser.uid
        )
        // User is not a member of this event, redirecting them to redeem page
        if (membershipData === undefined) {
          navigate(PathHelper.event.redeemTicketPath(slug))
          return false
        }
        const banners = event.banners

        setEventBanners(await EventService.getBannersURL(banners))
        setMembership(membershipData)
        setSessions(event.sessions)
        setEvent(event)
      }

      return event
    },
    onReadyVideo: (newPlayerInstance) => {
      playerRef.current = newPlayerInstance
    },
    onPlayerListerner: (player) => {
      player.on('play', function () {
        setStart(true)
        setIsPlayedVideo(true)
      })
      player.on('pause', function () {
        setStart(false)
        updateTime()
      })
      player.on('ended', () => {
        setStart(false)
        updateTime()
      })
      player.on('waiting', function () {
        updateTime()
      })
      player.on('playing', function () {
        updateTime()
      })
    },

    loadSession: (sessionId) => {
      if (sessions) {
        return sessions.find((session) => session.sessionId === sessionId)
      } else {
        return null
      }
    },

    loadWidgets: (sessionId) => {
      const defaultWidgets = {
        chat: true,
        poll: true,
        chapters: false
      }

      const sessions = actions.loadSession(sessionId)
      return sessions?.widgets ?? defaultWidgets
    },

    checkSessionPermission: (sessionId) => {
      return membership.sessions.includes(sessionId)
    },

    joinSession: (sessionId) => {
      if (actions.loadSession(sessionId)) {
        console.log('Joined!')
      }
    },

    leaveSession: (sessionId) => {
      if (actions.loadSession(sessionId)) {
        console.log('Left!')
      }
    },

    setIsNotFound: async (action) => {
      setIsNotFound(action === true)
    },

    setIsShowBadgeOnPollTab: async (action) => {
      setIsShowBadgeOnPollTab(action === true)
    },

    setStart: (action) => {
      setStart(action)
    },
    loadChapter: async (sessionId) => {
      const session = sessions.find(
        (session) => session.sessionId === sessionId
      )
      if (session?.chapters) {
        const chapterWithImageUrl = await Promise.all(
          session.chapters.map(async (chapter) => {
            if (chapter.imageRef) {
              const imageUrl = await getImageUrlByImageRef(chapter.imageRef)
              return {
                name: chapter.name,
                imageUrl: imageUrl,
                seconds: chapter.seconds
              }
            } else
              return {
                name: chapter.name,
                imageUrl: '',
                seconds: chapter.seconds
              }
          })
        )

        return chapterWithImageUrl
      } else return []
    },
    loadPollIdByStatus: (polls, status) => {
      const pollsByStatus = []
      if (polls.length === 0) return pollsByStatus
      polls.forEach((poll) => {
        const { openAndCloseOptions, startAt, endAt } = poll
        const isSpecificTime = openAndCloseOptions === 'specificTime'
        if (isSpecificTime) {
          const timer = TimeIsBetweenStarAtAndEndAt(startAt, endAt)
          if (status === 'active' && (timer.isBeforeStart || timer.isBetween)) {
            pollsByStatus.push(poll)
          } else if (status === 'ended' || timer.isEnded) {
            pollsByStatus.push(poll)
          }
        } else if (poll.status === status) {
          pollsByStatus.push(poll)
        }
      })

      return pollsByStatus
    },
    videoTimeSkip: (time) => {
      playerRef.current.currentTime(time)
    },
    handleReloadPolls: () => setReloadPolls((st) => !st),
    setTimeRemainingSeconds: (timeRemaining) =>
      setTimeRemainingSeconds(timeRemaining)
  }

  return (
    <EventContext.Provider
      value={{
        event,
        emojis,
        eventBanners,
        playerRef,
        sessions,
        membership,
        actions,
        isNotFound,
        reloadPolls,
        isShowBadgeOnPollTab,
        timeCurrent,
        timeRemainingSeconds,
        setStart,
        isPlayedVideo
      }}
    >
      {children}
    </EventContext.Provider>
  )
}

export default {
  Context: EventContext,
  Provider: EventContextProvider
}
