import React, { useEffect, useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'

import PollOption from './PollOption'

const customStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    borderRadius: 8,
    overflow: 'hidden',
    zIndex: 1,
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),

    '& #progressbar': {
      transition: 'width 2s'
    }
  },
  progressbar: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    zIndex: -1
  },
  common: theme.poll.progressbar.common,
  mySelected: theme.poll.progressbar.mySelected,
  topSelect: theme.poll.progressbar.topSelect,
  topSelectWithMySelected: theme.poll.progressbar.topSelectWithMySelected
}))

const ProgressLine = (params) => {
  const {
    choice,
    mySelected,
    topSelected,
    percentage,
    progressStyles,
    showVotedCount
  } = params

  const customClasses = customStyles()

  const [widths, setWidths] = useState(0)

  useEffect(() => {
    requestAnimationFrame(() => {
      setWidths(percentage)
    })
  }, [percentage])

  return (
    <Box className={`${customClasses.root} ${customClasses[progressStyles]}`}>
      <PollOption
        imageRef={choice.imageRef}
        label={choice.name}
        votedPercent={percentage}
        votedCount={choice.votedCount}
        showVotedPercent
        showVotedCount={showVotedCount}
        mySelected={mySelected}
        topSelected={topSelected}
      />

      <Box
        id="progressbar"
        width={`${widths}%`}
        className={customClasses.progressbar}
      />
    </Box>
  )
}

export default ProgressLine
