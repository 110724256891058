import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useStyles } from '../../../utils/styled'

const PollHeader = ({ icon, title }) => {
  const helperClasses = useStyles()
  return (
    <Box display="flex">
      {icon && (
        <Typography variant="body1" className={helperClasses.mr1}>
          <strong>
            <FontAwesomeIcon icon={['far', 'chart-bar']} />
          </strong>
        </Typography>
      )}
      <Typography variant="body1">
        <strong>{title}</strong>
      </Typography>
    </Box>
  )
}

export default PollHeader
