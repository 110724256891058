import { useState, useEffect, useContext, useMemo } from 'react'
import { Box, makeStyles, createStyles } from '@material-ui/core'

import EventContext from '../../Services/EventContext'
import DisabledWidget from './../DisabledWidgetState'
import PollsActive from './PollsActive'
import PollsEnded from './PollsEnded'
import useWindowDimensions from '../../hooks/useWindowDimensions'

const customStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'row'
      // overflow: 'hidden' //
    },
    pollsWrapper: {
      ...theme.chatBar.root,
      position: 'relative',
      flexGrow: 1,
      padding: '16px',
      [theme.breakpoints.down('sm')]: {
        overflowY: 'auto',
        height: ({ viedoHeight }) => `calc(100vh -  ${viedoHeight + 125}px)`
      }
    },
    banner: {
      height: ({ viedoHeight }) => `calc(100vh -  ${viedoHeight + 56 + 68}px)`,
      [theme.breakpoints.up('md')]: {
        height: 'calc(100vh -  271px) !important',
        overflowY: 'auto'
      }
    },
    header: {
      fontSize: '16px',
      fontWeight: 'bold',
      marginBottom: theme.spacing(2)
    }
  })
)

const LivestreamPoll = ({
  eventId,
  eventSlug,
  sessionId,
  polls,
  alreadyPresent
}) => {
  const [viedoHeight, setViedoHeight] = useState(0)
  const { width } = useWindowDimensions()
  const customClass = customStyles({ viedoHeight })
  const eventContext = useContext(EventContext.Context)
  const banners = eventContext?.event?.banners || []
  const widgets = eventContext.actions.loadWidgets(sessionId)

  const pollsActive = useMemo(() => {
    const sortPoll = eventContext.actions.loadPollIdByStatus(polls, 'active')
    return sortPoll
  }, [polls])

  const pollsEnded = useMemo(() => {
    const sortPoll = eventContext.actions.loadPollIdByStatus(polls, 'ended')
    return sortPoll
  }, [polls])

  const disabledPoll = !widgets.poll || alreadyPresent

  useEffect(() => {
    let unsubscribe = true
    if (unsubscribe) {
      const track = document.querySelector('#bg-state') //To access the div with class slide track
      if (track) {
        setViedoHeight(track.clientHeight)
      }
    }
  }, [width])

  return (
    <Box
      className={`${customClass.root} ${
        banners.length > 0 && customClass.banner
      }`}
    >
      <div className={customClass.pollsWrapper}>
        {disabledPoll ? (
          <DisabledWidget widget="poll" />
        ) : (
          <Box mt={1}>
            <PollsActive
              polls={pollsActive}
              eventId={eventId}
              eventSlug={eventSlug}
              sessionId={sessionId}
            />
            <PollsEnded
              polls={pollsEnded}
              eventId={eventId}
              eventSlug={eventSlug}
              sessionId={sessionId}
            />
          </Box>
        )}
      </div>
    </Box>
  )
}

export default LivestreamPoll
