import React, { useContext } from 'react'
import { Router } from '@reach/router'
import { Helmet } from 'react-helmet'

import CheckAuthenticated from './CheckAuthenticated'
import EventContainerLayout from '../Components/EventContainerLayout'
import LobbyPage from './EventPage/LobbyPage'
import LivestreamPage from './EventPage/LivestreamPage'
import EventContext from '../Services/EventContext'
import { Backdrop, CircularProgress } from '@material-ui/core'
import SessionContext from '../Services/SessionContext'
import PageNotFound from './404'

const EventPageInnerComponent = () => {
  const eventContext = useContext(EventContext.Context)
  const { event, isNotFound } = eventContext

  if (isNotFound) return <PageNotFound />

  return (
    <SessionContext.Provider event={event}>
      {!event ? (
        <Backdrop open={true} invisible={true}>
          <CircularProgress />
        </Backdrop>
      ) : (
        <EventContainerLayout>
          <Helmet>
            <title>{eventContext.event?.title}</title>
          </Helmet>
          <Router>
            <LobbyPage path="/" />
            <LivestreamPage path="/s/livestream/:sessionId" />
          </Router>
        </EventContainerLayout>
      )}
    </SessionContext.Provider>
  )
}

const EventPage = ({ slug }) => {
  return (
    <CheckAuthenticated>
      <EventContext.Provider slug={slug}>
        <EventPageInnerComponent />
      </EventContext.Provider>
    </CheckAuthenticated>
  )
}

export default EventPage
