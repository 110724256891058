import React from 'react'
import {
  makeStyles,
  ListItem,
  ListItemAvatar,
  ListItemText,
  createStyles
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import { ThemeSessionType } from '../../utils/type'

const useStyles = makeStyles((theme: ThemeSessionType) =>
  createStyles({
    root: {
      ...theme.chat.listItem
    },
    listItem: {
      padding: 0
    },
    listChatMessageWrapper: {
      alignItems: 'center',
      padding: '8px',
      gap: '8px'
    },
    listItemAvatar: {
      minWidth: 0
    }
  })
)

interface ChatSkeletonProps {
  style?: React.CSSProperties
}

const ChatSkeleton = ({ style }: ChatSkeletonProps) => {
  const classes = useStyles()
  return (
    <ListItem className={classes.listChatMessageWrapper} style={style}>
      <ListItemAvatar className={classes.listItemAvatar}>
        <Skeleton variant="circle" width={36} height={36} animation="wave" />
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </>
        }
      />
    </ListItem>
  )
}

export default ChatSkeleton
