import React, { useContext, useEffect } from 'react'
import AppContext from '../../Services/AppContext'
import { Grid, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { useStyles } from '../../utils/styled'
import { Logos } from '../../utils/images'

const SignInComponent = () => {
  const appContext = useContext(AppContext.Context)
  const classes = useStyles()
  useEffect(() => {
    // Autologin
    appContext.actions.signInAnonymously().then((res) => res)
  }, [appContext.currentEvent])

  const renderErrorMessages = (errors) => {
    if (errors) {
      return (
        <React.Fragment>
          <Alert variant="filled" severity="error">
            {errors.message}
          </Alert>
          <div className={classes.spacerXs}></div>
        </React.Fragment>
      )
    }
  }

  const renderEventName = () => {
    return 'Joining...'
  }

  return (
    <React.Fragment>
      <form>
        <Grid container spacing={1} direction="column">
          <Grid item className={classes.textCenter}>
            <img
              src={Logos.poplive}
              alt="POP LIVE"
              className={`${classes.mb3} ${classes.imgResponsive}`}
            />
            <p
              className={`${classes.fontWeightNormal} ${classes.mt0} ${classes.mb1}`}
            >
              &nbsp;
            </p>
            <Typography variant="h3">
              <strong>{renderEventName()}</strong>
            </Typography>
          </Grid>
          {renderErrorMessages(appContext.errors)}
        </Grid>
      </form>
    </React.Fragment>
  )
}

export default SignInComponent
