import { memo } from 'react'
import Carousel from 'react-material-ui-carousel'
import {
  Paper,
  makeStyles,
  useMediaQuery,
  useTheme,
  Box,
  createStyles
} from '@material-ui/core'
import { BannerType, ThemeSessionType } from '../../utils/type'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = makeStyles((theme: ThemeSessionType) =>
  createStyles({
    bannerWraper: {
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        borderRadius: '12px',
        ...theme.banners.wrapper
      },
      '& img': {
        position: 'absolute',
        maxWidth: '100%',
        width: '100%',
        height: 'auto',
        aspectRatio: '16/9',
        objectFit: 'cover'
      },
      '&::after': {
        display: 'block',
        content: '""',
        aspectRatio: '16/9'
      }
    },
    iconLink: {
      position: 'absolute',
      right: 8,
      bottom: 16,
      height: '24px',
      width: '24px',
      color: 'white',
      padding: 4,
      borderRadius: '100px',
      backgroundColor: 'rgba(0,0,0,0.25)',
      '& svg': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }
    }
  })
)

type CarouselBannersProps = {
  banners: BannerType[]
}

const CarouselBanners = ({ banners }: CarouselBannersProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const smSizeDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Carousel
      animation="slide"
      timeout={100}
      autoPlay={banners.length <= 1 ? false : true}
      navButtonsAlwaysInvisible={banners.length <= 1}
      className={classes.bannerWraper}
      indicators={false}
      navButtonsAlwaysVisible={smSizeDown}
    >
      {banners.map((banner) => (
        <BannerImage key={banner.id} banner={banner} />
      ))}
    </Carousel>
  )
}
type BannerImageProps = {
  banner: BannerType
}
const BannerImage = memo(({ banner }: BannerImageProps) => {
  const classes = useStyles()
  return (
    <Paper>
      {banner.link ? (
        <a href={banner.link} target="_blank" rel="noopener noreferrer">
          <Box>
            <img src={banner.url} alt="banner" />
            <Box className={classes.iconLink}>
              <FontAwesomeIcon icon={['far', 'external-link']} size="xs" />
            </Box>
          </Box>
        </a>
      ) : (
        <img src={banner.url} alt="banner" />
      )}
    </Paper>
  )
})

export default CarouselBanners
