import React, { useRef, useEffect, useState } from 'react'
import { makeStyles, createStyles } from '@material-ui/core'
import {
  VariableSizeList as List,
  ListChildComponentProps,
  ListOnItemsRenderedProps,
  ListOnScrollProps
} from 'react-window'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AutoSizer, { Size } from 'react-virtualized-auto-sizer'

import ChatSkeleton from './ChatSkeleton'
import DisabledWidget from '../DisabledWidgetState'
import ChatMessage from './ChatMessage'
import EmptyState from './EmptyState'

import { EventType, MessageType } from '../../utils/type'
import { variables } from '../../utils/styled'

type Props = {
  messages: MessageType[]
  disabled: boolean
  isEmptyState: boolean
  event: EventType
  sessionId: string
  isLoadingMoreMessages: boolean
  handleUnPinMessage: () => void
  setScroll: React.Dispatch<React.SetStateAction<number>>
  hasNextMessage: boolean
  getLoadMoreMessages: () => void
}

const customStyles = makeStyles((theme) =>
  createStyles({
    listRoot: {
      flexGrow: 1,
      padding: 0,
      display: 'flex',
      height: '100%'
    },
    buttonScrollToButtom: {
      position: 'absolute',
      right: 20,
      cursor: 'pointer',
      bottom: 10,
      border: 'none',
      borderRadius: '50%',
      width: 40,
      height: 40,
      background: variables.grayLighther,
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
        bottom: '20vh'
      }
    }
  })
)

export default function ChatMessageContainer({
  disabled,
  messages,
  isEmptyState,
  event,
  sessionId,
  handleUnPinMessage,
  hasNextMessage,
  isLoadingMoreMessages,
  getLoadMoreMessages
}: Props) {
  const customClasses = customStyles()
  const listRef = useRef<any>(null)
  const rowHeights = useRef<any>({})
  const listWrapperRef = useRef(null)
  const [bottomOffset, setBottomOffset] = useState(true)

  useEffect(() => {
    if (listRef.current) {
      clickToButtom()
    }
  }, [listRef.current])

  useEffect(() => {
    if (listRef.current?.scrollToItem && bottomOffset) {
      if (bottomOffset) {
        clickToButtom()
      }

      listRef.current?.resetAfterIndex(0)
    }
  }, [messages])

  const onScroll = (e: ListOnScrollProps): void => {
    if (e.scrollOffset < 100 && Object.keys(rowHeights.current).length !== 0) {
      if (hasNextMessage && !isLoadingMoreMessages) {
        getLoadMoreMessages()
        listRef.current?.scrollToItem(messages.length - 50, 'start')
      }
    }
  }

  const clickToButtom = () => {
    listRef.current.scrollToItem(messages.length - 1, 'end')
  }

  async function onItemsRendered({
    visibleStopIndex
  }: ListOnItemsRenderedProps) {
    if (messages.length - visibleStopIndex > 4) {
      setBottomOffset(false)
    } else if (messages.length - visibleStopIndex < 4) {
      setBottomOffset(true)
    }
  }

  if (disabled) return <DisabledWidget widget="chat" />

  function getRowHeight(index: number): number {
    return rowHeights.current[index] || 60
  }

  const Message = ({ index, style }: ListChildComponentProps) => {
    const rowRef = useRef<any>({})
    const msg = messages[index]

    useEffect(() => {
      if (rowRef.current) {
        const height = rowRef.current?.clientHeight as number

        setRowHeight(index, height)
      }
      // eslint-disable-next-line
    }, [rowRef])

    if (hasNextMessage && index === 0) {
      return <ChatSkeleton style={style} />
    }

    return (
      <ChatMessage
        key={msg.id}
        style={style}
        sessionId={sessionId}
        message={msg}
        handleUnPinMessage={handleUnPinMessage}
        listRef={listRef}
        event={event}
        rowRef={rowRef}
      />
    )
  }

  function setRowHeight(index: number, size: number) {
    listRef.current.resetAfterIndex(0)
    rowHeights.current = { ...rowHeights.current, [index]: size }
  }

  return (
    <>
      {isEmptyState ? (
        <EmptyState />
      ) : (
        <div className={customClasses.listRoot} ref={listWrapperRef}>
          <AutoSizer>
            {({ height, width }: Size) => (
              <List
                itemCount={messages.length}
                itemSize={getRowHeight}
                height={height}
                ref={listRef}
                width={width}
                onItemsRendered={onItemsRendered}
                onScroll={onScroll}
              >
                {Message}
              </List>
            )}
          </AutoSizer>
          {!bottomOffset && (
            <button
              className={customClasses.buttonScrollToButtom}
              onClick={clickToButtom}
            >
              <FontAwesomeIcon icon={['far', 'chevron-down']} />
            </button>
          )}
        </div>
      )}
    </>
  )
}
