import React from 'react'
import { Container, Grid, makeStyles, Box } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent'
  },
  container: {
    minHeight: '100vh'
  },
  formContainer: {
    minHeight: '100vh',
    padding: theme.spacing(5)
  }
}))

const CenterBlockLayout = (props) => {
  const classes = useStyles()

  return (
    <Box className={`${classes.root} ${props.className}`}>
      <Container
        className={`${classes.container} ${props.containerClassName}`}
        maxWidth="xl"
      >
        <Grid
          className={classes.formContainer}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={11} sm={8}>
            {props.children}
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default CenterBlockLayout
