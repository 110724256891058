import React, { useState } from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { useQuery } from '@tanstack/react-query'

import EventService from '../../Services/EventService'
import EventBlock from './EventBlock'
import BecomeToHostSection from './BecomeToHostSection'
import { variables, ButtonLinkPOPLIVE } from '../../utils/styled'
import PathHelper from '../../utils/PathHelper'
import { EventType } from '../../utils/type'

const customStyles = makeStyles((theme) => ({
  welcomeSectionWrapper: {
    background: variables.grayBase,
    height: '432px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '64px 24px',
    [theme.breakpoints.down('md')]: {
      padding: '64px 24px',
      height: 'auto'
    },
    [theme.breakpoints.down('md')]: {
      padding: '64px 14px',
      height: 'auto'
    }
  },
  welcomeText: {
    fontSize: '56px',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '36px'
    }
  },
  welcomeSecondeText: {
    color: variables.grayDark
  },
  eventListsWrapper: {
    color: variables.grayBase,
    background: 'white',
    width: '1080px',
    margin: '0 auto',
    padding: '100px 0',
    [theme.breakpoints.down('md')]: {
      margin: '0 24px',
      padding: '100px 0',
      width: 'auto'
    },
    [theme.breakpoints.down('xs')]: {
      margin: '0 16px',
      padding: '36px 0'
    }
  },
  eventBlockWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    gridColumnGap: '24px',
    gridRowGap: '32px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr 1fr'
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr 1fr'
    }
  },
  linkToEventWrapper: {
    '& svg': {
      color: variables.grayBase,
      opacity: '0.32'
    },
    '& a': {
      textDecoration: 'none',
      color: variables.grayBase
    }
  }
}))

interface ContainerProps {
  eventOnlineRef: React.MutableRefObject<HTMLElement | null>
  scrollToEventOnline: () => void
}

function Container({ scrollToEventOnline, eventOnlineRef }: ContainerProps) {
  const customClass = customStyles()
  const [currentPage, setCurrentPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(false)
  const [events, setEvents] = useState([])
  const {
    isLoading: loadingEvents,
    isFetching,
    isPreviousData
  } = useQuery({
    queryKey: ['events', currentPage],
    queryFn: async () => {
      const getEvents = await EventService.getAllEvents({
        page: currentPage,
        perPage: 10
      })
      setEvents((prev) => [...prev, ...(getEvents.events as [])])
      setHasNextPage(getEvents?.hasNextPage)
      return getEvents.events
    },
    keepPreviousData: true
  })

  const onLoadMore = async () => {
    setCurrentPage((page) => page + 1)
  }

  return (
    <React.Fragment>
      <Box className={customClass.welcomeSectionWrapper}>
        <Box mb="24px" textAlign="center" color="white">
          <Typography className={customClass.welcomeText}>
            Enjoy online events and get real experiences
          </Typography>
          <Typography variant="h4" className={customClass.welcomeSecondeText}>
            Amazing opportunities to have fun and learn.
          </Typography>
        </Box>
        <ButtonLinkPOPLIVE
          variant="contained"
          onClick={() => scrollToEventOnline()}
        >
          Explore Online Events
        </ButtonLinkPOPLIVE>
      </Box>
      <Box style={{ background: 'white' }}>
        <Box
          className={customClass.eventListsWrapper}
          {...{ ref: eventOnlineRef }}
        >
          <Box mb="32px">
            <Typography variant="h3">Online Events</Typography>
          </Box>
          <Box width="100%">
            {loadingEvents ? (
              <>
                <Skeleton variant="rect" width="100%" height={200} />
                <Skeleton variant="text" height={40} />
                <Skeleton variant="text" height={60} />
              </>
            ) : (
              <Box>
                <Box className={customClass.eventBlockWrapper}>
                  {events?.map((event: EventType) => {
                    return (
                      <Box
                        className={customClass.linkToEventWrapper}
                        key={event.uid}
                      >
                        <a
                          href={PathHelper.event.redeemTicketPath(event.slug)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <EventBlock event={event} />
                        </a>
                      </Box>
                    )
                  })}
                </Box>
                {isFetching && (
                  <Box mt="32px">
                    <Skeleton variant="rect" width="100%" height={200} />
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="text" height={60} />
                  </Box>
                )}
              </Box>
            )}
          </Box>
          {hasNextPage && !isFetching && !isPreviousData && (
            <Box
              display="flex"
              justifyContent="center"
              mt={{ xs: '36px', md: '64px' }}
            >
              <ButtonLinkPOPLIVE
                variant="contained"
                onClick={() => onLoadMore()}
              >
                Load More
              </ButtonLinkPOPLIVE>
            </Box>
          )}
        </Box>
      </Box>
      <BecomeToHostSection />
    </React.Fragment>
  )
}

export default Container
