import React, { memo } from 'react'
import { Grid } from '@material-ui/core'

import LiveCardItem from './LiveCardItem'

const LiveCards = ({ liveCards }) => {
  if (liveCards.length === 0) return null

  return (
    <Grid container spacing={2}>
      {liveCards.map((card, idx) => (
        <Grid item key={idx} xs={6} sm={3}>
          <LiveCardItem card={card} />
        </Grid>
      ))}
    </Grid>
  )
}

export default memo(LiveCards)
