import React, { useState } from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import moment from 'moment'

import {
  UseInterval,
  FormatTimeNumber,
  ConvertTimeStamp
} from '../../../utils/helper'

const useStyles = makeStyles((theme) => ({
  timer: theme.poll.timer
}))

const Countdown = ({ endTime }) => {
  const classes = useStyles()
  const [time, setTime] = useState()

  const convertTime = () => {
    let then = moment(ConvertTimeStamp(endTime).seconds * 1000)
    let now = moment().valueOf()
    let time = moment.duration(then - now)
    let seconds = time.seconds()
    let minutes = time.minutes()
    let hours = time.hours()
    let days = time.days()

    if (days > 0) {
      return then.format('DD MMMM YYYY - HH:mm')
    } else {
      return `${FormatTimeNumber(hours)} : ${FormatTimeNumber(
        minutes
      )} : ${FormatTimeNumber(seconds)}`
    }
  }

  UseInterval(async () => {
    setTime(convertTime())
  }, 1000)

  return (
    <React.Fragment>
      {time ? (
        <Typography variant="caption" className={classes.timer}>
          Poll will close in {time}
        </Typography>
      ) : (
        <Skeleton width="60%" />
      )}
    </React.Fragment>
  )
}

export default Countdown
