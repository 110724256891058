import React from 'react'
import { Divider } from '@material-ui/core'

import { useStyles } from '../../utils/styled'
import { PollEmptyState } from './Components'
import PollCard from './PollCard'

const PollsActive = (props) => {
  const { eventId, eventSlug, sessionId, polls } = props
  const classes = useStyles()

  if (polls.length === 0) return <PollEmptyState />

  return (
    <React.Fragment>
      {polls.length > 0 ? (
        <React.Fragment>
          {polls.map((poll) => (
            <PollCard
              key={poll.id}
              eventId={eventId}
              eventSlug={eventSlug}
              sessionId={sessionId}
              poll={poll}
            />
          ))}
          <Divider className={`${classes.mt3} ${classes.mb3}`} />
        </React.Fragment>
      ) : (
        <PollEmptyState />
      )}
    </React.Fragment>
  )
}

export default PollsActive
