import React from 'react'
import { Snackbar, makeStyles } from '@material-ui/core'
import { Alert, AlertProps } from '@material-ui/lab'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { variables } from '../../utils/styled'

interface AlertComponentProps extends AlertProps {
  children: React.ReactNode
  open: boolean
  duration?: number
  handleCloseAlert: () => void
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  alertWrapper: {
    position: 'relative',
    zIndex: 99
  },
  warningClasses: {
    backgroundColor: variables.brandWarningLight,
    color: variables.primaryOrange
  }
}))

export default function AlertComponent({
  children,
  severity,
  open,
  handleCloseAlert,
  duration = 3000
}: AlertComponentProps) {
  const useClasses = useStyles()
  return (
    <Snackbar
      classes={{
        root: useClasses.alertWrapper
      }}
      autoHideDuration={duration}
      open={open}
      onClose={handleCloseAlert}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <Alert
        iconMapping={{
          warning: (
            <FontAwesomeIcon icon={['far', 'exclamation-triangle']} size="xs" />
          )
        }}
        classes={{
          root: useClasses.root,
          standardWarning: useClasses.warningClasses
        }}
        severity={severity}
      >
        {children}
      </Alert>
    </Snackbar>
  )
}
