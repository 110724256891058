import React from 'react'
import { makeStyles, Box } from '@material-ui/core'
import { Skeleton as MuiSkeleton } from '@material-ui/lab'
import { useStyles } from '../../../utils/styled'

const skeletonStyles = makeStyles({
  image: {
    borderRadius: '8px'
  }
})

const Skeleton = ({
  image,
  imgWidth = '65px',
  imgHeight = '65px',
  textWidth = '60%',
  textHeight = '40%'
}) => {
  const helperClasses = useStyles()
  const skeletonClasses = skeletonStyles()

  return (
    <Box width="100%" height="100%" display="flex" alignItems="center">
      {image && (
        <MuiSkeleton
          variant="rect"
          width={imgWidth}
          height={imgHeight}
          className={`${skeletonClasses.image} ${helperClasses.mr2}`}
        />
      )}

      <MuiSkeleton
        width={textWidth}
        height={textHeight}
        className={helperClasses.mr1}
      />
    </Box>
  )
}

export default Skeleton
