import React from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'
import {
  FormatRelativeTime,
  TimeIsBetweenStarAtAndEndAt
} from '../../../utils/helper'

import Countdown from './Countdown'

const customStyles = makeStyles((theme) => ({
  question: {
    marginBottom: theme.spacing(1)
  },
  description: theme.poll.description,
  timer: theme.poll.timer
}))

const QuestionAndDescription = ({ poll }) => {
  const customClasses = customStyles()

  const { question, description, startAt, endAt } = poll
  const { isBetween, isEnded } = TimeIsBetweenStarAtAndEndAt(startAt, endAt)

  const renderTimeOrCountdown = () => {
    let time = null

    if (startAt && endAt) {
      if (isEnded) {
        time = `Closed ${FormatRelativeTime(endAt)}`
      } else if (isBetween) {
        time = <Countdown endTime={endAt} />
      }
    }

    return time ? (
      <Box mt={1} mb={2}>
        <Typography variant="caption" className={customClasses.timer}>
          {time}
        </Typography>
      </Box>
    ) : null
  }

  return (
    <Box mb={2}>
      <Typography variant="h5" className={customClasses.question}>
        {question}
      </Typography>

      {description && (
        <Typography variant="caption" className={customClasses.description}>
          {description}
        </Typography>
      )}

      {startAt && endAt && renderTimeOrCountdown()}
    </Box>
  )
}

export default QuestionAndDescription
