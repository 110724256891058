import React, { useContext, useEffect, useRef } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import AppContext from '../../Services/AppContext'
import EventContext from '../../Services/EventContext'
import SessionChatService from '../../Services/SessionChatService'
import ChatBar from './ChatBar'
import PinMessage from './PinMessage'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import ChatMessageContainer from './ChatMessageContainer'
import useChatMessages from '../../hooks/useChatMessages'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    height: ({ height }) => `calc(100vh -  ${height}px)`,
    [theme.breakpoints.down('sm')]: {
      height: ({ viedoHeight }) => `calc(100vh -  ${viedoHeight + 125}px)`
    }
  },
  autoSizerWrapper: {
    ...theme.chatBar.root,
    flexGrow: 1,
    height: ({ height }) => `calc(100vh -  ${height}px)`,
    [theme.breakpoints.down('sm')]: {
      height: ({ pinMessage }) => {
        return `calc(100% -  ${pinMessage ? 138 : 48}px)`
      }
    }
  },
  pinMessage: {
    position: 'fixed',
    bottom: 60,
    width: 350,
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      padding: '0 8px'
    }
  }
}))

const ChatMessages = ({
  event,
  sessionId,
  messages,
  disabled,
  getLoadMoreMessages,
  isLoadingMoreMessages,
  hasNextMessage,
  pinMessage,
  handleUnPinMessage
}) => {
  const classes = useStyles({ pinMessage })
  const isEmptyState = messages.length === 0
  return (
    <React.Fragment>
      <ChatMessageContainer
        messages={messages}
        disabled={disabled}
        isEmptyState={isEmptyState}
        event={event}
        sessionId={sessionId}
        handleUnPinMessage={handleUnPinMessage}
        hasNextMessage={hasNextMessage}
        isLoadingMoreMessages={isLoadingMoreMessages}
        getLoadMoreMessages={getLoadMoreMessages}
      />

      {pinMessage && (
        <Box className={classes.pinMessage}>
          <PinMessage
            pinMessage={pinMessage}
            sessionId={sessionId}
            handleUnPinMessage={handleUnPinMessage}
          />
        </Box>
      )}
    </React.Fragment>
  )
}

const LivestreamChat = ({ sessionId, authenticated, alreadyPresent }) => {
  const appContext = useContext(AppContext.Context)
  const eventContext = useContext(EventContext.Context)
  const { event, eventBanners: banners } = eventContext
  const { currentUser: user, currentProfile: profile, ticketRole } = appContext
  const viedoHeightRef = useRef(0)
  const { width } = useWindowDimensions()
  const isHost = ticketRole === 'host' || ticketRole === 'invisibleHost'
  const {
    chatMessages,
    hasNextMessage,
    isLoadingMoreMessages,
    getLoadMoreMessages,
    pinMessage,
    handleUnPinMessage
  } = useChatMessages(authenticated, sessionId, event)

  useEffect(() => {
    const track = document.querySelector('#bg-state') //To access the div with class slide track
    if (track) {
      viedoHeightRef.current = track.clientHeight
    }
  }, [width])

  const calHeightLiveStremChat = () => {
    let height = 137
    if (banners.length > 0) {
      height += 200
    }
    if (pinMessage) {
      height += 80
    }
    return height
  }
  const classes = useStyles({
    height: calHeightLiveStremChat(),
    pinMessage,
    viedoHeight: viedoHeightRef.current
  })

  const widgets = eventContext.actions.loadWidgets(sessionId)

  const handleSend = async (message) => {
    await SessionChatService.createMessage(
      sessionId,
      user,
      message,
      profile,
      ticketRole,
      event.id
    )
  }

  const chatMessagesSession = !isHost
    ? chatMessages.filter((message) => !message.hidden)
    : chatMessages

  return (
    <Box className={classes.root}>
      <Box className={classes.autoSizerWrapper}>
        <ChatMessages
          hasNextMessage={hasNextMessage}
          event={event}
          sessionId={sessionId}
          messages={!alreadyPresent ? chatMessagesSession : []}
          disabled={!widgets.chat || alreadyPresent}
          getLoadMoreMessages={getLoadMoreMessages}
          isLoadingMoreMessages={isLoadingMoreMessages}
          pinMessage={!alreadyPresent ? pinMessage : undefined}
          handleUnPinMessage={handleUnPinMessage}
        />
        {widgets.chat && (
          <ChatBar
            onSend={!alreadyPresent ? handleSend : () => {}}
            disabled={!widgets.chat || alreadyPresent}
          />
        )}
      </Box>
    </Box>
  )
}

export default LivestreamChat
