import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useStyles as helperStyles } from '../../utils/styled'

const useStyles = makeStyles((theme) => ({
  root: {
    transform: 'translate(-50%, -50%)',
    top: '50%',
    position: 'relative',
    left: '50%',
    height: 'calc(100vh - 20%)',
    width: '80%',
    backgroundColor: '#fff',
    overflow: 'hidden',
    borderRadius: '8px',

    [theme.breakpoints.down('xs')]: {
      top: 0,
      left: 0,
      transform: 'translate(0, 0)',
      width: '100% !important',
      height: 'auto',
      borderRadius: 0
    }
  },
  formLayout: {
    padding: '32px 48px',

    '& .max-width-layout': {
      maxWidth: '450px',
      margin: 'auto auto'
    },

    [theme.breakpoints.down('xs')]: {
      padding: '32px 0'
    }
  },
  signInLayout: {
    backgroundColor: '#FFFFFF',
    height: '100vh'
  }
}))

const SignInUpLayout = (props) => {
  const classes = useStyles()
  const helperClasses = helperStyles()

  return (
    <div className={`${classes.signInLayout}`}>
      {false && (
        <div
          style={{
            backgroundImage: `url(${'https://i.pinimg.com/originals/1f/b9/f4/1fb9f47778dc58318c63abfe83f7304c.jpg'})`
          }}
          className={helperClasses.backgroundEventOnBlur}
        ></div>
      )}

      <Grid
        className={classes.root}
        container
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} className={classes.formLayout}>
          <div className="max-width-layout">{props.children}</div>
        </Grid>
      </Grid>
    </div>
  )
}

export default SignInUpLayout
