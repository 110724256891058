import React, { useContext } from 'react'
import { Box, Typography, Grid, makeStyles } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DialogLayout from './DialogLayout'
import { DialogContent } from '../../utils/styled'
import ProgressLine from '../Poll/Components/ProgressLine'
import EventContext from '../../Services/EventContext'
import {
  FormatRelativeTime,
  TimeIsBetweenStarAtAndEndAt
} from '../../utils/helper'
import Countdown from '../Poll/Components/Countdown'

const customStyle = makeStyles((theme) => ({
  votedCount: {
    ...theme.pollResultModal.votedCount,
    borderRadius: theme.spacing(2)
  },
  description: {
    ...theme.pollResultModal.description
  },
  mySelected: {
    ...theme.poll.progressbar.mySelected,
    borderRadius: 8,
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2)
  },
  common: {
    ...theme.poll.progressbar.common,
    borderRadius: 8,
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2)
  },
  timer: theme.poll.timer
}))

export default function PollResultModal({
  isOpenDialogResult,
  poll,
  handdleCloseDialogPollResult
}) {
  const customClasses = customStyle()
  const eventContext = useContext(EventContext.Context)
  const progressHeight = poll.choiceWithImage ? '81px' : '45px'
  const calVotedPercent = (votedCount) =>
    votedCount > 0 ? ((votedCount / poll.votedCount) * 100).toFixed(2) : 0

  const renderTimeOrCountdown = (startAt, endAt, timeCurrent) => {
    const { isBetween, isEnded } = TimeIsBetweenStarAtAndEndAt(startAt, endAt)
    if (isEnded) {
      return (
        <Box mt={1} mb={2}>
          <Typography variant="caption" className={customClasses.timer}>
            {`Closed ${FormatRelativeTime(endAt)}`}
          </Typography>
        </Box>
      )
    } else if (isBetween) {
      return <Countdown endTime={endAt} />
    }
  }

  return (
    <DialogLayout
      open={isOpenDialogResult}
      handleClose={handdleCloseDialogPollResult}
      title="Poll Results"
      showCloseIcon={true}
    >
      <DialogContent>
        <Grid container justifyContent="space-between" spacing={4}>
          <Grid item xs={12} md={7}>
            <Box mb="8px">
              <Typography variant="h3">
                <strong>{poll.question}</strong>
              </Typography>
            </Box>
            <Typography variant="h6" className={customClasses.description}>
              {poll.description}
            </Typography>
            {poll.startAt &&
              poll.endAt &&
              renderTimeOrCountdown(
                poll.startAt,
                poll.endAt,
                eventContext.timeCurrent
              )}
            <Box mt={3}>
              {Object.entries(poll.choices)
                .sort((a, b) => a[1].position - b[1].position)
                .map((choice) => {
                  const percentage = calVotedPercent(choice[1].votedCount)
                  return (
                    <ProgressLine
                      key={choice[0]}
                      choice={choice[1]}
                      mySelected={false}
                      topSelected={false}
                      percentage={percentage}
                      progressHeight={progressHeight}
                      showVotedCount={true}
                      progressStyles={'common'}
                    />
                  )
                })}
            </Box>
          </Grid>

          <Grid item xs={7} md={5}>
            <Box padding="12px" className={customClasses.votedCount}>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h3">
                  <strong>{poll.votedCount}</strong>
                </Typography>
                <FontAwesomeIcon icon={['far', 'check-square']} size="2x" />
              </Box>
              <Typography variant="h6">Viewers answered</Typography>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </DialogLayout>
  )
}
