export default {
  app: {
    homePath: () => '/',
    popliveSale: () =>
      `https://services.eventpop.me/pop-live-streaming-portal/`,
    redirect: () => '/redirect'
  },
  auth: {
    authPath: (redirectPath) => {
      if (redirectPath) {
        return `/auth?redirect=${redirectPath}`
      } else {
        return '/auth'
      }
    },
    userSignIn: () => '/user/sign_in',
    userSignOut: () => '/user/sign_out',
    signInPath: () => '/signin',
    signUpPath: () => '/signup'
  },
  event: {
    eventPopEventDetail: (eventId) =>
      `${process.env.REACT_APP_EVP_BASE_URL}/e/${eventId}`,
    redeemTicketPath: (eventSlug) => `/e/${eventSlug}/redeem`,
    lobbyPath: (eventSlug) => `/e/${eventSlug}`,
    sessionPath: (eventSlug, sessionType, sessionId) =>
      `/e/${eventSlug}/s/${sessionType}/${sessionId}`
  },
  error: {
    pageNotFound: () => '/404'
  }
}
