import { functions } from './firebaseApp'
import { httpsCallable } from 'firebase/functions'

const getUserInfo = httpsCallable(functions, 'getUserInfo')
const authUserEventpopCallback = httpsCallable(
  functions,
  'AuthUserEventpopCallback'
)
const getTicketByUserId = httpsCallable(functions, 'getTicketByUserId')

export const getUserInfoByAccessToken = async (accessToken) => {
  const user = await getUserInfo({ access_token: accessToken })
  return user
}

export const authUserCallback = async (code) => {
  const auth = await authUserEventpopCallback({ code })
  return auth
}

export const getTicketsByUserId = async (
  accessToken,
  userId,
  eventSlug,
  eventpopId
) => {
  const tickets = await getTicketByUserId({
    accessToken,
    userId,
    eventSlug,
    eventpopId
  })

  return tickets
}
