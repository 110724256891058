import React, { useContext, useEffect, useState, useMemo } from 'react'
import {
  modalStyles,
  useStyles as helperStyles,
  CustomButton
} from '../../utils/styled'
import ModalLayout from '../Shares/ModalLayout'
import {
  Box,
  Divider,
  Typography,
  ListItem,
  List,
  makeStyles
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AppContext from '../../Services/AppContext'
import SetupProfile from '../Users/SetupAccount'
import Avatar from '../Shares/Avatar'
import COUNTRIES_CODE from '../../utils/CountriesCode'

const useStyles = makeStyles((theme) => ({
  root: {},
  displayName: {
    ...theme.profile.displayName,
    textAlign: 'center',
    marginBottom: 0,
    fontWeight: 'normal',
    wordBreak: 'break-word'
  },
  bio: {
    ...theme.profile.bio,
    textAlign: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    overflowWrap: 'break-word'
  },
  country: {
    ...theme.profile.country,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(1)
  },
  divider: theme.divider
}))

export default function ProfileModal() {
  const [isFirstStep, setIsFirstStep] = useState(false)
  const modalClasses = modalStyles()
  const appContext = useContext(AppContext.Context)
  const { currentProfile, profileModalSelected, actions } = appContext
  const classes = useStyles()
  const helperCleasses = helperStyles()
  const user = useMemo(() => {
    if (appContext.onOpenProfile.isEnable) {
      return appContext.profileModalSelected
    }
  }, [appContext.onOpenProfile.isEnable])

  useEffect(() => {
    let mounted = true
    if (
      mounted &&
      currentProfile &&
      currentProfile.id === profileModalSelected.id &&
      currentProfile.fresh
    ) {
      setIsFirstStep(true)
      actions.onOpenEditProfile()
    }
    return () => (mounted = false)
  }, [isFirstStep])

  const signOut = async () => {
    await appContext.actions.signOut()
    appContext.actions.setDisplayProfile(null)
    appContext.actions.setFirstTimeRenderLobby(true)
  }

  const editProfile = () => {
    appContext.actions.onOpenEditProfile()
  }

  const closeModal = (event, reason) => {
    if (reason === 'backdropClick') {
      if (!appContext.onOpenProfile.isEdit || !isFirstStep) {
        appContext.actions.setDisplayProfile(null)
        setIsFirstStep(false)
      } else return
    }
    appContext.actions.setDisplayProfile(null)
  }

  const handleBackdropClick = () => {
    if (!appContext.onOpenProfile.isEdit) {
      appContext.actions.setDisplayProfile(null)
      setIsFirstStep(false)
    }
  }

  const getCountryName = (code) => {
    let country = COUNTRIES_CODE.filter((country) => country.code === code)
    if (country.length > 0) {
      return country[0].name
    } else {
      return '-'
    }
  }

  const displayProfile = () => {
    if (user) {
      if (appContext.onOpenProfile.isEdit) {
        return (
          <SetupProfile
            user={user}
            handleClose={closeModal}
            isFirstStep={isFirstStep}
          />
        )
      } else {
        return (
          <div className={modalClasses.xs}>
            <div className={helperCleasses.spacer}></div>
            <Avatar user={user} type="profile" />
            <div className={helperCleasses.spacer}></div>

            <div className={helperCleasses.mx3}>
              <Typography className={classes.displayName} variant="h3">
                {user.displayName || user.email}
              </Typography>
              <Typography className={classes.bio} variant="h5">
                {user.bio}
              </Typography>
            </div>

            <List className={helperCleasses.py0}>
              <ListItem className={helperCleasses.px4}>
                <Box className={classes.country}>
                  <FontAwesomeIcon
                    icon={['far', 'globe-asia']}
                    className={helperCleasses.mr1}
                  />
                  {getCountryName(user.country)}
                </Box>
              </ListItem>

              <Divider className={classes.divider} />

              {appContext.currentProfile &&
                appContext.currentProfile.id !==
                  appContext.profileModalSelected.id && (
                  <div className={helperCleasses.spacer}></div>
                )}
              {appContext.currentProfile &&
                appContext.currentProfile.id ===
                  appContext.profileModalSelected.id && (
                  <React.Fragment>
                    <ListItem onClick={() => editProfile()}>
                      <CustomButton fullWidth>Edit My Profile</CustomButton>
                    </ListItem>

                    {appContext.currentEventType !== 'free' && (
                      <React.Fragment>
                        <Divider className={classes.divider} />

                        <ListItem onClick={() => signOut()}>
                          <CustomButton fullWidth>Sign Out</CustomButton>
                        </ListItem>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
            </List>
          </div>
        )
      }
    }
  }

  return (
    <ModalLayout
      notClosable={isFirstStep}
      open={appContext.onOpenProfile.isEnable}
      handleClose={closeModal}
    >
      {displayProfile()}
    </ModalLayout>
  )
}
