import React, { useContext, useEffect } from 'react'
import AppContext from '../Services/AppContext'
import { Container, Backdrop, CircularProgress } from '@material-ui/core'
import { parse } from 'query-string'
import { useLocation, Redirect } from '@reach/router'

import PathHelper from '../utils/PathHelper'

const AuthPage = () => {
  const context = useContext(AppContext.Context)
  const location = useLocation()
  let params = { redirect: null, ref: null, flags: null }
  if (location.search !== '') {
    params = parse(location.search)
  }

  // Get redirect param
  useEffect(() => {
    if (params.ref) {
      context.actions.setRefCode(params.ref)
    }
    if (params.flags) {
      context.actions.setFlags(params.flags)
    }
    if (params.redirect) {
      context.actions.setRedirectUrl(params.redirect)
      context.actions.setCurrentEventSlug(params.redirect)
    }
  }, [params.redirect, params.ref, params.flags])

  // Update state according to app's user context

  // Generate redirect component depending on user authentication state,
  // Note: signedIn null means the state hasn't initiated.
  const currentUser = () => {
    if (context.isSignedIn) {
      if (params.redirect !== null) {
        return <Redirect noThrow to={params.redirect} />
      } else {
        return <Redirect noThrow to={PathHelper.app.homePath()} />
      }
    } else {
      return <Redirect noThrow to={PathHelper.auth.signInPath()} />
    }
  }

  return (
    <Container maxWidth="sm">
      {currentUser()}
      <Backdrop open={true} invisible={true}>
        <CircularProgress />
      </Backdrop>
    </Container>
  )
}

export default AuthPage
