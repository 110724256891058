import React, { memo, useContext, useState } from 'react'
import moment from 'moment'
import {
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Chip,
  Typography,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
  createStyles
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AppContext from '../../Services/AppContext'
import SessionChatService from '../../Services/SessionChatService'
import Avatar from '../Shares/Avatar'
import RelativeTimer from './RelativeTimer'
import { parseEmoji } from '../../utils/helper'
import EventContext from '../../Services/EventContext'
// import { TextWithSeeMore } from './TextWithSeeMore'

import { MessageType, ThemeSessionType, EventType } from '../../utils/type'

const useStyles = makeStyles((theme: ThemeSessionType) =>
  createStyles({
    root: {
      ...theme.chat.listItem,
      width: '98%',
      margin: '0 auto',
      [theme.breakpoints.down('sm')]: {
        width: '98vw',
        margin: '0 auto'
      }
    },
    listItem: {
      padding: 0
    },
    menuPaper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gridColumnGap: 6,
      ...theme.chat.menu
    },
    host: {
      ...theme.chat.listItemHost
    },
    listChatMessageWrapper: {
      alignItems: 'flex-start',
      padding: '8px',
      gap: '8px'
    },
    listItemAvatar: {
      minWidth: 0
    },
    listItemText: {
      margin: 0,
      wordWrap: 'break-word'
    },
    displayName: {
      ...theme.chat.displayName,
      display: 'flex',
      alignItems: 'center',
      gap: '6px',
      fontWeight: 'bold',
      overflowY: 'hidden',
      width: '100%'
    },
    displayNameText: {
      fontWeight: 'bold',
      maxWidth: '70%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    },
    chip: {
      ...theme.chip.chat,
      fontWeight: 'bold',
      fontSize: '0.8em',
      height: '1.8em'
    },
    timestamp: {
      ...theme.pinMessage.timestamp,
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    },
    btnAction: {
      ...theme.button.hideMessage,
      cursor: 'pointer'
    },
    hideMessage: {
      opacity: 0.16
    },
    pinMessage: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    pinMessageText: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gridColumnGap: '4px',
      opacity: 0.52
    }
  })
)

type ChatMessageProps = {
  event: EventType
  sessionId: string
  message: MessageType
  style: React.CSSProperties
  handleUnPinMessage: () => void
  listRef: React.MutableRefObject<any>
  rowRef: React.MutableRefObject<any>
}

const ChatMessage = ({
  event,
  sessionId,
  message,
  handleUnPinMessage,
  style,
  listRef,
  rowRef
}: ChatMessageProps) => {
  const classes = useStyles()
  const eventContext = useContext(EventContext.Context)
  const { emojis } = eventContext
  const appContext = useContext(AppContext.Context) as any
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const ticketRole = appContext.ticketRole

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenuHost = () => {
    setAnchorEl(null)
  }

  const displayBadge = () => {
    if (!message.ticketRole) return
    if (message.ticketRole === 'host')
      return <Chip className={classes.chip} label="Host" size="small" />
  }

  const renderMessage = message.message
  const convertDate = (timestamp: number) => {
    return moment(timestamp).format('DD MMM YYYY, HH:mm')
  }

  const handleHiddenMessage = async (
    messageId: string,
    prevHiddenState: boolean
  ) => {
    const newMessage = {
      ...message,
      hidden: !prevHiddenState
    }

    await SessionChatService.updateMessages(
      event.id,
      sessionId,
      messageId,
      newMessage
    )
    listRef.current?.resetAfterIndex(0)
  }

  const handlePinMessage = async (messageId: string, prevPinState: boolean) => {
    const newMessage = {
      ...message,
      pin: !prevPinState
    }
    await handleUnPinMessage()
    await SessionChatService.updateMessages(
      event.id,
      sessionId,
      messageId,
      newMessage
    )

    listRef.current?.resetAfterIndex(0)
  }

  const classHost =
    message.ticketRole === 'host' || message.pin ? classes.host : null
  const classHideMessage = message.hidden ? classes.hideMessage : null

  return (
    <Box
      className={`${classes.root} ${classHost}`}
      style={style}
      data-testid="message"
    >
      <div ref={rowRef}>
        {message.pin && (
          <Box className={classes.pinMessage}>
            <Box className={classes.pinMessageText}>
              <FontAwesomeIcon icon={['fas', 'thumbtack']} />
              <Typography variant="caption">Pinned</Typography>
            </Box>
            {(ticketRole === 'host' || ticketRole === 'invisibleHost') && (
              <Box onClick={handleOpenMenu}>
                <Typography className={classes.btnAction}>
                  <FontAwesomeIcon icon={['fal', 'ellipsis-h']} size="lg" />
                </Typography>
              </Box>
            )}
          </Box>
        )}
        {(ticketRole === 'host' || ticketRole === 'invisibleHost') &&
          message.hidden && (
            <Box className={classes.pinMessage}>
              <Box className={classes.pinMessageText}>
                <FontAwesomeIcon icon={['fas', 'window-close']} />
                <Typography variant="caption">Hidden</Typography>
              </Box>
              {(ticketRole === 'host' || ticketRole === 'invisibleHost') && (
                <Box onClick={handleOpenMenu}>
                  <Typography className={classes.btnAction}>
                    <FontAwesomeIcon icon={['fal', 'ellipsis-h']} size="lg" />
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        <ListItem
          className={`${classes.listChatMessageWrapper} ${classHideMessage}`}
        >
          <ListItemAvatar className={classes.listItemAvatar}>
            <Avatar user={message} />
          </ListItemAvatar>
          <ListItemText
            className={classes.listItemText}
            primary={
              <Box display="flex" justifyContent="space-between">
                <Box className={classes.displayName}>
                  <Typography variant="h6" className={classes.displayNameText}>
                    {message.displayName}
                  </Typography>
                  {displayBadge()}
                  <Tooltip
                    title={convertDate(message.timestamp)}
                    placement="top"
                  >
                    <Typography variant="h6" className={classes.timestamp}>
                      <RelativeTimer timestamp={message.timestamp} />
                    </Typography>
                  </Tooltip>
                </Box>
                {(ticketRole === 'host' || ticketRole === 'invisibleHost') &&
                  !message.pin &&
                  !message?.hidden && (
                    <Box onClick={handleOpenMenu}>
                      <Typography className={classes.btnAction}>
                        <FontAwesomeIcon
                          icon={['fal', 'ellipsis-h']}
                          size="lg"
                        />
                      </Typography>
                    </Box>
                  )}
              </Box>
            }
            secondary={
              <Typography
                variant="h6"
                dangerouslySetInnerHTML={{
                  __html: parseEmoji(renderMessage, emojis)
                }}
              />
            }
          />
        </ListItem>
      </div>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenuHost}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        classes={{ paper: classes.menuPaper, list: classes.listItem }}
      >
        {!message.pin && (
          <MenuItem
            onClick={handleCloseMenuHost}
            divider={true}
            className={classes.menuPaper}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gridColumnGap={6}
            >
              <FontAwesomeIcon icon={['far', 'window-close']} />
              <Typography
                onClick={() =>
                  handleHiddenMessage(message.id, message?.hidden as boolean)
                }
              >
                {message.hidden ? 'Un-hide comment ' : 'Hide comment '}
              </Typography>
            </Box>
          </MenuItem>
        )}
        {!message.hidden && (
          <MenuItem onClick={handleCloseMenuHost} className={classes.menuPaper}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gridColumnGap={6}
              onClick={() => handlePinMessage(message.id, message.pin)}
            >
              <FontAwesomeIcon
                icon={['far', 'thumbtack']}
                style={{ transform: 'rotate(45deg)' }}
              />
              <Typography>
                {message.pin ? 'Un-pin comment' : 'Pin comment'}
              </Typography>
            </Box>
          </MenuItem>
        )}
      </Menu>
    </Box>
  )
}

export default memo(ChatMessage)
