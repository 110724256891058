import { realtimedb as db } from '../Services/firebaseApp'
import moment from 'moment'
import {
  ref,
  push,
  set,
  serverTimestamp,
  orderByChild,
  limitToLast,
  onValue,
  query as realtimeQuery,
  update,
  off,
  onChildChanged,
  equalTo,
  startAt,
  onChildAdded,
  orderByKey,
  child,
  get,
  endBefore
} from 'firebase/database'

import { ConvertTimeStamp } from '../utils/helper'

const createMessage = async (
  sessionId,
  user,
  message,
  profile,
  ticketRole,
  eventId
) => {
  const messageListRef = ref(db, `sessionMessages/${eventId}/${sessionId}`)

  const newMessageRef = push(messageListRef)
  await set(newMessageRef, {
    userId: user.uid,
    profileId: profile.id,
    ticketRole,
    displayName: profile.displayName,
    photoURL: profile.photoURL,
    timestamp: serverTimestamp(),
    message,
    pin: false
  })
}

const getMessages = async (eventId, sessionId, createdEvent) => {
  let messages = []
  const dateCreatedEvent = ConvertTimeStamp(createdEvent).seconds * 1000
  const oldMessageVersion = moment(dateCreatedEvent).diff(
    moment('01/05/2022', 'dd/MM/yyyy')
  )

  const messageListRef = ref(db, `sessionMessages/${eventId}/${sessionId}`)

  //if event create before 01/05/2022 must load messages old version
  if (oldMessageVersion < 0) {
    const messageListSessionIdRef = ref(db, `sessionMessages/${sessionId}`)
    const messageListSessionIdQuery = realtimeQuery(
      messageListSessionIdRef,
      orderByChild('timestamp')
    )

    onValue(
      messageListSessionIdQuery,
      (snapshot) => {
        if (snapshot.exists()) {
          const messageArray = Object.entries(snapshot.val()).map(
            ([id, data]) => ({
              id,
              ...data
            })
          )

          messages = messages.concat(messageArray)
        }
      },
      {
        onlyOnce: true
      }
    )
  }

  const messageListQuery = realtimeQuery(
    messageListRef,
    orderByChild('timestamp'),
    limitToLast(101)
  )

  onValue(
    messageListQuery,
    (snapshot) => {
      if (snapshot.exists()) {
        const messageArray = Object.entries(snapshot.val()).map(
          ([id, data]) => ({
            id,
            ...data
          })
        )

        messages = messages.concat(messageArray)
      }
    },
    { onlyOnce: true }
  )

  return {
    messages: messages.length > 100 ? messages.slice(1, 101) : messages,
    hasNextMessage: messages.length > 100 ? true : false
  }
}

const lazyLoadMessage = async (eventId, sessionId, messageStart) => {
  let messages = []
  const messageListRef = ref(
    db,
    `sessionMessages/${eventId}/${sessionId}`,
    orderByKey()
  )
  const messageListQuery = realtimeQuery(
    messageListRef,
    endBefore(messageStart.id),
    limitToLast(51)
  )

  onValue(
    messageListQuery,
    (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        messages.push({ ...childSnapshot.val(), id: childSnapshot.key })
      })
    },
    { onlyOnce: true }
  )

  return {
    messages: messages.length > 50 ? messages.slice(1, 51) : messages,
    hasNextMessage: messages.length > 50 ? true : false
  }
}

const findPinMessage = async (eventId, sessionId) => {
  let findMessage = null
  const messageListRef = ref(db, `sessionMessages/${eventId}/${sessionId}`)
  const pinQuery = realtimeQuery(
    messageListRef,
    orderByChild('pin'),
    equalTo(true)
  )

  const snapshot = await get(pinQuery)
  snapshot.forEach((childSnapshot) => {
    findMessage = { ...childSnapshot.val(), id: childSnapshot.key }
  })

  return {
    pinMessage: findMessage
  }
}

const listenToPinMessage = async (eventId, sessionId, callback) => {
  const messageListRef = ref(db, `sessionMessages/${eventId}/${sessionId}`)
  const messageQuery = realtimeQuery(messageListRef, orderByChild('pin'))
  onChildChanged(messageQuery, (data) => {
    callback({ ...data.val(), id: data.key })
  })
}

const listenToNewMessages = async (eventId, sessionId, callback) => {
  const messageListRef = ref(db, `sessionMessages/${eventId}/${sessionId}`)
  const startKey = push(messageListRef).key
  const messagesQuery = realtimeQuery(
    messageListRef,
    orderByKey(),
    startAt(startKey)
  )
  onChildAdded(messagesQuery, (data) => {
    callback({ ...data.val(), id: data.key })
  })
}

const listenToUpdateMessages = async (eventId, sessionId, callback) => {
  const messageListRef = ref(db, `sessionMessages/${eventId}/${sessionId}`)
  onChildChanged(messageListRef, (data) => {
    callback({ ...data.val(), id: data.key })
  })
}

const unsubscribeFromlistenerMessages = (eventId, sessionId) => {
  const messageListRef = ref(db, `sessionMessages/${eventId}/${sessionId}`)
  off(messageListRef, 'value')
  off(messageListRef, 'child_added')
  off(messageListRef, 'child_changed')
}

const updateMessages = async (eventId, sessionId, messageId, message) => {
  const messageListRef = ref(db, `sessionMessages/${eventId}/${sessionId}`)
  await update(child(messageListRef, messageId), { ...message })
}

export default {
  createMessage,
  getMessages,
  listenToNewMessages,
  listenToUpdateMessages,
  unsubscribeFromlistenerMessages,
  updateMessages,
  lazyLoadMessage,
  findPinMessage,
  listenToPinMessage
}
