import { initializeApp } from 'firebase/app'
import { firebaseConfig } from '../config'
import { getAnalytics } from 'firebase/analytics'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getDatabase, connectDatabaseEmulator } from 'firebase/database'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getStorage, connectStorageEmulator } from 'firebase/storage'

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const db = getFirestore(app)
const rtdb = getDatabase(app)
const func = getFunctions(app, 'asia-east2')
const storage = getStorage(app)
const auth = getAuth(app)

if (process.env.REACT_APP_FIREBASE_EMULATOR) {
  connectAuthEmulator(auth, 'http://127.0.0.1:9099')
  connectFunctionsEmulator(func, '127.0.0.1', 5001)
  connectFirestoreEmulator(db, '127.0.0.1', 8080)
  connectDatabaseEmulator(rtdb, '127.0.0.1', 9000)
  connectStorageEmulator(storage, '127.0.0.1', 9199)
}

export const firebaseAnalytics = analytics
export const firebaseApp = app
export const firestore = db
export const realtimedb = rtdb
export const functions = func
export const storageRef = storage
export const firebaseAuth = auth
