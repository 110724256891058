import { useState, useContext, useMemo } from 'react'
import { Collapse, makeStyles, Box } from '@material-ui/core'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'

import EventContext from '../../Services/EventContext'

const customClass = makeStyles((theme) => ({
  expandButton: {
    ...theme.colorFromTheme.root,
    cursor: 'pointer',
    fontSize: '10px',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px'
    },
    display: 'inline'
  },
  textDescription: {
    ...theme.videoBox.description,
    '& p': {
      marginBlock: 'unset',
      fontSize: '12px',
      [theme.breakpoints.up('md')]: {
        fontSize: '16px'
      }
    },
    '& a': {
      ...theme.videoBox.linkText,
      cursor: 'pointer',
      textDecoration: 'none',
      fontWeight: 'bold',
      [theme.breakpoints.up('md')]: {
        fontSize: '16px'
      }
    },

    [theme.breakpoints.up('md')]: {
      marginBottom: '16px'
    }
  }
}))

const LivestreamDetails = ({ session }) => {
  const [isSeeMore, setIsSeeMore] = useState(false)
  const [canExpand, setCanExpand] = useState(false)
  const fullDescription = useMemo(() => {
    let description = session.fullDescription
    const timePattern = /(?:([0-5]?[0-9]):)?([0-5]?[0-9]):([0-5][0-9])/gm
    let seconds = ''
    const matches = session.fullDescription.match(timePattern)
    if (matches) {
      matches.forEach((matched) => {
        const a = matched.split(':')
        if (matched.length === 7 || matched.length === 8) {
          seconds = +a[0] * 60 * 60 + +a[1] * 60 + a[2]
        } else {
          seconds = +a[0] * 60 + +a[1]
        }

        description = description.replace(
          matched,
          `<a data-sec="${seconds}">${matched}</a>`
        )
      })

      return description
    }
    return description
  }, [session.fullDescription])
  const customStyle = customClass()
  const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis)
  const handleReflow = (rleState) => {
    const { clamped } = rleState
    setCanExpand(clamped)
  }
  const eventContext = useContext(EventContext.Context)

  const jumpToElementTime = (e) => {
    const element = e.target.closest('[data-sec]')
    if (element) {
      eventContext.actions.videoTimeSkip(+element.getAttribute('data-sec'))
    }
  }

  if (
    session.fullDescription === '-' ||
    session.fullDescription === '<p><br></p>'
  )
    return null
  return (
    <Box>
      <Collapse in={isSeeMore}>
        <div
          style={{ display: 'inline' }}
          className={customStyle.textDescription}
          dangerouslySetInnerHTML={{ __html: fullDescription }}
          onClick={(e) => {
            jumpToElementTime(e)
          }}
        />
        <span
          className={customStyle.expandButton}
          onClick={() => canExpand && setIsSeeMore(false)}
        >
          See Less
        </span>
      </Collapse>
      <Collapse in={!isSeeMore}>
        <Box onClick={() => canExpand && setIsSeeMore(true)}>
          <ResponsiveEllipsis
            onReflow={handleReflow}
            className={`${customStyle.textDescription}`}
            unsafeHTML={fullDescription}
            maxLine={2}
            ellipsisHTML="... <a>See More</a>"
            basedOn="letters"
          />
        </Box>
      </Collapse>
    </Box>
  )
}

export default LivestreamDetails
