import React, { useEffect, useState, useContext, useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import videojs from 'video.js'
import { ByteArkPlayerContainer } from 'byteark-player-react'
import 'video.js/dist/video-js.css'
import '@videojs/themes/dist/fantasy/index.css'
import 'videojs-landscape-fullscreen'
import 'videojs-contrib-quality-levels'
import 'videojs-hls-quality-selector'
import Plugin from 'videojs-landscape-fullscreen'

import EventContext from '../Services/EventContext'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'black',
    color: 'white',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const VideoPlayer = ({
  autoplay,
  fluid,
  streaming,
  controls,
  sources,
  playsinline,
  heightVideo
}) => {
  const [byteArkVideo, setByteArkVideo] = useState()
  const isShowByteArkPlayer = useMemo(() => {
    const source = sources[0]
    if (source.src?.includes('byteark')) {
      setByteArkVideo(source)
      return true
    } else {
      setByteArkVideo(null)
      return false
    }
  }, [sources[0]?.src])

  const classes = useStyles()
  const videoRef = React.useRef(null)
  const eventContext = useContext(EventContext.Context)
  const { playerRef } = eventContext

  const videoOptions = {
    autoplay: autoplay,
    muted: autoplay,
    fluid: fluid || false,
    controls: controls || false,
    sources: sources,
    playsinline: playsinline
  }

  const byteArkPlayerOptions = {
    autoplay: autoplay,
    aspectRatio: '16:9',
    onReady: (player) => {
      const registerPlugin =
        window.videojs.registerPlugin || window.videojs.plugin
      registerPlugin('landscapeFullscreen', Plugin)
      player.landscapeFullscreen({
        fullscreen: {
          enterOnRotate: true,
          exitOnRotate: true,
          alwaysInLandscapeMode: true,
          iOS: true
        }
      })
    }
  }

  useEffect(() => {
    if (sources.length > 0) {
      playerRef.current = null
      const source = sources[0]
      if (!source.src?.includes('byteark')) {
        if (videoRef.current && !playerRef.current) {
          playerRef.current = videojs(videoRef.current, videoOptions, () => {
            eventContext.actions.onPlayerListerner(playerRef.current)
          })
        }
      }
    }
  }, [videoRef.current, sources[0]?.src])

  useEffect(() => {
    const player = playerRef.current

    if (player) {
      playerRef.current.hlsQualitySelector({
        displayCurrentQuality: true
      })
      playerRef.current.landscapeFullscreen({
        fullscreen: {
          enterOnRotate: true,
          exitOnRotate: true,
          alwaysInLandscapeMode: true,
          iOS: true
        }
      })
    }
    return () => {
      if (playerRef.current && !playerRef.current.isDisposed()) {
        // Dispose the Video.js player when the functional component unmounts
        playerRef.current.dispose()
        playerRef.current = null
      }
    }
  }, [playerRef])
  return (
    <>
      {isShowByteArkPlayer ? (
        <ByteArkPlayerContainer
          ref={videoRef}
          className="video-js vjs-theme-eventpop"
          sources={byteArkVideo}
          {...byteArkPlayerOptions}
          onPlayerCreated={eventContext.actions.onPlayerListerner} /// track player
          onReady={eventContext.actions.onReadyVideo}
        />
      ) : (
        <div className={classes.root} style={{ height: heightVideo }}>
          <video ref={videoRef} className="video-js vjs-theme-eventpop" />
        </div>
      )}
    </>
  )
}

export default VideoPlayer
