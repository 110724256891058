import { firestore, functions, storageRef } from './firebaseApp'
import { httpsCallable } from 'firebase/functions'
import { ref, getDownloadURL } from 'firebase/storage'
import {
  collection,
  query,
  where,
  limit,
  getDoc,
  doc,
  getDocs
} from 'firebase/firestore'

const db = firestore
const storage = storageRef
const getTimeCurrentFromServer = httpsCallable(
  functions,
  'getTimeCurrentFromServer'
)
const redeemTicket = httpsCallable(functions, 'eventRedeemTicket')
const createPublicTicket = httpsCallable(functions, 'createPublicTicket')
const getEventsListing = httpsCallable(functions, 'getEventsListing')
const getTicketTypeByTicketId = httpsCallable(
  functions,
  'getTicketTypeByTicketId'
)
const getEmojisEventByEventSlug = httpsCallable(
  functions,
  'getEmojisEventByEventSlug'
)

const COLLECTIONS = {
  EVENTS: 'events',
  EVENTS_EVENTPOP: 'events_eventpop',
  THEMES: 'themes',
  EVENTSETTING: 'eventSetting'
}

const getAllEvents = async ({ perPage, page }) => {
  const result = await getEventsListing({ perPage, page })
  if (result.data.success) {
    const events = result.data.events
    const eventList = await Promise.all(
      events.map(async (event) => ({
        ...event,
        posterUrl:
          event.posterUrl ?? (await getImageUrlFromStorage(event.posterRef))
      }))
    )

    return { ...result.data, events: eventList }
  }
  return []
}

const getEventWithSlug = async (slug) => {
  const eventRef = collection(db, COLLECTIONS.EVENTS)
  const eventQuery = query(eventRef, where('slug', '==', slug), limit(1))
  const eventRes = await getDocs(eventQuery)
  const doc = eventRes.docs[0]
  if (doc) {
    let event = {
      id: doc.id,
      source: 'poplive',
      ...doc.data()
    }

    // Load poster
    const posterRef = ref(storageRef, event.posterRef)
    event.posterURL = await getDownloadURL(posterRef)

    return event
  }
}

const getBannersURL = async (banners) => {
  if (!banners || banners.length === 0) return []
  const bannersRef = await Promise.all(
    banners.map(async (banner) => {
      return {
        id: banner.id,
        ref: banner.url ?? banner.ref,
        url: banner.url ?? (await getDownloadURL(ref(storage, banner.ref))),
        link: banner.link
      }
    })
  )
  return bannersRef
}

const getTimeFromServer = async () => {
  const { data } = await getTimeCurrentFromServer()
  return data
}

const getEventMembership = async (eventId, userId) => {
  const docRef = doc(db, `${COLLECTIONS.EVENTS}/${eventId}/members/${userId}`)
  const docQuery = await getDoc(docRef)

  if (docQuery) {
    return docQuery.data()
  } else {
    return null
  }
}

const getEventDoc = (eventId) => {
  return doc(db, COLLECTIONS.EVENTS, eventId)
}

const getSessionsViewersDoc = (eventId) => {
  return collection(db, `events/${eventId}/sessions`)
}

const redeemEventTicket = async (eventId, ticketRef) => {
  const resp = await redeemTicket({
    eventId: eventId,
    ticketRef: ticketRef
  })

  return resp
}

const getEventThemeDoc = (eventId) => {
  return doc(db, COLLECTIONS.THEMES, eventId)
}

const getEventSettingDoc = (eventId) => {
  return doc(db, COLLECTIONS.EVENTSETTING, eventId)
}

const generatePublicTicket = async (eventId, ticketTypeId) => {
  const result = await createPublicTicket({ eventId, ticketTypeId })
  return { ...result.data }
}

const getImageUrlFromStorage = async (storageUrl) => {
  if (!storageUrl) return null

  const posterRef = ref(storage, storageUrl)

  const imageUrl = await getDownloadURL(posterRef)

  return imageUrl
}

const getTicketTypeByTicketRef = async (eventId, ticketRef) => {
  const result = await getTicketTypeByTicketId({ eventId, ticketRef })
  if (result.data.success) {
    return {
      ticket: result.data.ticket,
      ticketType: result.data.ticketType
    }
  }
}

const getEmojisByEventSlug = async ({ slug }) => {
  const getEmojis = await getEmojisEventByEventSlug({ slug })
  if (getEmojis.data.success) {
    const emojis = getEmojis.data.emojis
    const emojiWithURL = Promise.all(
      emojis.map(async (emoji) => {
        const imageRef = ref(storageRef, emoji.imageRef)
        const imageURL = await getDownloadURL(imageRef)
        return {
          ...emoji,
          imageURL
        }
      })
    )
    return emojiWithURL
  }
  return []
}

export default {
  getAllEvents,
  getEventWithSlug,
  getEventDoc,
  getEventMembership,
  redeemTicket: redeemEventTicket,
  getEventThemeDoc,
  generatePublicTicket,
  getBannersURL,
  getTimeFromServer,
  getEventSettingDoc,
  getImageUrlFromStorage,
  getTicketTypeByTicketRef,
  getSessionsViewersDoc,
  getEmojisByEventSlug
}
