import React from 'react'
import { makeStyles, Box, Typography } from '@material-ui/core'
import Placeholders from './Livestream/PlaceholderStates'

const useStyles = makeStyles(() => ({
  intermissionTitle: {
    fontSize: '40px'
  }
}))

const LivestreamIntermission = () => {
  const classes = useStyles()
  return (
    <Placeholders>
      <Box>
        <Typography className={classes.intermissionTitle}>
          <strong>Intermission</strong>
        </Typography>
        <Typography>Livestream will be back shortly</Typography>
      </Box>
    </Placeholders>
  )
}

export default LivestreamIntermission
