import React, { useState } from 'react'
import {
  Menu,
  Box,
  Avatar,
  Typography,
  MenuItem,
  Divider,
  IconButton,
  makeStyles
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { variables } from '../../utils/styled'

const customStyles = makeStyles((theme) => ({
  avatar: {
    ...theme.navBar.avatar
  },
  menuWrapper: {
    padding: 0,
    borderRadius: theme.spacing(1),
    boxShadow: '0px 8px 32px rgba(0, 0, 0, 0.16)'
  },
  menuItem: {
    padding: theme.spacing(2),
    color: variables.grayBase
  },
  subtitleText: {
    fontSize: '12px',
    color: variables.grayDark
  }
}))

export default function AvatarDropDown({
  user,
  checkPathIsEventListing,
  signOut
}) {
  const customClasses = customStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const openMenuAccount = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2 }}
        aria-haspopup="dialog"
      >
        <Avatar
          className={customClasses.avatar}
          sx={{ width: 32, height: 32 }}
          style={{
            backgroundColor: checkPathIsEventListing ? variables.popliveRed : ''
          }}
        >
          {user.firstname[0]}
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenuAccount}
        onClose={handleClose}
        onClick={handleClose}
        classes={{ list: customClasses.menuWrapper }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem className={customClasses.menuItem}>
          <Box>
            <Typography variant="h6" style={{ color: variables.grayDark }}>
              {user.firstname} {user.lastname}
            </Typography>
            <Typography variant="h6" className={customClasses.subtitleText}>
              {user.email}
            </Typography>
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem className={customClasses.menuItem}>
          <Box display="flex" gridGap="12px" justifyContent="space-between">
            <Box>
              <Typography variant="h6">My Wallet</Typography>
              <Typography className={customClasses.subtitleText}>
                This will take you to
                <br /> www.eventpop.me
              </Typography>
            </Box>
            <FontAwesomeIcon icon={['far', 'external-link']} size="xs" />
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem button onClick={signOut} className={customClasses.menuItem}>
          <Typography variant="h6">Log out</Typography>
        </MenuItem>
      </Menu>
    </Box>
  )
}
