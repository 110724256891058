import React, { useState, useEffect, useContext } from 'react'
import { Box, makeStyles, createStyles } from '@material-ui/core'
import { navigate } from '@reach/router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import LinesEllipsis from 'react-lines-ellipsis'

import EventContext from '../../Services/EventContext'
import { CustomButton } from '../../utils/styled'
import PathHelper from '../../utils/PathHelper'
import ChipSessionStatus from '../../Components/ChipSessionStatus'
import Timer from './Chips/Timer'
import ViewersCount from './Chips/ViewersCount'
import { CheckStateSession, ConvertTimeStamp } from '../../utils/helper'

const customeStyles = makeStyles((theme) =>
  createStyles({
    listRoot: {
      ...theme.lobby.listRoot,
      width: '100%',
      height: '100%',
      borderRadius: 8,
      padding: '16px'
    },
    sessionIcon: {
      ...theme.lobby.sessionIcon,
      padding: '20px',
      borderRadius: '8px',
      fontSize: '20px'
    },
    iconRoot: {
      marginRight: 32,
      [theme.breakpoints.down('xs')]: {
        marginRight: 16
      }
    },
    joinButton: {
      width: '120px'
    },
    message: {
      fontWeight: 'bold',
      fontSize: '16px'
    }
  })
)

export default function BlockSession({ session, eventSlug }) {
  const customClasses = customeStyles()
  const [liveStreamState, setLiveStreamState] = useState('wait')
  const eventContext = useContext(EventContext.Context)
  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)

  useEffect(() => {
    const start = ConvertTimeStamp(session.startAt).seconds * 1000
    const end = ConvertTimeStamp(session.endAt).seconds * 1000
    const state = CheckStateSession(
      session.isLive,
      start,
      end,
      eventContext.timeCurrent
    )

    setLiveStreamState(state)
  }, [session.startAt, session.endAt])

  const ButtonTemplate = () => {
    let variant = 'contained'
    let btnTitleDesktop = 'Join Session'

    if (liveStreamState === 'wait' || liveStreamState === 'ended') {
      variant = 'outlined'
      btnTitleDesktop = 'Join Waiting Room'
    }

    const handleClickToSession = (eventSlug, sessType, sessId) => {
      navigate(PathHelper.event.sessionPath(eventSlug, sessType, sessId))
    }

    return (
      <CustomButton
        variant={variant}
        color="primary"
        type="submit"
        className={liveStreamState === 'join' ? customClasses.joinButton : ''}
        onClick={() =>
          handleClickToSession(
            eventSlug,
            session.sessionType,
            session.sessionId
          )
        }
      >
        {btnTitleDesktop}
      </CustomButton>
    )
  }

  return (
    <Box
      display="flex"
      gridGap="16px"
      className={customClasses.listRoot}
      alignItems="start"
    >
      <Box className={customClasses.sessionIcon}>
        <FontAwesomeIcon icon={['far', session.icon]} size="1x" />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          gridGap="16px"
          height="100%"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column" gridGap="6px">
            <Box display="flex" gridGap="6px">
              {liveStreamState === 'wait' && (
                <ChipSessionStatus
                  liveStreamState={liveStreamState}
                  isLive={session.isLive}
                />
              )}

              <ViewersCount
                viewersIncremental={session.viewersIncremental || 0}
                liveStreamState={liveStreamState}
                eventSlug={eventSlug}
                sessionId={session.sessionId}
              />
            </Box>
            <ResponsiveEllipsis
              text={session.fullTitle}
              className={`${customClasses.message}`}
              maxLine="3"
              ellipsis="..."
              basedOn="words"
            />
            <Timer startAt={session.startAt} endAt={session.endAt} />
          </Box>
          <ButtonTemplate />
        </Box>
      </Box>
    </Box>
  )
}
