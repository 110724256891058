import React, { useState, useEffect, useContext } from 'react'
import {
  ThemeProvider as MuiThemeProvider,
  createTheme
} from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
import { onSnapshot } from 'firebase/firestore'

import EventService from '../Services/EventService'
import AppContext from '../Services/AppContext'
import { variables } from '../utils/styled'
import { typography } from './theme'

const ThemeContext = React.createContext()

const ThemeContextProvider = ({ children }) => {
  const appContext = useContext(AppContext.Context)
  const currentEvent = appContext.currentEvent
  const [eventTheme, setEventTheme] = useState(null)
  const [eventSetting, setEventSetting] = useState(null)
  const [backgroundLiveStreamImage, setBackgroundLiveStreamImage] =
    useState(null)

  useEffect(() => {
    let unsubscribe = () => {}
    if (currentEvent?.id) {
      unsubscribe = onSnapshot(
        EventService.getEventThemeDoc(currentEvent.id),
        async (doc) => {
          const themeData = doc.data()
          let imageURL = null
          if (themeData?.backgroundImageRef) {
            imageURL = await EventService.getImageUrlFromStorage(
              themeData.backgroundImageRef
            )
          }
          setBackgroundLiveStreamImage(imageURL)
          setEventTheme(themeData)
        }
      )
      unsubscribe = onSnapshot(
        EventService.getEventSettingDoc(currentEvent.id),
        async (doc) => {
          if (doc.data()) {
            let eventSetting = { ...doc.data() }
            const { whereIsMyRefCodeSetting, redeemTicket } = eventSetting

            ///whereIsMyRefCodeImage getImageUrlFromStorage
            if (whereIsMyRefCodeSetting) {
              const whereIsMyRefCodeImageRef =
                whereIsMyRefCodeSetting?.whereIsMyRefCodeImage.imageRef
              if (whereIsMyRefCodeImageRef) {
                const imageUrl = await EventService.getImageUrlFromStorage(
                  whereIsMyRefCodeImageRef
                )
                const whereIsMyRefCode = {
                  ...whereIsMyRefCodeSetting,
                  whereIsMyRefCodeImage: {
                    ...eventSetting.whereIsMyRefCodeSetting
                      .whereIsMyRefCodeImage,
                    imageUrl
                  }
                }
                eventSetting = {
                  ...eventSetting,
                  whereIsMyRefCodeSetting: whereIsMyRefCode
                }
              }
            }
            ///backgroundImageRef getImageUrlFromStorage
            if (redeemTicket) {
              const backgroundImageRef = redeemTicket.backgroundImage.imageRef
              if (backgroundImageRef) {
                const imageUrl = await EventService.getImageUrlFromStorage(
                  backgroundImageRef
                )
                const redeemTicketImageURL = {
                  ...redeemTicket,
                  backgroundImage: {
                    ...redeemTicket.backgroundImage,
                    imageUrl
                  }
                }
                eventSetting = {
                  ...eventSetting,
                  redeemTicket: redeemTicketImageURL
                }
              }
            }
            setEventSetting(eventSetting)
          }
        }
      )
    }

    return () => {
      unsubscribe()
    }
  }, [currentEvent])

  const actions = {
    hexToRgba: (hex, opacity = 100) => {
      const opacities = {
        100: '1',
        64: '0.64',
        48: '0.48',
        32: '0.32',
        24: '0.24',
        16: '0.16',
        8: '0.08',
        4: '0.04'
      }
      const rgbRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i

      if (hex) {
        const rgb = hex
          .replace(rgbRegex, (m, r, g, b) => '#' + r + r + g + g + b + b)
          .substring(1)
          .match(/.{2}/g)
          .map((x) => parseInt(x, 16))
        if (opacity <= 1) {
          return `rgba(${rgb[0]},${rgb[1]},${rgb[2]}, ${1 - opacity})`
        } else
          return `rgba(${rgb[0]},${rgb[1]},${rgb[2]}, ${opacities[opacity]})`
      }

      return null
    },

    colorByTheme: (defalutColor, lightColor, darkColor) => {
      if (eventTheme) {
        const layoutTheme = eventTheme?.layoutTheme
        if (layoutTheme === 'light') return lightColor
        if (layoutTheme === 'dark') return darkColor
      }
      return defalutColor
    }
  }

  const hexToRgba = (hex, opacity) => actions.hexToRgba(hex, opacity)

  const primaryColor = eventTheme?.primaryColor
  const backgroundColor = eventTheme?.backgroundColor
  const layoutTheme = eventTheme?.layoutTheme || 'light'
  const redeemTicketBackground = eventSetting?.redeemTicket
  const colorFromEventJoinSetting = (redeemTicketBackground) => {
    if (redeemTicketBackground?.backgroundMode === 'backgroundColor') {
      if (redeemTicketBackground.backgroundColor.theme === 'dark') {
        return {
          color: 'white'
        }
      } else
        return {
          color: variables.grayBase
        }
    } else if (redeemTicketBackground?.backgroundMode === 'backgroundImg') {
      if (redeemTicketBackground.backgroundImage.theme === 'dark') {
        return {
          color: 'white'
        }
      } else
        return {
          color: variables.grayBase
        }
    } else return null
  }
  const backgroundImageFromCreator = (redeemTicketBackground) => {
    if (redeemTicketBackground?.backgroundMode === 'backgroundColor') {
      return {
        backgroundColor: hexToRgba(redeemTicketBackground.backgroundColor.color)
      }
    } else if (redeemTicketBackground?.backgroundMode === 'backgroundImg') {
      return {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: `url(${redeemTicketBackground.backgroundImage.imageUrl})`
      }
    } else return null
  }

  const checkBackgroundRedeemCodeDefaultMode = (redeemTicketBackground) => {
    if (
      redeemTicketBackground?.backgroundMode === 'default' ||
      !redeemTicketBackground?.backgroundMode
    ) {
      return true
    } else return false
  }

  const breakpoints = {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1240,
      xl: 1440
    }
  }
  const palette = {
    type: layoutTheme,
    primary: {
      main: primaryColor || variables.eventpopPrimary,
      light: primaryColor
        ? hexToRgba(primaryColor, 32)
        : hexToRgba(variables.eventpopPrimary, 32),
      dark: primaryColor
        ? hexToRgba(primaryColor, 64)
        : hexToRgba(variables.eventpopPrimary, 64)
    },
    background: {
      default: backgroundColor || '#FFFFFF',
      paper: backgroundColor || '#FFFFFF'
    },
    text: {
      secondary: variables.grayDark
    }
  }

  const button = {
    root: {
      color: palette.primary.main
    },
    containedPrimary: {
      borderColor: palette.primary.main,
      color: actions.colorByTheme('#FFFFFF', '#FFFFFF', backgroundColor)
    },
    outlinedPrimary: {
      borderColor: palette.primary.main,
      color: palette.primary.main
    },
    hideMessage: {
      color: palette.primary.main,
      '&:hover': {
        color: palette.primary.light
      }
    }
  }

  const colorFromTheme = {
    root: {
      color: actions.colorByTheme(
        variables.eventpopPrimaryMedium,
        primaryColor,
        primaryColor
      )
    }
  }

  const videoBox = {
    description: {
      color: `${actions.colorByTheme(
        hexToRgba(variables.grayBase, 64),
        hexToRgba(variables.grayBase, 64),
        hexToRgba('#FFFFFF', 64)
      )} !important`
    },
    linkText: {
      color: `${actions.colorByTheme(
        variables.grayBase,
        hexToRgba(variables.grayBase),
        hexToRgba('#FFFFFF')
      )} !important`
    }
  }

  const chip = {
    liveActive: {
      color: '#FFFFFF',
      background: variables.popliveRed
    },
    liveIntermission: {
      color: variables.primaryOrange,
      background: hexToRgba(variables.warning, 16)
    },
    liveEnded: {
      color: actions.colorByTheme(
        hexToRgba('#FFFFFF', 100),
        backgroundColor,
        backgroundColor
      ),
      backgroundColor: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimaryMedium, 100),
        primaryColor,
        primaryColor
      )
    },
    viewerCount: {
      color: actions.colorByTheme(
        hexToRgba(variables.grayBase),
        hexToRgba(variables.grayBase),
        hexToRgba('#FFFFFF')
      ),
      backgroundColor: actions.colorByTheme(
        hexToRgba(variables.grayBase, 8),
        hexToRgba(variables.grayBase, 8),
        hexToRgba('#FFFFFF', 8)
      ),
      '& .MuiChip-icon': {
        color: actions.colorByTheme(
          hexToRgba(variables.grayBase),
          hexToRgba(variables.grayBase),
          hexToRgba('#FFFFFF')
        )
      }
    },
    chat: {
      color: actions.colorByTheme(
        hexToRgba('#FFFFFF'),
        hexToRgba(backgroundColor),
        hexToRgba(backgroundColor)
      ),
      background: primaryColor || variables.eventpopPrimaryMedium
    },
    pinMessage: {
      color: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimaryMedium, 100),
        primaryColor,
        primaryColor
      ),
      backgroundColor: actions.colorByTheme(
        hexToRgba('#FFFFFF', 100),
        backgroundColor,
        backgroundColor
      )
    }
  }

  const navBar = {
    avatar: {
      backgroundColor: actions.colorByTheme(
        variables.eventpopPrimary,
        hexToRgba(primaryColor),
        hexToRgba(primaryColor)
      ),
      textTransform: 'uppercase'
    },
    signInButton: {
      fontWeight: 'bold',
      color: actions.colorByTheme(
        '#FFFFFF',
        hexToRgba(backgroundColor),
        hexToRgba(backgroundColor)
      ),
      backgroundColor: actions.colorByTheme(
        variables.eventpopPrimary,
        hexToRgba(primaryColor),
        hexToRgba(primaryColor)
      ),
      minWidth: '85px'
    },
    signUpButton: {
      fontWeight: 'bold',
      minWidth: '85px',
      borderColor: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimary),
        hexToRgba(primaryColor),
        hexToRgba(primaryColor)
      ),
      backgroundColor: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimary, 8),
        hexToRgba(primaryColor, 8),
        hexToRgba(primaryColor, 8)
      )
    },
    textBecomeToHost: {
      color: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimary, 100),
        hexToRgba(primaryColor, 100),
        hexToRgba(primaryColor, 100)
      )
    }
  }

  const eventSidebar = {
    navButton: {
      normal: {
        color: actions.colorByTheme(
          hexToRgba(variables.grayBase, 32),
          hexToRgba(variables.grayBase, 32),
          hexToRgba('#FFFFFF', 32)
        ),
        backgroundColor: 'transparent'
      },
      active: {
        color: layoutTheme === 'light' ? '#000106' : '#FFFFFF',
        backgroundColor: 'transparent'
      },
      activeWithHighlight: {
        color: actions.colorByTheme(
          variables.eventpopPrimaryMedium,
          primaryColor,
          backgroundColor
        ),
        backgroundColor: actions.colorByTheme(
          hexToRgba(variables.eventpopPrimaryMedium, 8),
          hexToRgba(primaryColor, 8),
          primaryColor
        )
      }
    },
    peopleList: {
      person: {
        color: variables.brandInfo,
        fontSize: '1em'
      }
    }
  }

  const avatar = {
    root: {
      backgroundColor: primaryColor || variables.eventpopPrimaryMedium
    },
    pinMessage: {
      backgroundColor: actions.colorByTheme(
        hexToRgba('#FFFFFF', 100),
        hexToRgba(backgroundColor, 100),
        hexToRgba(backgroundColor, 100)
      ),
      color: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimaryMedium, 100),
        primaryColor,
        primaryColor
      )
    }
  }

  const viewersCount = {
    root: {
      color: actions.colorByTheme(
        hexToRgba(variables.grayBase),
        hexToRgba(variables.grayBase),
        hexToRgba('#FFFFFF')
      ),
      backgroundColor: actions.colorByTheme(
        hexToRgba(variables.grayBase, 8),
        hexToRgba(variables.grayBase, 8),
        hexToRgba('#FFFFFF', 8)
      ),
      '& .MuiChip-icon': {
        color: actions.colorByTheme(
          hexToRgba(variables.grayBase),
          hexToRgba(variables.grayBase),
          hexToRgba('#FFFFFF')
        )
      }
    }
  }

  const tabMenus = {
    root: {
      background: actions.colorByTheme(
        hexToRgba('#FFFFFF', 24),
        hexToRgba(backgroundColor, 24),
        hexToRgba(backgroundColor, 24)
      ),
      color: actions.colorByTheme(
        variables.gray,
        hexToRgba(variables.grayBase, 32),
        hexToRgba('#FFFFFF', 32)
      ),
      borderBottom: `2px solid ${actions.colorByTheme(
        variables.grayLighther,
        hexToRgba(variables.grayBase, 4),
        hexToRgba('#FFFFFF', 8)
      )}`,
      borderTop: `2px solid ${actions.colorByTheme(
        variables.grayLighther,
        hexToRgba(variables.grayBase, 4),
        hexToRgba('#FFFFFF', 8)
      )}`,

      '& .Mui-selected': {
        color: actions.colorByTheme(
          variables.eventpopPrimaryMedium,
          primaryColor,
          backgroundColor
        ),
        background: actions.colorByTheme(
          hexToRgba(variables.eventpopPrimaryMedium, 4),
          hexToRgba(primaryColor, 8),
          hexToRgba(primaryColor)
        ),
        borderRadius: 8
      }
    }
  }
  const chapter = {
    image: {
      borderRadius: '4px',
      width: '100px',
      height: ' 56px'
    },
    badge: {
      display: 'inline-block',
      borderRadius: '4px',
      fontSize: '12px',
      padding: '2px 4px',
      backgroundColor: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimary, 8),
        hexToRgba(primaryColor, 8),
        hexToRgba(primaryColor, 8)
      ),
      color: primaryColor || variables.eventpopPrimaryMedium
    },
    chaptersMenuTitle: {
      color: actions.colorByTheme(
        hexToRgba(variables.grayBase),
        hexToRgba(variables.grayBase),
        hexToRgba('#FFFFFF')
      ),
      '& span': {
        color: actions.colorByTheme(
          hexToRgba(variables.grayBase, 32),
          hexToRgba(variables.grayBase, 32),
          hexToRgba('#FFFFFF', 32)
        )
      }
    },
    listChapter: {
      transition: 'all 0.5s ease-out',
      '&:hover': {
        backgroundColor: actions.colorByTheme(
          hexToRgba(variables.eventpopPrimary, 8),
          hexToRgba(primaryColor, 8),
          hexToRgba(primaryColor, 8)
        )
      }
    }
  }

  const chat = {
    listItem: {
      padding: 0,
      borderRadius: 8
    },
    listItemHost: {
      backgroundColor: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimary, 8),
        hexToRgba(primaryColor, 8),
        hexToRgba(primaryColor, 8)
      )
    },
    displayName: {
      color: primaryColor || variables.eventpopPrimaryMedium
    },
    menu: {
      backgroundColor: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimaryMedium, 100),
        hexToRgba(primaryColor, 100),
        hexToRgba(primaryColor, 100)
      ),
      borderColor: actions.colorByTheme(
        hexToRgba('#FFFFFF', 32),
        hexToRgba(backgroundColor, 32),
        hexToRgba(backgroundColor, 32)
      ),
      color: actions.colorByTheme(
        '#FFFFFF',
        hexToRgba(backgroundColor, 100),
        hexToRgba(backgroundColor, 100)
      )
    },
    timestamp: {
      color: actions.colorByTheme(
        hexToRgba(variables.grayBase, 32),
        hexToRgba(variables.grayBase, 32),
        hexToRgba('#FFFFFF', 48)
      ),
      cursor: 'default'
    },
    seeMoreButton: {
      cursor: 'pointer',
      color: actions.colorByTheme(
        variables.eventpopPrimary,
        hexToRgba(primaryColor, 100),
        hexToRgba(primaryColor, 100)
      )
    }
  }

  const pinMessage = {
    root: {
      backgroundColor: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimary, 100),
        hexToRgba(primaryColor, 100),
        hexToRgba(primaryColor, 100)
      ),
      color: actions.colorByTheme(
        hexToRgba('#FFFFFF', 100),
        hexToRgba(backgroundColor, 100),
        hexToRgba(backgroundColor, 100)
      )
    },
    displayName: {
      color: backgroundColor || variables.grayLight
    },
    timestamp: {
      cursor: 'default'
    },
    menu: {
      transition: 'color 0.5s',
      color: hexToRgba(backgroundColor, 52),
      '&:hover': {
        color: hexToRgba(backgroundColor, 32)
      }
    },
    menuPaper: {
      backgroundColor: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimaryMedium, 100),
        hexToRgba(primaryColor, 100),
        hexToRgba(primaryColor, 100)
      ),
      color: actions.colorByTheme(
        '#FFFFFF',
        hexToRgba(backgroundColor, 100),
        hexToRgba(backgroundColor, 100)
      )
    }
  }

  const chatBar = {
    root: {
      background: actions.colorByTheme(
        hexToRgba('#FFFFFF', 24),
        hexToRgba(backgroundColor, 24),
        hexToRgba(backgroundColor, 24)
      )
    },
    input: {
      outline: '1px solid',
      outlineColor: actions.colorByTheme(
        hexToRgba(variables.grayBase, 32),
        hexToRgba(variables.grayBase, 32),
        hexToRgba('#FFFFFF', 32)
      ),

      '&:focus': {
        outlineColor: actions.colorByTheme(
          hexToRgba(variables.grayBase, 100),
          hexToRgba(variables.grayBase, 100),
          hexToRgba('#FFFFFF', 100)
        )
      },
      '&:empty:before': {
        content: 'attr(placeholder)',
        color: actions.colorByTheme(
          hexToRgba(variables.grayBase, 32),
          hexToRgba(variables.grayBase, 32),
          hexToRgba('#FFFFFF', 32)
        ),
        display: 'inline-block',
        fontStyle: 'italic'
      }
    },
    emojiButton: {
      color: actions.colorByTheme(
        hexToRgba(variables.grayBase, 64),
        hexToRgba(variables.grayBase, 64),
        hexToRgba('#FFFFFF', 32)
      ),
      '& :hover': {
        color: actions.colorByTheme(
          hexToRgba('#FFFFFF', 32),
          hexToRgba('#FFFFFF', 32),
          hexToRgba(variables.grayBase, 16)
        )
      }
    },
    form: {
      borderColor: actions.colorByTheme(
        variables.grayLight,
        variables.grayLight,
        hexToRgba('#FFFFFF', 32)
      )
    },
    emojiPaper: {
      backgroundColor: actions.colorByTheme(
        hexToRgba('#FFFFFF', 100),
        hexToRgba(backgroundColor, 100),
        hexToRgba(backgroundColor, 100)
      ),
      borderColor: actions.colorByTheme(
        hexToRgba(variables.grayBase, 16),
        hexToRgba(variables.grayBase, 16),
        hexToRgba('#FFFFFF', 32)
      )
    },
    buttonSend: {
      color: primaryColor || variables.brandInfo,
      backgroundColor: 'transparent',
      boxShadow: `inset 0px 0px 0px 1px ${primaryColor || variables.brandInfo}`,

      '&:hover': {
        background:
          hexToRgba(primaryColor, 8) || hexToRgba(variables.brandInfo, 8),
        boxShadow: `inset 0px 0px 0px 1px ${
          primaryColor || variables.brandInfo
        }`
      },

      '&:disabled': {
        color: actions.colorByTheme(
          hexToRgba(variables.grayBase, 16),
          hexToRgba(variables.grayBase, 16),
          hexToRgba('#FFFFFF', 32)
        ),
        backgroundColor: 'transparent',
        boxShadow: `inset 0px 0px 0px 1px ${actions.colorByTheme(
          hexToRgba(variables.grayBase, 16),
          hexToRgba(variables.grayBase, 16),
          hexToRgba('#FFFFFF', 32)
        )}`
      }
    }
  }

  const modal = {
    paper: {
      backgroundColor: actions.colorByTheme(
        hexToRgba('#FFFFFF', 100),
        hexToRgba(backgroundColor, 100),
        hexToRgba(backgroundColor, 100)
      )
    },
    text: {
      color: actions.colorByTheme(
        hexToRgba(variables.grayBase, 64),
        hexToRgba(variables.grayBase, 64),
        hexToRgba('#FFFFFF', 64)
      )
    }
  }

  const profile = {
    displayName: {
      color: actions.colorByTheme(
        variables.grayBase,
        variables.grayBase,
        '#FFFFFF'
      )
    },
    bio: {
      color: actions.colorByTheme(
        hexToRgba(variables.grayBase, 48),
        hexToRgba(variables.grayBase, 48),
        hexToRgba('#FFFFFF', 48)
      )
    },
    country: {
      color: actions.colorByTheme(
        hexToRgba(variables.grayBase, 48),
        hexToRgba(variables.grayBase, 48),
        hexToRgba('#FFFFFF', 48)
      )
    }
  }

  const divider = {
    width: '90%',
    margin: 'auto',
    color: actions.colorByTheme(
      hexToRgba(variables.grayBase, 16),
      hexToRgba(variables.grayBase, 16),
      hexToRgba('#FFFFFF', 16)
    ),
    backgroundColor: actions.colorByTheme(
      hexToRgba(variables.grayBase, 16),
      hexToRgba(variables.grayBase, 16),
      hexToRgba('#FFFFFF', 16)
    )
  }

  const redeemTicket = {
    navbarTheme: {
      backgroundColor: backgroundColor || '#FFFFFF',
      background: () => {
        const checkDefaultMode = checkBackgroundRedeemCodeDefaultMode(
          redeemTicketBackground
        )
        if (checkDefaultMode) {
          return ''
        } else
          return 'linear-gradient(180deg, rgba(0, 1, 6, 0.25) 0%, rgba(0, 1, 6, 0) 100%)'
      },
      position: () => {
        const checkDefaultMode = checkBackgroundRedeemCodeDefaultMode(
          redeemTicketBackground
        )
        if (checkDefaultMode) {
          return 'static'
        } else return 'absolute'
      }
    },
    footerTheme: {
      backgroundColor: backgroundColor || '#FFFFFF',
      zIndex: 99,
      bottom: 0,
      ...backgroundImageFromCreator(redeemTicketBackground),
      background: () => {
        const checkDefaultMode = checkBackgroundRedeemCodeDefaultMode(
          redeemTicketBackground
        )
        if (checkDefaultMode) {
          return ''
        } else
          return 'linear-gradient(180deg, rgba(0, 1, 6, 0) 0%, rgba(0, 1, 6, 0.25) 100%)'
      },
      position: () => {
        const checkDefaultMode = checkBackgroundRedeemCodeDefaultMode(
          redeemTicketBackground
        )
        if (checkDefaultMode) {
          return 'static'
        } else return 'absolute'
      }
    },
    divider: {
      display: () => {
        const checkDefaultMode = checkBackgroundRedeemCodeDefaultMode(
          redeemTicketBackground
        )
        if (checkDefaultMode) {
          return 'block'
        } else return 'none'
      }
    },
    footer: {
      display: () => {
        const checkDefaultMode = checkBackgroundRedeemCodeDefaultMode(
          redeemTicketBackground
        )
        if (checkDefaultMode) {
          return 'block'
        } else return 'none'
      }
    },
    overlay: {
      position: 'absolute',
      display: () => {
        const checkDefaultMode = checkBackgroundRedeemCodeDefaultMode(
          redeemTicketBackground
        )
        if (checkDefaultMode) {
          return 'none'
        } else return 'block'
      },
      width: '100%',
      height: '100%',
      left: 0,
      zIndex: 1,
      background: () => {
        let opacity = 0
        if (redeemTicketBackground?.backgroundMode === 'backgroundColor') {
          opacity =
            1 - parseInt(redeemTicketBackground?.backgroundColor?.opacity) / 100
        } else {
          opacity =
            1 - parseInt(redeemTicketBackground?.backgroundImage?.opacity) / 100
        }
        return hexToRgba(variables.grayBase, opacity)
      }
    },
    root: {
      backgroundColor: backgroundColor || '#FFFFFF',
      ...backgroundImageFromCreator(redeemTicketBackground)
    },
    container: {
      zIndex: 101,
      backgroundColor: backgroundColor || '#FFFFFF',
      ...backgroundImageFromCreator(redeemTicketBackground),
      ...colorFromEventJoinSetting(redeemTicketBackground),
      padding: () => {
        if (
          redeemTicketBackground?.backgroundMode === 'default' ||
          !redeemTicketBackground?.backgroundMode
        ) {
          return '74px 0'
        } else return '148px 0'
      }
    },
    buttonByThemeWrapper: {
      '& .MuiButton-contained:hover': {
        backgroundColor: actions.colorByTheme(
          hexToRgba(variables.eventpopPrimary, 64),
          hexToRgba(primaryColor, 64),
          hexToRgba(primaryColor, 64)
        )
      }
    },
    buttonByTheme: {
      backgroundColor: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimary, 100),
        hexToRgba(primaryColor, 100),
        hexToRgba(primaryColor, 100)
      ),
      color: actions.colorByTheme(
        hexToRgba('#FFFFFF'),
        hexToRgba('#FFFFFF'),
        hexToRgba(variables.grayBase)
      )
    },
    buttonHamburgur: {
      borderColor: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimary, 100),
        hexToRgba(primaryColor, 100),
        hexToRgba(primaryColor, 100)
      ),
      color: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimaryMedium),
        hexToRgba(primaryColor),
        hexToRgba(primaryColor)
      )
    },
    containerSm: {
      padding: () => {
        const checkDefaultMode = checkBackgroundRedeemCodeDefaultMode(
          redeemTicketBackground
        )
        if (checkDefaultMode) {
          return '32px 24px 32px 24px !important'
        } else return '72px 24px 124px 24px !important'
      }
    },
    eventName: {
      color: layoutTheme === 'light' ? variables.grayBase : '#FFFFFF',
      ...colorFromEventJoinSetting(redeemTicketBackground)
    },
    inputLabel: {
      color: actions.colorByTheme(
        hexToRgba(variables.grayBase, 32),
        hexToRgba(variables.grayBase, 32),
        hexToRgba('#FFFFFF', 64)
      ),
      ...colorFromEventJoinSetting(redeemTicketBackground)
    },
    buttonRedeemTicket: {
      color: layoutTheme === 'light' ? '#FFFFFF' : backgroundColor
    },
    textByTheme: {
      color: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimaryMedium),
        hexToRgba(primaryColor),
        hexToRgba(primaryColor)
      )
    },
    backgroundColorByTheme: {
      backgroundColor: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimary),
        hexToRgba(primaryColor),
        hexToRgba(primaryColor)
      )
    },
    redeemTicketEventTitle: {
      backgroundColor: () => {
        const checkDefault = checkBackgroundRedeemCodeDefaultMode(
          redeemTicketBackground
        )
        if (checkDefault) {
          return actions.colorByTheme(
            hexToRgba(variables.grayBase, 4),
            hexToRgba(variables.grayBase, 4),
            hexToRgba('#FFFFFF', 8)
          )
        } else {
          if (redeemTicketBackground.backgroundMode === 'backgroundImg') {
            if (redeemTicketBackground.backgroundImage.theme === 'light') {
              return hexToRgba(variables.grayBase, 8)
            } else return hexToRgba('#FFFFFF', 8)
          } else {
            if (redeemTicketBackground.backgroundColor.theme === 'light') {
              return hexToRgba(variables.grayBase, 8)
            } else return hexToRgba('#FFFFFF', 8)
          }
        }
      },
      backdropFilter: 'blur(12px)'
    },
    tooltipTitle: {
      color: hexToRgba(variables.grayBase, 32)
    },
    ticketWrapper: {
      backgroundColor: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimary, 0.94),
        hexToRgba(primaryColor, 0.94),
        hexToRgba(primaryColor, 0.94)
      )
    },
    ticketIcon: {
      backgroundColor: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimary, 0.84),
        hexToRgba(primaryColor, 0.84),
        hexToRgba(primaryColor, 0.84)
      ),
      color: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimary),
        hexToRgba(primaryColor),
        hexToRgba(primaryColor)
      )
    },
    timeWrapperIcon: {
      color: () => {
        const checkDefaultMode = checkBackgroundRedeemCodeDefaultMode(
          redeemTicketBackground
        )
        if (checkDefaultMode) {
          return actions.colorByTheme(
            hexToRgba(variables.grayBase, 32),
            hexToRgba(variables.grayBase, 32),
            hexToRgba('#FFFFFF', 32)
          )
        } else {
          if (redeemTicketBackground.backgroundMode === 'backgroundColor') {
            if (redeemTicketBackground.backgroundColor.theme === 'light') {
              return hexToRgba(variables.grayBase, 32)
            } else return hexToRgba('#FFFFFF', 32)
          } else {
            if (redeemTicketBackground.backgroundImage.theme === 'light') {
              return hexToRgba(variables.grayBase, 32)
            } else return hexToRgba('#FFFFFF', 32)
          }
        }
      }
    },
    iconTooltipWrapper: {
      backgroundColor: actions.colorByTheme(
        hexToRgba(variables.grayBase, 8),
        hexToRgba(variables.grayBase, 8),
        hexToRgba('#FFFFFF', 8)
      )
    },
    goToEventpopWrapper: {
      marginTop: '24px',
      textDecoration: 'none',
      backgroundColor: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimary, 8),
        hexToRgba(primaryColor, 8),
        hexToRgba(primaryColor, 8)
      ),
      borderRadius: '12px',
      color: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimary),
        hexToRgba(primaryColor),
        hexToRgba(primaryColor)
      )
    }
  }

  const lobby = {
    label: {
      color: actions.colorByTheme(
        hexToRgba(variables.grayBase, 48),
        hexToRgba(variables.grayBase, 48),
        hexToRgba('#FFFFFF', 48)
      )
    },
    listRoot: {
      backgroundColor: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimaryMedium, 8),
        hexToRgba(primaryColor, 8),
        hexToRgba(primaryColor, 8)
      )
    },
    itemIcon: {
      color: actions.colorByTheme(
        hexToRgba(variables.grayBase, 32),
        hexToRgba(variables.grayBase, 32),
        hexToRgba('#FFFFFF', 48)
      )
    },
    sessionIcon: {
      backgroundColor: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimaryMedium, 16),
        hexToRgba(primaryColor, 16),
        hexToRgba(primaryColor, 16)
      ),
      color: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimaryMedium),
        hexToRgba(primaryColor),
        hexToRgba(primaryColor)
      )
    },
    hint: {
      root: {
        borderTop: `1px solid ${actions.colorByTheme(
          hexToRgba(variables.grayBase, 4),
          hexToRgba(variables.grayBase, 4),
          hexToRgba('#FFFFFF', 8)
        )}`
      },
      cardRoot: {
        border: `1px solid ${actions.colorByTheme(
          hexToRgba(variables.grayBase, 16),
          hexToRgba(variables.grayBase, 16),
          hexToRgba('#FFFFFF', 16)
        )}`,
        backgroundColor: 'transparent'
      },
      title: {
        color: actions.colorByTheme(
          hexToRgba(variables.eventpopPrimaryMedium),
          hexToRgba(primaryColor),
          hexToRgba(primaryColor)
        )
      }
    }
  }

  const pollResultModal = {
    paper: {
      backgroundColor: actions.colorByTheme(
        hexToRgba('#FFFFFF'),
        hexToRgba(backgroundColor),
        hexToRgba(backgroundColor)
      )
    },
    votedCount: {
      backgroundColor: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimaryMedium, 16),
        hexToRgba(primaryColor, 16),
        hexToRgba(primaryColor, 8)
      ),
      color: actions.colorByTheme(
        hexToRgba(variables.grayBase, 100),
        hexToRgba(variables.grayBase, 100),
        hexToRgba('#FFFFFF', 100)
      )
    },
    description: {
      color: actions.colorByTheme(
        hexToRgba(variables.grayBase, 64),
        hexToRgba(variables.grayBase, 64),
        hexToRgba('#FFFFFF', 64)
      )
    },
    iconButton: {
      color: actions.colorByTheme(
        hexToRgba(variables.grayBase, 100),
        hexToRgba(variables.grayBase, 100),
        hexToRgba('#FFFFFF', 100)
      )
    }
  }

  const poll = {
    root: {
      border: `1px solid ${actions.colorByTheme(
        hexToRgba(variables.grayLight),
        hexToRgba(variables.grayBase, 16),
        hexToRgba('#FFFFFF', 16)
      )}`
    },
    description: {
      color: actions.colorByTheme(
        hexToRgba(variables.grayBase, 48),
        hexToRgba(variables.grayBase, 48),
        hexToRgba('#FFFFFF', 48)
      )
    },
    timer: {
      color: actions.colorByTheme(
        variables.brandInfo,
        primaryColor,
        primaryColor
      )
    },
    option: {
      root: {
        boderColor: actions.colorByTheme(
          hexToRgba(variables.grayBase, 16),
          hexToRgba(variables.grayBase, 16),
          hexToRgba('#FFFFFF', 48)
        ),
        '&:hover': {
          backgroundColor: 'unset'
        },

        '&$checked': {
          color: actions.colorByTheme(
            hexToRgba(variables.eventpopPrimaryMedium),
            hexToRgba(primaryColor),
            hexToRgba(primaryColor)
          ),

          '&:hover': {
            backgroundColor: 'unset'
          }
        }
      },
      imageBg: {
        color: actions.colorByTheme(
          hexToRgba(variables.eventpopPrimaryMedium),
          hexToRgba(primaryColor),
          hexToRgba(primaryColor)
        )
      },
      selected: {
        backgroundColor: actions.colorByTheme(
          hexToRgba(variables.eventpopPrimaryMedium, 16),
          hexToRgba(primaryColor, 16),
          hexToRgba(primaryColor, 8)
        )
      }
    },
    progressbar: {
      common: {
        border: `thin solid ${actions.colorByTheme(
          variables.brandPrimaryLight,
          hexToRgba(primaryColor, 8),
          hexToRgba(primaryColor, 16)
        )}`,

        '& #progressbar': {
          backgroundColor: actions.colorByTheme(
            variables.brandPrimaryLight,
            hexToRgba(primaryColor, 8),
            hexToRgba(primaryColor, 16)
          )
        }
      },
      mySelectedLabel: {
        color: actions.colorByTheme(
          variables.eventpopPrimaryMedium,
          primaryColor,
          primaryColor
        )
      },
      mySelected: {
        border: `medium solid ${actions.colorByTheme(
          variables.eventpopPrimaryMedium,
          primaryColor,
          primaryColor
        )}`,

        '& #progressbar': {
          backgroundColor: actions.colorByTheme(
            variables.brandInfoLight,
            hexToRgba(primaryColor, 8),
            hexToRgba(primaryColor, 16)
          )
        }
      },
      myTopSelectLabel: {
        color: variables.brandSuccess
      },
      topSelect: {
        border: `thin solid ${actions.colorByTheme(
          variables.brandSuccessLight,
          hexToRgba(variables.brandSuccess, 8),
          hexToRgba(variables.brandSuccess, 16)
        )}`,

        '& #progressbar': {
          backgroundColor: actions.colorByTheme(
            variables.brandSuccessLight,
            hexToRgba(variables.brandSuccess, 8),
            hexToRgba(variables.brandSuccess, 16)
          )
        }
      },
      topSelectWithMySelected: {
        border: `medium solid ${actions.colorByTheme(
          variables.eventpopPrimaryMedium,
          primaryColor,
          primaryColor
        )}`,

        '& #progressbar': {
          backgroundColor: actions.colorByTheme(
            hexToRgba(variables.brandSuccess, 8),
            hexToRgba(variables.brandSuccess, 8),
            hexToRgba(variables.brandSuccess, 16)
          )
        }
      }
    },
    placeholder: {
      color: actions.colorByTheme(
        hexToRgba(variables.grayBase, 48),
        hexToRgba(variables.grayBase, 48),
        hexToRgba('#FFFFFF', 48)
      )
    },
    viewResultButton: {
      color: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimaryMedium),
        hexToRgba(primaryColor),
        hexToRgba(primaryColor)
      ),
      cursor: 'pointer'
    }
  }

  const pageNotFound = {
    description: {
      color: actions.colorByTheme(
        variables.grayBase,
        variables.grayBase,
        '#FFFFFF'
      )
    },
    errorCode: {
      color: actions.colorByTheme(
        hexToRgba(variables.grayBase, 48),
        hexToRgba(variables.grayBase, 48),
        hexToRgba('#FFFFFF', 48)
      )
    }
  }
  const customInput = {
    borderColor: actions.colorByTheme(
      hexToRgba(variables.grayBase, 16),
      hexToRgba(variables.grayBase, 16),
      hexToRgba('#FFFFFF', 48)
    )
  }
  const text = {
    description: actions.colorByTheme(
      hexToRgba(variables.grayBase, 64),
      hexToRgba(variables.grayBase, 64),
      hexToRgba('#FFFFFF', 48)
    )
  }
  const banners = {
    wrapper: {
      borderColor: actions.colorByTheme(
        hexToRgba(variables.grayBase, 8),
        hexToRgba(variables.grayBase, 8),
        hexToRgba('#FFFFFF', 8)
      ),
      border: '1px solid'
    }
  }

  const seeMore = {
    button: {
      color: actions.colorByTheme(
        variables.eventpopPrimaryMedium,
        primaryColor,
        primaryColor
      )
    }
  }
  const timer = {
    timer: {
      backgroundColor: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimaryMedium, 16),
        hexToRgba(primaryColor, 16),
        hexToRgba(primaryColor, 16)
      )
    },
    time: {
      backgroundColor: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimaryMedium, 0.1),
        hexToRgba(primaryColor, 0.1),
        hexToRgba(primaryColor, 0.1)
      )
    },
    timeText: {
      backgroundColor: actions.colorByTheme(
        hexToRgba(variables.eventpopPrimaryMedium),
        hexToRgba(primaryColor),
        hexToRgba(primaryColor)
      ),
      color: actions.colorByTheme(
        hexToRgba('#FFFFFF'),
        hexToRgba('#FFFFFF'),
        hexToRgba(variables.grayBase)
      )
    }
  }

  const styledPages = {
    pageNotFound,
    redeemTicket,
    lobby
  }

  const styledComponents = {
    avatar,
    button,
    divider,
    eventSidebar,
    viewersCount,
    tabMenus,
    chip,
    chapter,
    chat,
    chatBar,
    customInput,
    modal,
    profile,
    poll,
    text,
    pinMessage,
    banners,
    colorFromTheme,
    pollResultModal,
    seeMore,
    navBar,
    timer,
    videoBox
  }

  const theme = createTheme({
    palette,
    breakpoints,
    typography,
    overrides: {
      MuiCssBaseline: {
        '@global': {
          body: {
            '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
              width: '8px',
              backgroundColor: actions.colorByTheme(
                hexToRgba(variables.grayBase, 8),
                hexToRgba(variables.grayBase, 8),
                hexToRgba('#FFFFFF', 8)
              ),
              borderRadius: 8
            },
            '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
              borderRadius: 8,
              backgroundColor: actions.colorByTheme(
                hexToRgba(variables.eventpopPrimary),
                hexToRgba(primaryColor),
                hexToRgba(primaryColor)
              ),
              minHeight: 10,
              opacity: 0
            },

            // '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus':
            //   {
            //     backgroundColor: '#959595'
            //   },
            '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active':
              {
                backgroundColor: actions.colorByTheme(
                  hexToRgba(variables.eventpopPrimary),
                  hexToRgba(primaryColor),
                  hexToRgba(primaryColor)
                )
              },
            '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover':
              {
                backgroundColor: actions.colorByTheme(
                  hexToRgba(variables.eventpopPrimary, 64),
                  hexToRgba(primaryColor, 64),
                  hexToRgba(primaryColor, 64)
                )
              },
            '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
              backgroundColor: actions.colorByTheme(
                hexToRgba(variables.eventpopPrimary, 64),
                hexToRgba(primaryColor, 64),
                hexToRgba(primaryColor, 64)
              )
            }
          }
        }
      }
    },
    ...styledPages,
    ...styledComponents
  })

  return (
    <ThemeContext.Provider
      value={{
        actions,
        eventTheme,
        backgroundLiveStreamImage,
        theme,
        eventSetting
      }}
    >
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  )
}

export default {
  Context: ThemeContext,
  Provider: ThemeContextProvider
}
