import React, { useEffect, useState, useContext, useMemo } from 'react'
import moment from 'moment'
import useResizeAware from 'react-resize-aware'
import {
  useTheme,
  useMediaQuery,
  makeStyles,
  Collapse,
  Box,
  Divider
} from '@material-ui/core'

import EventContext from '../Services/EventContext'
import AppContext from '../Services/AppContext'
import { functions } from '../Services/firebaseApp'
import VideoPlayer from '../Components/VideoPlayer'
import VideoDescription from './VideoDescription'
import TabMenus from './Livestream/TabMenus'
import LivestreamNotStarted from './LivestreamNotStarted'
import LivestreamIntermission from './LivestreamIntermission'
import Placeholders from './Livestream/PlaceholderStates'
import { CheckStateSession, ConvertTimeStamp } from '../utils/helper'
import MoreSession from './Livestream/MoreSession'
import Alert from './Shares/Alert'
import TimeoutModal from './Modal/TimeoutModal'
import UserTimeRemainingTimer from './UserTimeRemainingTimer'
import useWindowDimensions from '../hooks/useWindowDimensions'
import { httpsCallable } from 'firebase/functions'

const joinLivestream = httpsCallable(functions, 'eventJoinLivestream')

const useStyles = makeStyles((theme) => ({
  videoDescriptionBox: {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  videoBox: {
    position: 'relative',

    '& .bg-state': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      textAlign: 'center',
      zIndex: 10
    }
  },
  videoPlayer: {
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    background: '#fff',
    zIndex: 9,

    [theme.breakpoints.up('sm')]: {
      position: 'relative'
    },

    '@media (orientation: landscape)': {
      position: 'relative',
      zIndex: 99
    }
  },
  description: {
    flex: 1
  },
  wrapperInner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  collapseWrapper: {
    height: '100%'
  },
  alertWarningTimeLimitWrapper: {
    position: 'relative',
    zIndex: 99,
    right: 8
  }
}))

const JoiningSession = () => {
  return (
    <Placeholders>
      <h2>Joining...</h2>
    </Placeholders>
  )
}

const LivestreamContainer = ({
  event,
  session,
  alreadyPresent,
  chat,
  poll,
  qa,
  chapter
}) => {
  const eventContext = useContext(EventContext.Context)
  const { timeCurrent, isPlayedVideo } = eventContext
  const [resizeListener] = useResizeAware()
  const [streaming, setStreaming] = useState()
  const { membership, sessions } = eventContext
  const [showAlertWarning, setShowAlertWarning] = useState(false)
  const [showAlertFirstTime, setShowAlertFirstTime] = useState(false)
  const appContext = useContext(AppContext.Context)
  const { ticketType, timeRemainingSeconds, ticket } = appContext
  const [isOpenTimeOutModal, setIsOpenTimeOutModal] = useState(false)
  const [liveStreamState, setLiveStreamState] = useState()
  const [duration, setDuration] = useState()
  const classes = useStyles()
  const vdoBox = React.createRef()
  const theme = useTheme()
  const smSizeDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdSizeDown = useMediaQuery(theme.breakpoints.down('md'))
  const { width, height } = useWindowDimensions()
  const heightVideoBox = useMemo(() => {
    let calculateVideoHeight = Math.floor((9 / 16) * (width - 520))
    if (smSizeDown) {
      calculateVideoHeight = Math.floor((9 / 16) * width)
    }
    return calculateVideoHeight
  }, [width, smSizeDown])

  const handleCloseDialogTimeout = () => {
    setIsOpenTimeOutModal(false)
  }

  useEffect(() => {
    if (timeRemainingSeconds <= 900 && !showAlertFirstTime) {
      setShowAlertWarning(true)
      setShowAlertFirstTime(true)
    }
    if (timeRemainingSeconds <= 0) {
      setIsOpenTimeOutModal(true)
    }
  }, [timeRemainingSeconds, showAlertFirstTime])

  useEffect(() => {
    if (timeRemainingSeconds) {
      setShowAlertWarning(true)
    }
  }, [])

  useEffect(() => {
    const start = ConvertTimeStamp(session.startAt).seconds * 1000
    const end = ConvertTimeStamp(session.endAt).seconds * 1000
    let then = moment(start)
    let now = moment(timeCurrent)
    let time = moment.duration(then - now)
    setDuration(time.asMilliseconds())

    const state = CheckStateSession(session.isLive, start, end, timeCurrent)

    setLiveStreamState(state)
  }, [session.startAt, session.sessionId])

  useEffect(() => {
    if (!alreadyPresent) {
      setStreaming(null)
      joinLivestream({ eventId: event.id, sessionId: session.sessionId })
        .then((result) => setStreaming(result))
        .catch((errors) => console.log(errors))
    }
  }, [session.sessionId, alreadyPresent])

  const sessionsCanAccess = useMemo(() => {
    const sessionsAccess = []
    const membershipSessions = membership.sessions
    membershipSessions.forEach((sessionId) => {
      const session = sessions.find(
        (session) => session.sessionId === sessionId
      )
      if (session) {
        const state = CheckStateSession(
          session.isLive,
          ConvertTimeStamp(session.startAt).seconds * 1000,
          ConvertTimeStamp(session.endAt).seconds * 1000,
          eventContext.timeCurrent
        )

        if (state === 'ended') {
          return
        } else {
          sessionsAccess.push(session)
        }
      }
    })
    const filterSessions = sessionsAccess.filter(
      (sess) => sess.sessionId !== session.sessionId
    )

    return filterSessions
  }, [session.sessionId])

  const displayDetailBox = () => {
    return (
      <TabMenus
        heightVideoBox={heightVideoBox}
        session={session}
        qa={qa}
        chat={chat}
        poll={poll}
        isSmSize={smSizeDown}
        chapter={chapter}
      />
    )
  }
  const handleCloseAlert = (__, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setShowAlertWarning(false)
  }

  const displayVideoBox = (session) => {
    const { isLive } = session
    const renderLiveStream = () => {
      if (isLive && streaming.data && liveStreamState === 'start') {
        return (
          <div className={classes.videoPlayer} id="videoBox" ref={vdoBox}>
            <VideoPlayer
              fluid
              autoplay={ticket?.ticketTypeOndemand !== 'limited' ? true : false}
              streaming={streaming}
              controls={true}
              playsinline
              sources={[
                { src: streaming.data.url, type: 'application/x-mpegURL' }
              ]}
              heightVideo={heightVideoBox}
            />
          </div>
        )
      }
      if (liveStreamState === 'wait') {
        return (
          <LivestreamNotStarted
            setLiveStreamState={setLiveStreamState}
            timeCurrent={timeCurrent}
            session={session}
            duration={duration}
          />
        )
      } else if (liveStreamState === 'ended') {
        return (
          <Placeholders>
            <h2>Livestream Has Ended</h2>
          </Placeholders>
        )
      } else return <LivestreamIntermission />
    }

    if (alreadyPresent) {
      return (
        <Placeholders>
          <h2>You have already joined the session elsewhere.</h2>
        </Placeholders>
      )
    } else if (streaming && liveStreamState) {
      return renderLiveStream()
    } else {
      return <JoiningSession />
    }
  }

  return (
    <React.Fragment>
      {resizeListener}
      {ticket?.ticketTypeOndemand === 'limited' && (
        <React.Fragment>
          <UserTimeRemainingTimer
            isPlayedVideo={isPlayedVideo}
            timeRemaining={timeRemainingSeconds}
            ticketType={ticketType}
          />
          <TimeoutModal
            handleCloseDialogTimeout={handleCloseDialogTimeout}
            isOpenTimeOutModal={isOpenTimeOutModal}
            extendLink={ticketType?.extendLink}
          />
        </React.Fragment>
      )}

      <div className={classes.videoBox} style={{ height: heightVideoBox }}>
        {ticket?.ticketTypeOndemand === 'limited' && (
          <div className={classes.alertWarningTimeLimitWrapper}>
            <Alert
              severity="warning"
              open={showAlertWarning}
              handleCloseAlert={handleCloseAlert}
              duration={!isPlayedVideo ? null : 3000}
            >
              Only
              {timeRemainingSeconds < 120
                ? ` ${timeRemainingSeconds} seconds `
                : ` ${Math.round(timeRemainingSeconds / 60)} minutes `}
              remaining in this session.
            </Alert>
          </div>
        )}

        <div className="bg-state" id="bg-state">
          {timeRemainingSeconds <= 0 &&
          ticket?.ticketTypeOndemand === 'limited' ? (
            <Placeholders>
              <h2>Your session has reached its time limit</h2>
            </Placeholders>
          ) : (
            displayVideoBox(session)
          )}
        </div>
      </div>
      <div
        className={`${classes.videoDescriptionBox}`}
        style={
          mdSizeDown
            ? { height: `calc(${height}px - ${heightVideoBox + 56}px)` }
            : {}
        }
      >
        <Box className={classes.description}>
          <Collapse
            in={!smSizeDown || appContext.isToggleDescription}
            timeout="auto"
            classes={{
              wrapperInner: classes.wrapperInner,
              wrapper: classes.collapseWrapper,
              root: classes.collapseWrapper
            }}
          >
            <VideoDescription session={session} />
            {smSizeDown && sessionsCanAccess.length > 0 && (
              <Box>
                <Divider />
                <MoreSession
                  event={eventContext.event}
                  sessions={sessionsCanAccess}
                  currentSession={session}
                />
              </Box>
            )}
          </Collapse>
        </Box>
        {smSizeDown && displayDetailBox()}
      </div>
    </React.Fragment>
  )
}

export default LivestreamContainer
