import React, { useContext, useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'

import PollContext from '../../Services/PollContext'
import PollVotedResult from './PollVotedResult'

const PollDetailsInnerComponent = () => {
  const pollContext = useContext(PollContext.Context)
  const poll = pollContext.poll

  const [choices, setChoices] = useState([])

  useDeepCompareEffect(() => {
    setChoices(pollContext.choices)
  }, [pollContext.choices])

  if (!poll) return null

  return <PollVotedResult poll={poll} choices={choices} />
}

const PollDetails = (props) => {
  return (
    <PollContext.Provider sessionId={props.sessionId} poll={props.poll}>
      <PollDetailsInnerComponent {...props} />
    </PollContext.Provider>
  )
}

export default PollDetails
