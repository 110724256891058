import React, { useEffect } from 'react'
import { Button, makeStyles } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '1.2em',
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  qa: {
    marginRight: theme.spacing(1)
  }
}))

const QuestionAnswerCrisp = ({ websiteId }) => {
  const classes = useStyles()

  const openCrispChat = () => {
    window.$crisp.push(['do', 'chat:show'])
    window.$crisp.push(['do', 'chat:open'])
  }

  useEffect(() => {
    openCrispChat()

    return () => {
      window.$crisp.push(['do', 'chat:hide'])
    }
  }, [])

  return (
    <Button
      onClick={openCrispChat}
      style={{ marginTop: 16 }}
      className={classes.root}
      id="crisp"
      variant="contained"
      color="primary"
    >
      <FontAwesomeIcon
        className={classes.qa}
        icon={['far', 'comment-alt-check']}
        size="1x"
      />
      Q & A
    </Button>
  )
}

export default QuestionAnswerCrisp
