import React from 'react'
import { Chip, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  chipRoot: {
    height: '1.8em',
    fontSize: 12,
    fontWeight: 'bold',
    borderRadius: '4px'
  },
  chipActive: theme.chip.liveActive,
  chipIntermission: theme.chip.liveIntermission,
  chipEnded: theme.chip.liveEnded
}))

export default function ChipSessionStatus({ liveStreamState, isLive = false }) {
  const classes = useStyles()
  let chipLabel = null
  let chipStyled = null

  if (isLive) {
    chipLabel = 'LIVE'
    chipStyled = classes.chipActive
  } else if (liveStreamState === 'start') {
    chipLabel = 'INTERMISSION'
    chipStyled = classes.chipIntermission
  } else if (liveStreamState === 'ended') {
    chipLabel = 'ENDED'
    chipStyled = classes.chipEnded
  } else {
    return null
  }

  return (
    <Chip
      className={`${classes.chipRoot} ${chipStyled}`}
      label={chipLabel}
      size="small"
    />
  )
}
