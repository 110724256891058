import React, { useContext, useEffect } from 'react'
import AppContext from '../Services/AppContext'
import { CircularProgress } from '@material-ui/core'
import SignIn from '../Components/Users/SignIn'
import SignInUpLayout from '../Components/Pages/SignInUpLayout'
import { useStyles } from '../utils/styled'

const SignInPage = () => {
  const context = useContext(AppContext.Context)
  const classes = useStyles()

  useEffect(() => {
    if (context.hasOwnProperty('isSignedIn') && context.isSignedIn) {
      if (context.redirectTo) {
        context.actions.navigateRedirectUrl()
      } else {
        context.actions.navigateToHome()
      }
    }
  }, [context.isSignedIn])

  const renderSignInForm = () => {
    if (context.hasOwnProperty('isSignedIn')) {
      if (context.isSignedIn) {
        return null
      } else {
        return <SignIn />
      }
    } else {
      return (
        <div className={classes.textCenter}>
          <CircularProgress />
          <br />
          Please wait...
        </div>
      )
    }
  }

  return <SignInUpLayout>{renderSignInForm()}</SignInUpLayout>
}

export default SignInPage
