import { useRef } from 'react'

import PopliveLayout from '../Components/Pages/PopliveLayout'
import Container from '../Components/SalePage/Container'

const Home = () => {
  const eventOnlineRef = useRef<HTMLElement | null>(null)
  const scrollToEventOnline = () => {
    if (eventOnlineRef.current) {
      eventOnlineRef.current.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }

  return (
    <PopliveLayout scrollToEventOnline={scrollToEventOnline}>
      <Container
        eventOnlineRef={eventOnlineRef}
        scrollToEventOnline={scrollToEventOnline}
      />
    </PopliveLayout>
  )
}

export default Home
