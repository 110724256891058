import React, { useContext } from 'react'
import { Avatar, makeStyles } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AppContext from '../../Services/AppContext'

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.avatar.root,
    cursor: 'pointer',
    fontSize: 12,
    width: 32,
    height: 32,
    textTransform: 'uppercase',
    border: 'none',
    '&.avatar': {
      width: 40,
      height: 40
    },

    '&.profile': {
      fontSize: 32,
      width: 120,
      height: 120,
      margin: 'auto auto'
    },

    '&.pinMessage': {
      ...theme.avatar.pinMessage,
      cursor: 'pointer'
    }
  }
}))

const AvatarProfile = ({ user, type = undefined }) => {
  const classes = useStyles()
  const appContext = useContext(AppContext.Context)
  const findClassName = () => {
    if (type !== undefined) {
      return `${classes.root} ${type}`
    } else return `${classes.root}`
  }

  async function handleOnClick() {
    await appContext.actions.setDisplayProfile(user.userId || user.userIds[0])
  }

  if (user) {
    if (user.photoURL) {
      return (
        <Avatar
          className={findClassName()}
          onClick={() => handleOnClick()}
          alt={user.displayName || user.email}
          src={user.photoURL}
        />
      )
    } else {
      return (
        <Avatar className={findClassName()} onClick={() => handleOnClick()}>
          {user.displayName ? (
            user.displayName[0]
          ) : (
            <FontAwesomeIcon icon={['far', 'user']} />
          )}
        </Avatar>
      )
    }
  } else {
    return null
  }
}

export default AvatarProfile
