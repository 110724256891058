import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Typography, makeStyles } from '@material-ui/core'
import { useStyles } from '../../../utils/styled'

const customeStyles = makeStyles((theme) => ({
  cardRoot: {
    ...theme.lobby.hint.cardRoot,
    padding: 16,
    borderRadius: 16
  },
  hintTitle: {
    ...theme.lobby.hint.title
  },
  hintLabel: {
    ...theme.lobby.label,
    paddingLeft: 40
  }
}))

const HintsContainer = () => {
  const classes = useStyles()
  const customeClasses = customeStyles()

  const hints = [
    {
      icon: 'video',
      title: 'Watch the livestream!',
      description:
        'Choose a livestream room from the menu on the right side to start watching immediately.',
      display: true
    },
    {
      icon: 'comments',
      title: 'Make some noise!',
      description:
        'You can use chat to express yourself or share a comment with other attendees.',
      display: true
    },
    {
      icon: 'chart-bar',
      title: 'Cast your vote!',
      description:
        'You can cast your vote and see what’s other people have voted with our live poll system.',
      display: true
    },
    {
      icon: 'person-booth',
      title: 'Backstage access!',
      description: 'Get into backstage room to view exclusive live contents.',
      display: false
    }
  ]

  const HintCard = ({ icon, title, description }) => {
    return (
      <Box className={customeClasses.cardRoot} mb={2}>
        <Box display="flex" alignItems="center" mb={1}>
          <FontAwesomeIcon
            icon={['fal', icon]}
            size="2x"
            className={classes.mr1}
          />
          <Typography variant="h5">
            <strong>{title}</strong>
          </Typography>
        </Box>

        <Box className={customeClasses.hintLabel}>
          <Typography variant="h6">{description}</Typography>
        </Box>
      </Box>
    )
  }

  return (
    <React.Fragment>
      <Box display="flex" alignItems="center">
        <FontAwesomeIcon
          icon={['far', 'info-circle']}
          size="2x"
          className={`${customeClasses.hintTitle} ${classes.mr1}`}
        />
        <Typography variant="h5" className={customeClasses.hintTitle}>
          <strong>What to do in a virtual event?</strong>
        </Typography>
      </Box>

      <div className={classes.spacer}></div>

      {hints.map((hint, idx) => {
        if (hint.display) {
          return (
            <HintCard
              key={idx}
              icon={hint.icon}
              title={hint.title}
              description={hint.description}
            />
          )
        }
        return null
      })}
    </React.Fragment>
  )
}

export default HintsContainer
