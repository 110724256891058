import { useMemo } from 'react'
import { makeStyles, Typography, createStyles } from '@material-ui/core'

import { ThemeSessionType, TicketTypeType } from '../utils/type'

interface UserTimerReminingProps {
  timeRemaining: number
  percent: number
  ticketType: TicketTypeType
  isPlayedVideo: boolean
}

interface customerStyleProps {
  timePercent: number
  timeRemaining: number
  isPlayedVideo: boolean
}

const customStyle = makeStyles((theme: ThemeSessionType) =>
  createStyles({
    timer: {
      ...theme.timer.timer,
      overflow: 'hidden',
      position: 'relative',
      height: ({ isPlayedVideo }: customerStyleProps) =>
        isPlayedVideo ? '10px' : '21px',
      width: '100%',
      transition: 'all 1s ease-out',
      marginTop: 4,
      '&:hover': {
        height: '21px'
      },
      '&:hover $time': {
        borderRadius: ({ timePercent, isPlayedVideo }: customerStyleProps) =>
          timePercent >= 6 ? '0px 4px 4px 0px' : 0
      },
      '&:hover $timeText': {
        height: '100%',
        opacity: 1
      }
    },

    time: {
      ...theme.timer.time,
      position: 'relative',
      zIndex: 9,
      top: 0,
      width: ({ timePercent, timeRemaining }: customerStyleProps) =>
        timePercent < 1 && timeRemaining > 0 ? '1%' : `${timePercent}%`,
      height: '100%',
      borderRadius: ({ isPlayedVideo }: customerStyleProps) =>
        isPlayedVideo ? '0px 4px 4px 0px' : 0
    },

    timeText: {
      ...theme.timer.timeText,
      display: 'inline-block',
      position: 'absolute',
      top: 0,
      opacity: ({ isPlayedVideo }: customerStyleProps) =>
        isPlayedVideo ? 0 : 1,
      left: ({ timePercent }: customerStyleProps) =>
        timePercent < 6 ? `${timePercent}%` : '',
      right: ({ timePercent }: customerStyleProps) =>
        timePercent >= 6 ? `${100 - timePercent}%` : '',
      zIndex: 10,
      height: ({ isPlayedVideo }: customerStyleProps) =>
        isPlayedVideo ? '0px' : '21px',
      transition: 'all 1s ease-out',
      padding: '0 4px',
      lineHeight: '21px',
      fontSize: '12px !important',
      borderRadius: '0px 4px 4px 0px'
    }
  })
)

export default function UserTimeRemainingTimer({
  timeRemaining = 0,
  ticketType,
  isPlayedVideo
}: UserTimerReminingProps) {
  const timePercent = useMemo(() => {
    return Math.round((timeRemaining * 100) / ticketType.durationSeconds)
  }, [timeRemaining, ticketType.durationSeconds])

  const customClasses = customStyle({
    timePercent,
    timeRemaining,
    isPlayedVideo
  })

  return (
    <div className={customClasses.timer} data-testid="time-remaining">
      <Typography className={customClasses.timeText}>
        {timeRemaining < 120
          ? `${timeRemaining} seconds`
          : `${Math.round(timeRemaining / 60)} mins`}
      </Typography>
      <div className={customClasses.time} />
    </div>
  )
}
