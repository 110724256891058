import React, { useEffect, useState } from 'react'
const PollContext = React.createContext()

const PollContextProvider = ({ poll, children }) => {
  const [choices, setChoices] = useState([])

  useEffect(() => {
    if (poll?.choices) {
      setChoices(poll.choices)
    }
  }, [poll])

  return (
    <PollContext.Provider value={{ poll, choices }}>
      {children}
    </PollContext.Provider>
  )
}

export default {
  Context: PollContext,
  Provider: PollContextProvider
}
