import React from 'react'
import { makeStyles, Box } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useStyles } from '../utils/styled'

const customStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    '& img': {
      ...theme.banners.wrapper,
      width: '100%',
      borderRadius: 8,
      aspectRatio: '16/9',
      objectFit: 'cover'
    }
  },
  action: {
    cursor: 'pointer',

    '& :hover': {
      opacity: 1.6
    }
  },
  iconLink: {
    position: 'absolute',
    right: 8,
    bottom: 16,
    height: '24px',
    width: '24px',
    color: 'white',
    padding: 4,
    borderRadius: '100px',
    backgroundColor: 'rgba(0,0,0,0.25)',
    '& svg': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  }
}))

const LiveCardItem = ({ card }) => {
  const classes = useStyles()
  const customClasses = customStyles()

  if (!card.link) {
    return (
      <Box className={customClasses.card}>
        <img
          src={card.url}
          className={classes.imgResponsive}
          alt="link live card"
        />
      </Box>
    )
  }

  return (
    <a href={card.link} target="_blank" rel="noopener noreferrer">
      <div className={`${customClasses.card} ${customClasses.action}`}>
        <img src={card.url} className={classes.imgResponsive} alt="live card" />
        <Box className={customClasses.iconLink}>
          <FontAwesomeIcon icon={['far', 'external-link']} size="xs" />
        </Box>
      </div>
    </a>
  )
}

export default LiveCardItem
