import React, { useState, useEffect } from 'react'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import LinesEllipsis from 'react-lines-ellipsis'
import moment from 'moment'

export default function RelativeTimer({ timestamp }) {
  const [timer, setTimer] = useState(moment(timestamp).fromNow(true))
  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)

  useEffect(() => {
    const interval = setInterval(() => {
      const time = moment(timestamp).fromNow(true)
      setTimer(time)
    }, 1000 * 60) /// Delay 1 minute

    return () => {
      clearInterval(interval)
    }
  }, [timestamp])
  return (
    <ResponsiveEllipsis
      text={timer}
      maxLine="1"
      ellipsis="..."
      basedOn="words"
    />
  )
}
