import React from 'react'
import { Box, Typography, makeStyles, Divider } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from '@reach/router'

import { variables } from '../../utils/styled'

const customStyles = makeStyles((theme) => ({
  footerWrapper: {
    width: '100%',
    ...theme.redeemTicket.footerTheme
  },
  footerEventListWrapper: {
    width: '100%',
    background: 'white',
    color: 'red'
  },
  footer: {
    display: 'grid',
    gridTemplateColumns: '1fr 0.5fr',
    rowGap: '24px',
    maxWidth: '1080px',
    margin: '0 auto',
    width: '100%',
    minHeight: '72px',
    [theme.breakpoints.down('md')]: {
      padding: '0 24px'
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      padding: '24px 0'
    }
  },
  textInFooter: {
    color: variables.gray,
    display: 'flex',
    gap: '16px',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      textAlign: 'center',
      gap: '16px'
    }
  },
  iconContactFooter: {
    color: variables.gray,
    display: 'flex',
    gap: '20px',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },

  // Icon social media hover
  filterHover: {
    fontSize: '20px',
    transition: 'all 0.25s ease',
    ...theme.colorFromTheme.root
  },
  iconSalePage: {
    fontSize: '20px',
    color: variables.popliveRed
  },
  divider: {
    ...theme.divider,
    ...theme.redeemTicket.divider,
    width: '100%'
  }
}))

function Footer() {
  const location = useLocation()
  const customClasses = customStyles()
  const { pathname } = location
  return (
    <Box
      className={
        pathname === '/'
          ? customClasses.footerEventListWrapper
          : customClasses.footerWrapper
      }
    >
      <Divider className={customClasses.divider} />
      <Box
        className={customClasses.footer}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box className={customClasses.textInFooter}>
          <Typography variant="h6">©2020 Event Pop</Typography>
          {/* <Divider orientation="vertical" flexItem />
          <Typography variant='h6'>ข้อตกลงการใช้บริการ</Typography>
          <Divider orientation="vertical" flexItem />
          <Typography variant='h6'>นโยบายความเป็นส่วนตัว </Typography>
          <Divider orientation="vertical" flexItem />
          <Typography variant='h6'>ศูนย์ช่วยเหลือ</Typography> */}
        </Box>
        <Box className={customClasses.iconContactFooter}>
          <a
            href="https://www.facebook.com/eventpop/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon
              icon={['fab', 'facebook']}
              className={
                pathname === '/'
                  ? customClasses.iconSalePage
                  : customClasses.filterHover
              }
            />
          </a>
          <a
            href="https://www.instagram.com/eventpop"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon
              icon={['fab', 'instagram']}
              className={
                pathname === '/'
                  ? customClasses.iconSalePage
                  : customClasses.filterHover
              }
            />
          </a>
          <a
            href="https://page.line.me/jeb8791a"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon
              icon={['fab', 'line']}
              className={
                pathname === '/'
                  ? customClasses.iconSalePage
                  : customClasses.filterHover
              }
            />
          </a>
          <a
            href="https://twitter.com/eventpopdotme"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon
              icon={['fab', 'twitter']}
              className={
                pathname === '/'
                  ? customClasses.iconSalePage
                  : customClasses.filterHover
              }
            />
          </a>
        </Box>
      </Box>
    </Box>
  )
}

export default Footer
