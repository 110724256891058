import { useContext, useMemo } from 'react'
import {
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  makeStyles,
  createStyles,
  Box
} from '@material-ui/core'
import { sortBy } from 'lodash'

import EventContext from '../Services/EventContext'
import ViewersCount from './ViewersCount'
import ChipSessionStatus from './ChipSessionStatus'
import Timer from './Timer'
import LivestreamDetails from './Livestream/Details'
import LiveCards from './LiveCards'
import { CheckStateSession, ConvertTimeStamp } from '../utils/helper'
import CarouselBanners from './Shares/CarouselBanners'
import { SessionType, LiveCardType } from '../utils/type'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: `${theme.spacing(3)}px ${theme.spacing(2)}px`
    },
    icon: {
      cursor: 'pointer',
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5)
    }
  })
)
interface VideoDescriptionType {
  session: SessionType
}

const VideoDescription = ({ session }: VideoDescriptionType) => {
  const classes = useStyles()
  const theme = useTheme()
  const mdSizeUp = useMediaQuery(theme.breakpoints.up('md'))
  const smSizeDown = useMediaQuery(theme.breakpoints.down('sm'))
  const eventContext = useContext(EventContext.Context)
  const { eventBanners } = eventContext

  const liveCards = useMemo(() => {
    let liveCards: LiveCardType[] = []
    if (session?.liveCards?.length > 0) {
      liveCards = sortBy(session.liveCards, ['order'])
    }
    return liveCards
  }, [session.liveCards, session.sessionId])

  const liveStreamState = useMemo(() => {
    const start = ConvertTimeStamp(session.startAt).seconds * 1000
    const end = ConvertTimeStamp(session.endAt).seconds * 1000
    return CheckStateSession(
      session.isLive,
      start,
      end,
      eventContext.timeCurrent
    )
  }, [session.startAt, session.sessionId])

  return (
    <Box className={classes.root}>
      <Grid container alignItems={mdSizeUp ? 'center' : 'baseline'} spacing={1}>
        <Grid item xs={12}>
          <Box display="flex" gridGap="6px">
            {liveStreamState !== 'wait' && (
              <ChipSessionStatus
                liveStreamState={liveStreamState}
                isLive={session.isLive}
              />
            )}

            <ViewersCount
              sessionId={session.sessionId}
              viewersIncreament={session.viewersIncremental ?? 0}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={mdSizeUp ? 'h4' : 'h3'}>
            <b>{session.fullTitle}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Timer startAt={session.startAt} endAt={session.endAt} />
        </Grid>
        <Grid item xs={12}>
          <LivestreamDetails session={session} />
        </Grid>
        {smSizeDown && eventBanners.length > 0 && (
          <Grid item xs={12} sm={6}>
            <Box my="16px">
              <CarouselBanners banners={eventBanners} />
            </Box>
          </Grid>
        )}

        <Grid item xs={12}>
          <LiveCards liveCards={liveCards} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default VideoDescription
