import React, { useContext, useState, useEffect, useMemo, useRef } from 'react'
import { Box, Typography, makeStyles, createStyles } from '@material-ui/core'

import EventContext from '../../Services/EventContext'
import { ChapterType, ThemeSessionType } from '../../utils/type'
import { convertSecondsToTimeFormat } from '../../utils/helper'
import useWindowDimensions from '../../hooks/useWindowDimensions'

type LiveStreamChapterProps = {
  sessionId: string
  eventPoster: string
}
interface CustomStyleProps {
  height?: number
  viedoHeight: number
}
const customStyles = makeStyles((theme: ThemeSessionType) =>
  createStyles({
    root: {
      [theme.breakpoints.down('md')]: {
        height: ({ viedoHeight }: CustomStyleProps) =>
          `calc(100vh -  ${viedoHeight + 125}px)`
      },
      [theme.breakpoints.up('md')]: {
        height: (props: CustomStyleProps) => {
          return `calc(100vh -  ${props.height ?? 0}px)`
        },
        overflowY: 'auto'
      },

      width: '100%',
      overflowX: 'hidden',
      overflowY: 'auto',
      padding: '16px',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column'
    },
    chapterTitle: {
      ...theme.chapter.chaptersMenuTitle
    },
    title: {
      fontWeight: 'bold',
      ...theme.chapter.chaptersMenuTitle
    },
    listChapterWrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '14px'
    },
    listChapter: {
      display: 'flex',
      gap: theme.spacing(2),
      cursor: 'pointer',
      ...theme.chapter.listChapter,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    badge: {
      ...theme.chapter.badge
    },
    posterWrapper: {
      width: '100px',
      height: ' 56px',
      position: 'relative',
      overflow: 'hidden',
      zIndex: 101,
      borderRadius: theme.spacing(0.5)
    },
    imagePreview: {
      height: '100%',
      position: 'absolute',
      left: '50%',
      top: '50%',
      zIndex: 102,
      transform: 'translate(-50%, -50%)',
      objectFit: 'contain'
    },
    imagePreviewOverlay: {
      width: '100%',
      height: '100%',
      zIndex: 0,
      position: 'absolute',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      filter: 'blur(4px)',
      backgroundPosition: 'center center',
      boxShadow: 'inset 0 0 0 1000px rgba(0, 0, 0, 0.80)'
    }
  })
)

export default function LiveStreamChapter({
  sessionId,
  eventPoster
}: LiveStreamChapterProps): JSX.Element {
  const eventContext = useContext(EventContext.Context)
  const { eventBanners } = eventContext
  const viedoHeightRef = useRef(0)
  const { width } = useWindowDimensions()

  useEffect(() => {
    const track = document.querySelector('#bg-state') //To access the div with class slide track
    if (track) {
      viedoHeightRef.current = track.clientHeight
    }
  }, [width])

  const calculateHeight = useMemo(() => {
    let height = 68
    if (eventBanners.length > 0) {
      height += 200
    }
    return height
  }, [eventBanners.length])

  const { actions } = eventContext

  const customClass = customStyles({
    height: calculateHeight,
    viedoHeight: viedoHeightRef.current
  })
  const [chapters, setChapters] = useState([])

  useEffect(() => {
    const loadChapter = async () => {
      const getChapters = await eventContext.actions.loadChapter(sessionId)
      if (getChapters) {
        setChapters(getChapters)
      }
    }
    if (sessionId) {
      loadChapter()
    }
  }, [sessionId])

  const handleClickJumpToTimeSkip = (seconds: number): void => {
    actions.videoTimeSkip(seconds)
  }

  return (
    <Box className={customClass.root}>
      <div>
        <Typography variant="h5" className={customClass.title}>
          Chapters <span>({chapters?.length})</span>
        </Typography>
        <div className={customClass.listChapterWrapper}>
          {chapters.map((chapter: ChapterType, index: number) => {
            return (
              <Box
                key={index}
                onClick={() => handleClickJumpToTimeSkip(chapter.seconds)}
                className={customClass.listChapter}
              >
                <div className={customClass.posterWrapper}>
                  <img
                    src={chapter.imageUrl ? chapter.imageUrl : eventPoster}
                    alt={`chapter ${chapter.name}`}
                    className={customClass.imagePreview}
                    style={{
                      backgroundImage: `url(${
                        chapter.imageUrl ? chapter.imageUrl : eventPoster
                      })`
                    }}
                  />
                  <Box className={customClass.imagePreviewOverlay} />
                </div>

                <Box display="flex" flexDirection="column" gridGap="8px">
                  <Box className={customClass.chapterTitle}>{chapter.name}</Box>
                  <div>
                    <Typography className={customClass.badge}>
                      {convertSecondsToTimeFormat(chapter.seconds)}
                    </Typography>
                  </div>
                </Box>
              </Box>
            )
          })}
        </div>
      </div>
    </Box>
  )
}
