import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    marginBottom: 16
  },
  title: {
    marginBottom: 8
  },
  description: {
    color: theme.text.description
  }
}))

const DisabledWidget = ({ widget }) => {
  const classes = useStyles()

  const widgets = {
    chat: {
      icon: 'times',
      title: 'Chat is disabled',
      description: 'The host has disabled chat for this session.'
    },
    poll: {
      icon: 'times',
      title: 'Poll is disabled',
      description: 'The host has disabled poll for this session.'
    }
  }

  return (
    <div className={classes.root}>
      <Box textAlign="center">
        <FontAwesomeIcon
          className={classes.icon}
          icon={['far', widgets[`${widget}`].icon]}
          size="2x"
        />

        <Typography className={classes.title} variant="h5">
          {widgets[`${widget}`].title}
        </Typography>
        <Typography className={classes.description} variant="caption">
          {widgets[`${widget}`].description}
        </Typography>
      </Box>
    </div>
  )
}

export default DisabledWidget
