import { Box, Typography, makeStyles } from '@material-ui/core'
import { navigate } from '@reach/router'

import BlockSession from './BlockSession'
import PathHelper from '../../utils/PathHelper'
import useWindowDimensions from '../../hooks/useWindowDimensions'

const customeStyles = makeStyles((theme) => ({
  scrollSession: {
    display: 'flex',
    overflowX: 'auto',
    gap: '16px'
  },
  listSession: {
    minWidth: '313px',
    width: '50vw'
  },
  seeMoreButton: {
    ...theme.seeMore.button
  }
}))

export default function MoreSession({ sessions, currentSession, event }) {
  const { width } = useWindowDimensions()
  const customClasses = customeStyles()

  const handleDrawerToggle = () => {
    navigate(PathHelper.event.lobbyPath(event.slug))
  }

  return (
    <Box mx="16px" my="24px">
      <Box display="flex" justifyContent="space-between" mb="16px">
        <Typography variant="h4">
          <strong>More Session</strong>
        </Typography>
        <Box
          onClick={() => handleDrawerToggle()}
          className={customClasses.seeMoreButton}
        >
          <Typography>See more</Typography>
        </Box>
      </Box>
      <Box className={customClasses.scrollSession} width={`${width - 32}px`}>
        {sessions.map((session) => {
          return (
            <Box key={session.sessionId} className={customClasses.listSession}>
              <BlockSession session={session} eventSlug={event.slug} />
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
