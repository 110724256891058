import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import App from './App'
import AppContext from './Services/AppContext'
import ThemeContext from './Theme/ThemeContext'
import * as serviceWorker from './serviceWorker'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false // default: true
    }
  }
})

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <AppContext.Provider>
      <ThemeContext.Provider>
        <App />
      </ThemeContext.Provider>
    </AppContext.Provider>
  </QueryClientProvider>,

  document.getElementById('root')!
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
