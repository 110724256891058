import React, { useContext, useEffect, useState, useMemo } from 'react'
import {
  AppBar,
  Tab,
  Tabs,
  Hidden,
  useTheme,
  useMediaQuery,
  makeStyles,
  Typography,
  Box
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AppContext from '../../Services/AppContext'
import EventContext from '../../Services/EventContext'
import Avatar from '../Shares/Avatar'
import CarouselBanners from '../Shares/CarouselBanners'
import LabelPollWithBadge from './LabelWithBadge/Polls'

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.tabMenus.root,
    boxShadow: 'none',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    zIndex: '1000',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',

    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(3)
    },

    '& .tabs': {
      width: '90%'
    },

    '& button': {
      minWidth: 50,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),

      '& svg': {
        fontSize: 24
      }
    },

    '& [class*="MuiTabs-indicator-"]': {
      display: 'none'
    },

    '&.hidden': {
      '& .Mui-selected': {
        ...theme.tabMenus.root,
        borderBottom: 0,
        borderTop: 0
      }
    }
  },
  tabPanel: {
    position: 'relative'
  },
  scroller: {
    flexGrow: '0',
    width: '30px'
  },
  icon: {
    ...theme.colorFromTheme.root
  }
}))

function a11yProps(tab, index) {
  return {
    id: tab.id || `livestream-tab-${index}`,
    'aria-controls': `livestream-tabpanel-${index}`
  }
}

function TabPanel({ children, value, index }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`livestream-tabpanel-${index}`}
      aria-labelledby={`livestream-tab-${index}`}
    >
      <div>{children}</div>
    </div>
  )
}

const TabMenusWrapper = ({ children }) => {
  const theme = useTheme()
  const mdSizeDown = useMediaQuery(theme.breakpoints.down('md'))

  if (mdSizeDown) return <React.Fragment>{children}</React.Fragment>

  return <div>{children}</div>
}

const TabMenus = (props) => {
  const eventContext = useContext(EventContext.Context)
  const banners = eventContext?.eventBanners || []
  const calHeighTabPanel = () => {
    let height = 124
    if (banners.length > 0) {
      height += 200
    }
    return height
  }
  const [tabValue, setTabValue] = useState(0)
  const classes = useStyles({ height: calHeighTabPanel() })
  const theme = useTheme()
  const appContext = useContext(AppContext.Context)
  const intercomAppId = eventContext?.event?.intercomAppId ?? null
  const crispWebsiteId = eventContext?.event?.crispWebsiteId ?? null
  const mdSizeUp = useMediaQuery(theme.breakpoints.up('md'))
  const widgets = eventContext.actions.loadWidgets(props.session.sessionId)
  const polls = props.poll.props?.polls ?? []

  const user = useMemo(
    () => appContext.currentProfile,
    [appContext.currentProfile]
  )
  let tabMenus = [
    {
      icon: ['far', 'comment'],
      name: 'Chat',
      value: props.chat
    }
  ]
  if (polls.length > 0 && widgets.poll) {
    tabMenus.push({
      icon: ['far', 'chart-bar'],
      name: 'Poll',
      value: props.poll
    })
  }

  if (widgets.chapters) {
    tabMenus.unshift({
      icon: ['far', 'camcorder'],
      name: 'Chapters ',
      id: 'chapter',
      value: props.chapter
    })
  }
  if (intercomAppId) {
    tabMenus.push({
      icon: ['far', 'comment-alt-check'],
      name: 'Q & A',
      id: 'q-and-a',
      value: props.qa
    })
  } else if (crispWebsiteId) {
    tabMenus.push({
      icon: ['far', 'comment-alt-check'],
      name: 'Q & A',
      id: 'crisp',
      value: props.qa
    })
  }

  useEffect(() => {
    let isUnsubscribed = true
    if (mdSizeUp && isUnsubscribed) {
      appContext.actions.setDisplayMenusTab(true)
    } else {
      appContext.actions.setDisplayMenusTab(false)
    }

    return () => (isUnsubscribed = false)
  }, [mdSizeUp])

  const handleChange = (_, newValue) => {
    setTabValue(newValue)
    appContext.actions.setDisplayMenusTab(true)
  }

  const LabelWithBadge = (icon, tabName, index) => {
    if (tabName === 'Poll') {
      return (
        <LabelPollWithBadge
          icon={icon}
          polls={polls}
          currentTab={tabMenus[tabValue].name}
        />
      )
    }

    return (
      <Box display="flex" gridGap="8px">
        <FontAwesomeIcon icon={icon} />
        {tabValue === index && appContext.isToggleTabMenus && (
          <Typography>
            <b>{tabName}</b>
          </Typography>
        )}
      </Box>
    )
  }

  const displayIcon = () => {
    return appContext.isToggleTabMenus
      ? ['far', 'chevron-down']
      : ['far', 'chevron-up']
  }

  const handleClick = () => {
    appContext.actions.setDisplayMenusTab(!appContext.isToggleTabMenus)
  }

  const handleViewPollTab = (tab) => {
    if (tab === 'Poll') {
      eventContext.actions.setIsShowBadgeOnPollTab(false)
    }
  }

  return (
    <TabMenusWrapper>
      {banners.length > 0 && (
        <Hidden smDown>
          <CarouselBanners banners={banners} />
        </Hidden>
      )}
      <AppBar
        position="sticky"
        className={`${classes.root} ${
          appContext.isToggleTabMenus ? '' : 'hidden'
        }`}
        style={{ bottom: appContext.isToggleTabMenus ? 'initial' : 0 }}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="livestream-tabs"
          variant="scrollable"
          scrollButtons="auto"
          className="tabs"
          classes={{
            scrollButtons: classes.scroller
          }}
        >
          {tabMenus.map((tab, index) => (
            <Tab
              label={LabelWithBadge(tab.icon, tab.name, index)}
              {...a11yProps(tab, index)}
              key={index}
              aria-label={tab.name}
              onClick={(e) => handleViewPollTab(tab.name)}
            />
          ))}
        </Tabs>
        <Hidden only={['xs', 'sm']}>
          <Avatar user={user} type={'avatar'} />
        </Hidden>

        <Hidden only={['md', 'lg', 'xl']}>
          <span onClick={handleClick} className={classes.icon}>
            <FontAwesomeIcon icon={displayIcon()} size="lg" />
          </span>
        </Hidden>
      </AppBar>
      {appContext.isToggleTabMenus &&
        tabMenus.map((tab, index) => (
          <TabPanel
            value={tabValue}
            index={index}
            key={index}
            className={classes.tabPanel}
          >
            {tab.value}
          </TabPanel>
        ))}
    </TabMenusWrapper>
  )
}

export default TabMenus
