import React, { useMemo } from 'react'
import { Box, makeStyles, createStyles, Typography } from '@material-ui/core'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import LinesEllipsis from 'react-lines-ellipsis'
import moment from 'moment'

import ChipSessionStatus from '../ChipSessionStatus'
import Timer from '../Livestream/Chips/Timer'
import { variables } from '../../utils/styled'
import { EventType } from '../../utils/type'

const customStyles = makeStyles((theme) =>
  createStyles({
    poster: {
      width: '100%',
      height: '295px',
      position: 'relative',
      borderRadius: '8px',
      border: `1px solid ${variables.grayLight}`,
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: '313px'
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: '207px'
      },
      '& img': {
        position: 'relative',
        objectFit: 'cover',
        height: '100%',
        width: '100%',
        borderRadius: '8px',
        zIndex: 1
      }
    },
    eventTitle: {},
    chipStatusWrapper: {
      position: 'absolute',
      top: '12px',
      left: '18px',
      zIndex: 99
    },
    timerWrapper: {
      color: variables.grayBase
    }
  })
)
interface EventBlockType {
  event: EventType
}

function EventBlock({ event }: EventBlockType) {
  const customClasses = customStyles()
  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)
  const isLive = useMemo(() => {
    let live = false
    const eventEndAt = moment(event.endAt._seconds * 1000)
    const eventEnded = moment().isAfter(eventEndAt)
    if (event?.sessions) {
      event?.sessions.some((session) => {
        if (session.isLive && !eventEnded) {
          live = true
        }
        return live
      })
    }

    return live
  }, [event.sessions])

  return (
    <React.Fragment>
      <Box className={customClasses.poster}>
        {isLive && (
          <Box className={customClasses.chipStatusWrapper}>
            <ChipSessionStatus liveStreamState={'start'} isLive={true} />
          </Box>
        )}
        <img src={event.posterUrl} alt={event.title} />
      </Box>
      <Box mt="12px">
        <Typography variant="h5">
          <b>
            <ResponsiveEllipsis
              text={event.title}
              maxLine="2"
              ellipsis="..."
              basedOn="words"
            />
          </b>
        </Typography>
        <Box mt="8px">
          <Typography variant="h6">
            <Timer
              startAt={event.startAt}
              endAt={event.endAt}
              icon={'calendar-alt'}
            />
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default EventBlock
