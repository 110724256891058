import {
  realtimedb,
  firestore,
  functions,
  storageRef
} from '../Services/firebaseApp'
import {
  collection,
  where,
  query,
  limit,
  getDoc,
  doc,
  runTransaction,
  getDocs
} from 'firebase/firestore'
import { ref, getDownloadURL } from 'firebase/storage'
import { httpsCallable } from 'firebase/functions'
import {
  ref as realtimeRef,
  onValue,
  onDisconnect,
  remove,
  set,
  push,
  update,
  off,
  get
} from 'firebase/database'

const getUserJoinedSession = httpsCallable(functions, 'getUserJoinedSession')
const updateUserTimeVideoRemaining = httpsCallable(
  functions,
  'updateUserTimeVideoRemaining'
)

const storage = storageRef
const getProfileRole = (role) => {
  const roles = {
    host: 'Host',
    invisibleHost: 'Invisible Host',
    viewer: 'Viewer'
  }

  if (!role) return roles['viewer']
  return roles[role]
}

export const enterPresenceInSession = async (
  eventId,
  sessionId,
  user,
  presenceId
) => {
  const connectionRef = realtimeRef(realtimedb, '.info/connected')
  const profile = await _getProfileFromUserId(user.uid)

  const ticketSnapshot = await getDoc(
    doc(firestore, `events/${eventId}/tickets/${profile.id}`)
  )

  const ticket = ticketSnapshot.data()

  const ticketInfo = {
    role: getProfileRole(ticket?.ticketRole),
    email: ticket?.email || 'anonymous',
    firstname: ticket?.firstname || '-',
    lastname: ticket?.lastname || '-',
    displayName: ticket?.displayName || '-'
  }

  onValue(connectionRef, (snapshot) => {
    const statusRef = realtimeRef(
      realtimedb,
      `usersInSession/${eventId}/${sessionId}/${profile.id}`
    )
    if (snapshot.val() === false) {
      return
    }

    onDisconnect(statusRef)
      .remove()
      .then(() => {
        set(statusRef, {
          profileId: profile.id,
          displayName: profile.displayName,
          photoURL: profile.photoURL,
          presenceId,
          ticketInfo
        })
      })
  })
}

export const exitPresenceInSession = async (eventId, sessionId, user) => {
  const profile = await _getProfileFromUserId(user.uid)

  const statusRef = realtimeRef(
    realtimedb,
    `usersInSession/${eventId}/${sessionId}/${profile.id}`
  )
  await remove(statusRef)

  const connectionRef = realtimeRef(realtimedb, '.info/connected')
  off(connectionRef, 'value')
}
export const updateTimeVideoRemaining = async (secondsRemaining, user) => {
  const profile = await _getProfileFromUserId(user.uid)
  const { eventId, id: ticketRef } = profile

  await updateUserTimeVideoRemaining({ ticketRef, secondsRemaining, eventId })
}

export const checkPresence = async (
  eventId,
  sessionId,
  user,
  currentPresenceId
) => {
  const profile = await _getProfileFromUserId(user.uid)

  const statusRef = realtimeRef(
    realtimedb,
    `usersInSession/${eventId}/${sessionId}/${profile.id}`
  )
  const snapshot = await get(statusRef)
  const val = snapshot.val()
  if (val) {
    return val.presenceId !== currentPresenceId
  }

  return false
}
export const subscribeViewersInSession = (eventId, sessionId) => {
  let viewer = 0

  const presenceRef = realtimeRef(
    realtimedb,
    `usersInSession/${eventId}/${sessionId}`
  )
  onValue(presenceRef, (data) => {
    viewer = data.numChildren()
  })

  return viewer
}

export const unsubscribeViewersInSession = (eventId, sessionId) => {
  const presenceRef = realtimeRef(
    realtimedb,
    `usersInSession/${eventId}/${sessionId}`
  )
  off(presenceRef, 'value')
}

export const subscribePresenceChange = async (
  eventId,
  sessionId,
  user,
  callback
) => {
  const profile = await _getProfileFromUserId(user.uid)

  const presenceRef = realtimeRef(
    realtimedb,
    `usersInSession/${eventId}/${sessionId}/${profile.id}`
  )
  onValue(presenceRef, (data) => {
    callback({ ...data.val(), id: data.key })
  })
}

export const unsubscribePresenceChange = async (eventId, sessionId, user) => {
  const profile = await _getProfileFromUserId(user.uid)

  const presenceRef = realtimeRef(
    realtimedb,
    `usersInSession/${eventId}/${sessionId}/${profile.id}`
  )
  off(presenceRef, 'value')

  return true
}

const _getProfileFromUserId = async (userId) => {
  const profileRef = collection(firestore, 'profiles')
  const profileQuery = query(
    profileRef,
    where('userIds', 'array-contains', userId),
    limit(1)
  )

  const profileRes = await getDocs(profileQuery)
  const profile = profileRes.docs[0] ? profileRes.docs[0].data() : null

  return profile
}

export const getPollsDoc = (evenId, sessionId) => {
  return collection(firestore, `events/${evenId}/sessions/${sessionId}/polls`)
}

const createAttendeeVoteToRtdb = async (
  pollId,
  choicesIds,
  userId,
  eventId,
  sessionId
) => {
  const voteListRef = realtimeRef(
    realtimedb,
    `pollVotes/${eventId}/${sessionId}/${pollId}/${userId}`
  )

  const newVoteRef = push(voteListRef)
  await update(newVoteRef, { choicesIds: choicesIds })
}

const editAttendeeVoteToRtdb = async (
  pollId,
  choicesIds,
  userId,
  eventId,
  sessionId
) => {
  const voteListRef = realtimeRef(
    realtimedb,
    `pollVotes/${eventId}/${sessionId}/${pollId}/${userId}`
  )

  await update(voteListRef, { choicesIds: choicesIds })
}

const createAttendeeVoteToFirestore = async (params) => {
  const { profileId, eventId, sessionId, pollId, choicesIds } = params
  const pollRef = doc(
    collection(firestore, `events/${eventId}/sessions/${sessionId}/polls`),
    pollId
  )

  await runTransaction(firestore, async (t) => {
    const pollDoc = await t.get(pollRef)
    const pollData = pollDoc.data()
    const choices = pollData.choices

    choicesIds.forEach((id) => {
      const findIndexChoice = choices.findIndex((choice) => choice.id === id)
      choices[findIndexChoice].votedCount += 1
      !choices[findIndexChoice].voter.includes(profileId) &&
        choices[findIndexChoice].voter.push(profileId)
    })
    t.update(pollDoc.ref, { choices, votedCount: (pollData.votedCount += 1) })
  })
}

const editAttendeeVoteToFirestore = async (params) => {
  const { profileId, eventId, sessionId, pollId, choicesIds } = params
  const pollRef = doc(
    collection(firestore, `events/${eventId}/sessions/${sessionId}/polls`),
    pollId
  )

  await runTransaction(firestore, async (t) => {
    const pollDoc = await t.get(pollRef)
    const pollData = pollDoc.data()
    const choices = pollData.choices

    choices.forEach((choice) => {
      if (choice.voter.includes(profileId)) {
        choice.votedCount -= 1
        choice.voter = choice.voter.filter((voterId) => voterId !== profileId)
      }
    })

    choicesIds.forEach((id) => {
      const findIndexChoice = choices.findIndex((choice) => choice.id === id)
      choices[findIndexChoice].votedCount += 1
      !choices[findIndexChoice].voter.includes(profileId) &&
        choices[findIndexChoice].voter.push(profileId)
    })
    t.update(pollDoc.ref, {
      choices
    })
  })
}

export const votePoll = async (params) => {
  const { profileId, pollId, choicesIds, sessionId, eventId } = params

  await createAttendeeVoteToRtdb(
    pollId,
    choicesIds,
    profileId,
    eventId,
    sessionId
  )
  await createAttendeeVoteToFirestore(params)
}

export const editChoiceVotePoll = async (params) => {
  const { profileId, pollId, choicesIds, sessionId, eventId } = params
  await editAttendeeVoteToRtdb(
    pollId,
    choicesIds,
    profileId,
    sessionId,
    eventId
  )
  await editAttendeeVoteToFirestore(params)
}

export const getViewersJoinedSession = async (eventSlug, sessionId) => {
  const result = await getUserJoinedSession({ eventSlug, sessionId })
  return result.data
}

export const getImageUrlByImageRef = async (image) => {
  if (image) {
    const imageRef = ref(storage, image)
    const imageUrl = await getDownloadURL(imageRef)
    return imageUrl
  }
  return ''
}
