import { useContext, useState, useEffect } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { Box, Divider, Typography, makeStyles } from '@material-ui/core'

import AppContext from '../../Services/AppContext'
import EventContext from '../../Services/EventContext'
import PollContext from '../../Services/PollContext'

import { pollCardStyles } from '../../utils/styled'
import {
  TimeIsBetweenStarAtAndEndAt,
  ConvertTimeStamp
} from '../../utils/helper'
import { QuestionAndDescription, FormVotePoll } from './Components'
import PollResultModal from '../Modal/PollResultModal'

const customStyles = makeStyles((theme) => ({
  viewResultButton: {
    ...theme.poll.viewResultButton
  }
}))

const PollCardInnerComponent = ({ eventId, sessionId }) => {
  const classes = pollCardStyles()
  const customClasses = customStyles()
  const appContext = useContext(AppContext.Context)
  const eventContext = useContext(EventContext.Context)
  const pollContext = useContext(PollContext.Context)
  const profile = appContext.currentProfile
  const poll = pollContext.poll

  const { isBeforeStart, isBetween } = TimeIsBetweenStarAtAndEndAt(
    poll.startAt,
    poll.endAt,
    eventContext.timeCurrent
  )

  const handlePollOpenOrClose = (seconds) => {
    const now = new Date().getTime()
    const diff = new Date(seconds * 1000).getTime() - now

    setTimeout(async () => {
      await eventContext.actions.handleReloadPolls()
      await eventContext.actions.setIsShowBadgeOnPollTab(true)
    }, diff + 1000)
  }

  useEffect(() => {
    if (poll && poll.openAndCloseOptions === 'specificTime' && isBetween) {
      handlePollOpenOrClose(ConvertTimeStamp(poll.endAt).seconds)
    } else if (isBeforeStart) {
      handlePollOpenOrClose(ConvertTimeStamp(poll.startAt).seconds)
    }
  }, [poll])

  const [choices, setChoices] = useState([])
  const [isOpenDialogResult, setisOpenDialogResult] = useState(false)

  useDeepCompareEffect(() => {
    setChoices(pollContext.choices)
  }, [pollContext.choices])

  const handdleOpenDialogPollResult = () => {
    setisOpenDialogResult(true)
  }

  const handdleCloseDialogPollResult = () => {
    setisOpenDialogResult(false)
  }

  if (!poll) return null
  if (choices.length === 0) return null
  if (isBeforeStart) return null

  return (
    <Box mb={2} className={classes.root} data-testid="poll">
      <QuestionAndDescription poll={poll} />
      {profile && (
        <FormVotePoll
          profileId={profile.id}
          eventId={eventId}
          sessionId={sessionId}
        />
      )}

      {(appContext.ticketRole === 'host' ||
        appContext.ticketRole === 'invisibleHost') && (
        <Box mt="24px">
          <Divider />
          <Box
            className={customClasses.viewResultButton}
            mt="24px"
            textAlign="center"
            onClick={() => handdleOpenDialogPollResult()}
          >
            <Typography>View Results</Typography>
          </Box>
        </Box>
      )}
      <PollResultModal
        poll={poll}
        isOpenDialogResult={isOpenDialogResult}
        handdleCloseDialogPollResult={handdleCloseDialogPollResult}
      />
    </Box>
  )
}

const PollCard = (props) => {
  return (
    <PollContext.Provider sessionId={props.sessionId} poll={props.poll}>
      <PollCardInnerComponent {...props} />
    </PollContext.Provider>
  )
}

export default PollCard
