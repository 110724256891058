import React, { useState, useEffect } from 'react'
import { firestore, storageRef, firebaseAuth } from './firebaseApp'
import { navigate } from '@reach/router'
import Cookies from 'universal-cookie'
import { useQuery } from '@tanstack/react-query'
import {
  collection,
  query,
  where,
  limit,
  setDoc,
  getDoc,
  doc,
  getDocs
} from 'firebase/firestore'
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage'
import {
  onAuthStateChanged,
  signInAnonymously,
  setPersistence,
  browserSessionPersistence,
  GoogleAuthProvider,
  browserLocalPersistence,
  signInWithRedirect,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile
} from 'firebase/auth'

import PathHelper from '../utils/PathHelper'
import { useLocalStorage } from '../utils/useLocalStorage'
import EventService from './EventService'
import { authUserCallback } from './UsersService'

const auth = firebaseAuth

const AppContext = React.createContext({})
const cookies = new Cookies()

// HOC
const AppContextProvider = (props) => {
  const [user, setUser] = useLocalStorage('user', null)
  const [userState, setUserState] = useState()
  const [redirectTo, setRedirectTo] = useLocalStorage('redirectTo', null)
  const [refCode, setRefCode] = useLocalStorage('refCode', null)
  const [ticket, setTicket] = useState()
  const [ticketRole, setTicketRole] = useState(null)
  const [ticketType, setTicketType] = useState()
  const [timeRemainingSeconds, setTimeRemainingSeconds] = useState(null)
  const [firstTimeRenderLobby, setFirstTimeRenderLobby] = useLocalStorage(
    'firstTimeRenderLobby',
    true
  )
  const [flags, setFlags] = useState(null)
  const [currentEventSlug, setCurrentEventSlug] = useState(null)
  const [isToggleTabMenus, setToggleTabMenus] = useState(false)
  const [isToggleDescription, setIsToggleDescription] = useState(true)
  const [onOpenProfile, setIsOpenProfile] = useState({
    isEnable: false,
    isEdit: false
  })
  const [profileModalSelected, setProfileModalSelected] = useState()
  const db = firestore

  const { data: currentEvent } = useQuery({
    queryKey: ['event', currentEventSlug],
    queryFn: async () => {
      const event = await EventService.getEventWithSlug(currentEventSlug)
      let data = {
        title: '',
        posterUrl: null
      }

      if (event) {
        data = {
          id: event.id,
          title: event.title,
          posterUrl: event.posterURL
        }
      }
      return data
    },
    enabled: !!currentEventSlug
  })

  const currentEventType = currentEvent?.type || 'private'

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user !== null) {
        const profileRef = collection(firestore, 'profiles')

        const profileQuery = query(
          profileRef,
          where('userIds', 'array-contains', user.uid),
          limit(1)
        )
        const profileRes = await getDocs(profileQuery)
        const profile = profileRes?.docs[0]?.data()
        if (profile?.eventId && profile?.id) {
          const ticketInfo = await EventService.getTicketTypeByTicketRef(
            profile.eventId,
            profile.id
          )

          setTicket(ticketInfo.ticket)
          setTicketRole(ticketInfo.ticket.ticketRole)
          setTicketType(ticketInfo.ticketType)
          setTimeRemainingSeconds(ticketInfo.ticket.timeRemainingSeconds)
        }

        setUserState({
          isSignedIn: true,
          currentUser: user,
          currentProfile: profile
        })
      } else {
        setUserState({
          isSignedIn: false,
          currentUser: null,
          currentProfile: null
        })
      }
    })

    return () => {
      unsubscribe()
    }
  }, [])

  const actions = {
    signInAnonymously: async () => {
      await setPersistence(firebaseAuth, browserSessionPersistence)

      const result = await signInAnonymously(firebaseAuth)

      return result.user
    },
    signInWithGoogle: async () => {
      const provider = new GoogleAuthProvider()
      // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
      // provider.setCustomParameters({
      //   'login_hint': 'user@example.com'
      // });
      await setPersistence(firebaseAuth, browserLocalPersistence)
      const result = await signInWithRedirect(firebaseAuth, provider)

      return result.user
    },
    signUpWithMail: async (data) => {
      await setPersistence(firebaseAuth, browserSessionPersistence)
      const userCredential = await createUserWithEmailAndPassword(
        firebaseAuth,
        data.email,
        data.password
      )
        .then((response) => {
          if (data.displayName) {
            actions.setProfile(data, 'new')
          }
          return response
        })
        .catch((error) => {
          // Handle Errors here.
          setUserState({
            errors: error
          })
          return error
          // ...
        })

      return userCredential
    },
    signInWithEmail: async (data) => {
      await setPersistence(firebaseAuth, browserSessionPersistence)
      const user = await signInWithEmailAndPassword(
        firebaseAuth,
        data.email,
        data.password
      )
        .then((res) => {
          return res
        })
        .catch((error) => {
          setUserState({
            isSignedIn: false,
            currentUser: null,
            errors: error
          })

          return {
            isError: true,
            errors: error
          }
        })
      return user
    },
    signInWithEventpop: async (code) => {
      const resToken = await authUserCallback(code)
      if (resToken?.data?.token) {
        cookies.set('token', resToken.data.token.access_token, {
          path: '/',
          maxAge: 7200
        })
      }
      return resToken.data.token.access_token
    },
    signOut: async () => {
      await auth.signOut()
    },
    signOutEventpop: async () => {
      setUser(null)
      cookies.remove('token', { path: '/' })
    },
    // Redirect
    setRedirectUrl: (redirectPath) => {
      setRedirectTo(redirectPath)
    },
    setRefCode: (refCode) => {
      setRefCode(refCode)
    },
    setFlags: (flags) => {
      setFlags(flags)
    },
    setTicketRole: (role) => {
      setTicketRole(role)
    },
    setFirstTimeRenderLobby: (action) => {
      setFirstTimeRenderLobby(action === true)
    },
    setCurrentEventSlug: (url) => {
      const urlArr = url.split('/')
      setCurrentEventSlug(urlArr[2])
    },
    navigateToHome: () => {
      navigate(`${PathHelper.app.homePath()}${flags ? `?flags=${flags}` : ''}`)
    },
    navigateRedirectUrl: () => {
      if (redirectTo) {
        navigate(`${redirectTo}${flags ? `?flags=${flags}` : ''}`)
        setRedirectTo(null)
      }
    },

    uploadImageProfile: async (file) => {
      let newPhotoName = new Date().getTime() + '-' + file.name
      const photoRef = ref(storageRef, `/users/images/${newPhotoName}`)
      const uploadResult = await uploadBytes(photoRef, file)
      let getPhotoUrl = await getDownloadURL(uploadResult.ref)
      return getPhotoUrl
    },
    setProfile: async (data, type = 'update') => {
      let postData = {
        displayName: data.displayName,
        bio: data.bio,
        fresh: false,
        country: data.country
      }

      if (type === 'new') {
        postData = data
        postData.fresh = true
      }

      if (data.imageProfile && data.imageProfile.length > 0) {
        let getPhotoUrl = await actions.uploadImageProfile(data.imageProfile[0])
        postData.photoURL = getPhotoUrl
        return actions.updateProfile(postData)
      } else {
        return actions.updateProfile(postData)
      }
    },
    updateProfile: async (data) => {
      const user = auth.currentUser
      const { displayName, country } = data
      let eventId = currentEvent?.id
      if (!eventId) {
        let slug = window.location.pathname.split('/')[2]
        const event = await EventService.getEventWithSlug(
          decodeURIComponent(slug)
        )
        eventId = event.id
      }
      const dataUpdateProfile = { ...data, eventId }
      await updateProfile(user, dataUpdateProfile)

      const memberRef = doc(db, `events/${eventId}/members/${user.uid}`)
      const member = await getDoc(memberRef)
      const memberData = member.data()

      if (!memberData) {
        return null
      }

      const { ticketRef } = memberData

      const profileRef = doc(firestore, 'profiles', ticketRef)
      await setDoc(profileRef, dataUpdateProfile, { merge: true })

      const ticketUpdate = doc(
        collection(db, 'events'),
        eventId,
        'tickets',
        ticketRef
      )

      await setDoc(ticketUpdate, { displayName, country }, { merge: true })

      const profile = await getDoc(profileRef)

      setUserState({
        currentUser: user,
        currentProfile: profile.data()
      })

      return user
    },
    loadCurrentProfile: async (eventId, ticketRef) => {
      const profileRef = doc(collection(firestore, 'profiles'), ticketRef)
      const profile = await getDoc(profileRef)
      const ticketInfo = await EventService.getTicketTypeByTicketRef(
        eventId,
        ticketRef
      )
      setUserState((p) => {
        return { ...p, currentProfile: profile.data() }
      })

      setTicket(ticketInfo.ticket)
      setTicketRole(ticketInfo.ticket.ticketRole)
      setTicketType(ticketInfo.ticketType)
      setTimeRemainingSeconds(ticketInfo.ticket.timeRemainingSeconds)
    },
    getCurrentProfile: async (user) => {
      const result = await actions.getProfileById(user.uid)
      return result
    },
    setDisplayProfile: async (userId) => {
      if (userId) {
        let profile = await actions.getProfileById(userId)
        if (profile) {
          setProfileModalSelected(profile)
          setIsOpenProfile({
            isEnable: true,
            isEdit:
              profile.userIds.indexOf(userState.currentUser.uid) !== -1 &&
              profile.fresh
          })
        }
      } else {
        setProfileModalSelected(null)
        setIsOpenProfile({
          isEnable: false,
          isEdit: false
        })
      }
    },
    getProfileById: async (userId) => {
      const profileRef = collection(firestore, 'profiles')
      const profileQuery = query(
        profileRef,
        where('userIds', 'array-contains', userId),
        limit(1)
      )

      const profileRes = await getDocs(profileQuery)
      const profile = profileRes.docs[0] ? profileRes.docs[0].data() : null

      return profile
    },
    onOpenEditProfile: async () => {
      let action = {
        ...onOpenProfile,
        isEdit: true
      }
      setIsOpenProfile(action)
    },
    setDisplayMenusTab: async (action) => {
      setToggleTabMenus(action)
      setIsToggleDescription(!action)
    },
    setDisplayDescription: (action) => {
      setIsToggleDescription(action)
    }
  }

  return (
    <AppContext.Provider
      value={{
        ...userState,
        redirectTo,
        ticket,
        flags,
        actions,
        currentEvent,
        currentEventSlug,
        currentEventType,
        refCode,
        user,
        setUser,
        ticketRole,
        ticketType,
        firstTimeRenderLobby,
        profileModalSelected,
        onOpenProfile,
        isToggleTabMenus,
        isToggleDescription,
        timeRemainingSeconds,
        setTimeRemainingSeconds
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

export default {
  Context: AppContext,
  Provider: AppContextProvider
}
