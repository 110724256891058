import React, { useEffect, useContext } from 'react'
import { Box } from '@material-ui/core'
import { useLocation, navigate } from '@reach/router'

import { useStyles } from '../../utils/styled'
import { Logos } from '../../utils/images'
import AppContext from '../../Services/AppContext'
import CenterBlockLayout from '../../Components/Pages/CenterBlockLayout'
import { GetUserTokenFromStorage } from '../../utils/helper'
import { getUserInfoByAccessToken } from '../../Services/UsersService'

function AuthCallback() {
  const classes = useStyles()
  const { search, pathname } = useLocation()
  const params = new URLSearchParams(search)
  const appContext = useContext(AppContext.Context)
  const { setUser } = appContext
  const loginUrl = `${process.env.REACT_APP_EP_OA_AUTH_URL}?client_id=${process.env.REACT_APP_EVENTPOP_APP_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code`
  const { token } = GetUserTokenFromStorage()

  useEffect(() => {
    ///Sign out eventpop
    if (pathname === '/user/sign_out') {
      appContext.actions.signOutEventpop()
      navigate(-1)
    } else {
      // Sign in eventpop
      const getCodeFromUrl = params.get('code')
      const fetchUser = async (token) => {
        const { data } = await getUserInfoByAccessToken(token)
        if (data?.user?.success) {
          const sortUser = {
            id: data.user.user.id,
            email: data.user.user.email,
            firstname: data.user.user.firstname,
            lastname: data.user.user.lastname,
            avatar: data.user.user.avatar
          }
          setUser(sortUser)
          ///Redirect when login success
          appContext.actions.navigateRedirectUrl()
        }
      }
      ///Recive code
      if (token) {
        fetchUser(token)
      } else {
        if (getCodeFromUrl) {
          appContext.actions
            .signInWithEventpop(getCodeFromUrl)
            .then((token) => {
              fetchUser(token)
            })
        } else {
          navigate(loginUrl)
        }
      }
    }
  }, [])

  return (
    <CenterBlockLayout>
      <Box textAlign="center">
        <img
          src={Logos.poplive}
          alt="POP LIVE"
          className={`${classes.mb3}
          ${classes.imgResponsive}`}
        />
        <div>
          {pathname === '/user/sign_out' ? 'Sign out ...' : 'Redirecting...'}
        </div>
      </Box>
    </CenterBlockLayout>
  )
}

export default AuthCallback
