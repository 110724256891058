import React, { useEffect, useContext, useState, useMemo } from 'react'
import { navigate, useMatch } from '@reach/router'
import PropTypes from 'prop-types'
import {
  CssBaseline,
  Drawer,
  Hidden,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
  Box,
  Divider
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQuery } from '@tanstack/react-query'

import AppContext from '../Services/AppContext'
import EventContext from '../Services/EventContext'
import ThemeContext from '../Theme/ThemeContext'

import NavSidebar from './EventContainerLayout/NavSidebar'
import PathHelper from '../utils/PathHelper'
import { Logos } from '../utils/images'
import ProfileModal from './Users/ProfileModal'
import Avatar from './Shares/Avatar'
import { useStyles } from '../utils/styled'

const drawerWidth = 160
const customStyles = makeStyles((theme) => ({
  rootWrapper: {
    position: 'relative',
    minHeight: '100vh'
  },
  root: {
    display: 'flex'
  },
  containerWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100vw',
    zIndex: '-99',
    backgroundImage: ({ backgroundLiveStreamImage }) => {
      return backgroundLiveStreamImage
        ? `url("${backgroundLiveStreamImage}")`
        : ''
    },
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    [theme.breakpoints.down('md')]: {
      backgroundPosition: 'center center',
      backgroundSize: 'cover'
    }
  },

  drawer: {
    background: 'transparent',
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    background: 'transparent',
    zIndex: 1,
    border: 'none',
    width: drawerWidth
  },
  content: {
    flexGrow: 1
  },

  rootToolbar: {
    justifyContent: 'space-between',
    minHeight: 56
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  logo: {
    width: '120px',
    height: '36px'
  },
  titleWrapper: {
    width: '70vw'
  }
}))
function ResponsiveDrawer(props) {
  const { window, children } = props
  const themeContext = useContext(ThemeContext.Context)
  const classes = useStyles()
  const appContext = useContext(AppContext.Context)
  const eventContext = useContext(EventContext.Context)
  const { event } = eventContext
  const [isUserNotUpdated, setIsUserNotUpdated] = useState(false)
  const { backgroundLiveStreamImage, eventTheme } = themeContext
  const customClasses = customStyles({ backgroundLiveStreamImage })

  const user = useMemo(
    () => appContext.currentProfile,
    [appContext.currentProfile]
  )

  const match = useMatch('/e/:eventSlug/s/livestream/:sessionId')

  const { data: session } = useQuery({
    queryKey: ['session', match?.sessionId],
    queryFn: () => {
      return eventContext.actions.loadSession(match.sessionId)
    },
    enabled: !!match?.sessionId
  })

  const handleDrawerToggle = () => {
    navigate(PathHelper.event.lobbyPath(event.slug))
  }

  const container =
    window !== undefined ? () => window().document.body : undefined

  useEffect(() => {
    const getCurrentProfile = () => {
      appContext.actions
        .getCurrentProfile(appContext.currentUser)
        .then((res) => {
          setIsUserNotUpdated(res.fresh)
          if (res.fresh) {
            appContext.actions.setDisplayProfile(appContext.currentUser.uid)
          }
        })
    }

    getCurrentProfile()
  }, [isUserNotUpdated])

  const getLogoByTheme = () => {
    if (eventTheme?.layoutTheme === 'dark') return Logos.popliveHorizontalWhite
    return Logos.popliveHorizontal
  }

  return (
    <div className={customClasses.rootWrapper}>
      {backgroundLiveStreamImage && (
        <div className={customClasses.containerWrapper} />
      )}
      <Hidden mdUp>
        <Toolbar className={customClasses.rootToolbar}>
          <Box display="flex" gridGap="12px" alignItems="center">
            {match && session ? (
              <>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  size="small"
                >
                  <FontAwesomeIcon icon={['far', 'chevron-left']} />
                </IconButton>
                <Box className={customClasses.titleWrapper}>
                  <Typography className={classes.textEllipsis}>
                    {session.title}
                  </Typography>
                </Box>
              </>
            ) : (
              <img
                src={getLogoByTheme()}
                className={customClasses.logo}
                alt="Eventpop Logo"
              />
            )}
          </Box>
          <Avatar user={user} />
        </Toolbar>
        <Divider className={classes.divider} />
      </Hidden>

      <div className={customClasses.root}>
        <CssBaseline />
        <nav className={customClasses.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

          {/* <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: customClasses.drawerPaper
              }}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              <NavSidebar />
            </Drawer>
          </Hidden> */}
          <Hidden smDown implementation="css">
            <Drawer
              classes={{
                paper: customClasses.drawerPaper
              }}
              variant="permanent"
              open
            >
              <NavSidebar />
            </Drawer>
          </Hidden>
        </nav>
        <main className={customClasses.content}>
          {children}
          {appContext.onOpenProfile.isEnable && <ProfileModal />}
        </main>
      </div>
    </div>
  )
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
}

export default ResponsiveDrawer
