import React, { useContext, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  makeStyles,
  CircularProgress,
  Typography,
  TextField,
  Button,
  Box
} from '@material-ui/core'
import { navigate, useLocation } from '@reach/router'
import { useForm } from 'react-hook-form'
import moment from 'moment'

import AppContext from '../Services/AppContext'
import EventService from '../Services/EventService'
import { getTicketsByUserId } from '../Services/UsersService'
import CheckAuthenticated from './CheckAuthenticated'
import CenterBlockLayout from '../Components/Pages/CenterBlockLayout'
import WhereIsMyRefCodeModal from '../Components/Modal/WhereIsMyRefCodeModal'
import MyTicketEventModal from '../Components/Modal/MyTicketEvent'
import PathHelper from '../utils/PathHelper'
import PopliveLayout from '../Components/Pages/PopliveLayout'
import {
  variables,
  useStyles as customStyle,
  LightTooltip
} from '../utils/styled'
import {
  ConvertTimeStamp,
  TimestampToFormatShotTime,
  GetUserTokenFromStorage
} from '../utils/helper'

const useStyles = makeStyles((theme) => ({
  root: theme.redeemTicket.root,
  container: {
    ...theme.redeemTicket.container,
    textAlign: 'center'
  },
  eventRedeemTicketWrapper: {
    ...theme.redeemTicket.container,
    minHeight: '100vh',
    display: 'flex',
    gap: '50px',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      flexDirection: 'column',
      gap: '32px',
      ...theme.redeemTicket.containerSm
    }
  },
  eventRedeemTicket: {
    ...theme.redeemTicket.redeemTicketEventTitle,
    borderRadius: theme.spacing(1.5),
    padding: '50px',
    width: '474px',
    maxWidth: '474px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '32px',
      maxWidth: '100%',
      width: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '24px'
    }
  },
  eventPosterWrapper: {
    position: 'relative',
    width: '470px',
    height: '668px',
    overflow: 'hidden',
    zIndex: 101,
    borderRadius: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxHeight: '600px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '360px',
      maxHeight: '360px'
    }
  },
  overlay: {
    ...theme.redeemTicket.overlay
  },
  eventPosterOverlay: {
    width: '100%',
    height: '100%',
    zIndex: 0,
    position: 'absolute',
    left: 0,
    top: 0,
    backgroundImage: (props) => {
      if (props.event) {
        return `url(${props.event.posterURL})`
      }
    },
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    filter: 'blur(4px)',
    backgroundPosition: 'center center',
    boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.75)'
  },
  eventPoster: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: '50%',
    top: '50%',
    zIndex: 102,
    transform: 'translate(-50%, -50%)',
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: {
      padding: '24px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
      minHeight: '350px',
      maxHeight: '350px',
      borderRadius: '50px'
    }
  },
  eventDescriptionWrapper: {
    zIndex: 99,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  eventDescription: {
    [theme.breakpoints.down('sm')]: {
      width: '60%',
      margin: '0 auto'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0 '
    }
  },
  eventName: {
    ...theme.redeemTicket.eventName,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2)
  },
  inputLabel: {
    ...theme.redeemTicket.inputLabel
  },
  textError: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    textAlign: 'left',
    color: '#EE3124',
    fontSize: '12px',
    marginBottom: theme.spacing(2)
  },
  textFieldInputRoot: {
    marginTop: '6px',
    borderRadius: '4px',
    background: 'white'
  },
  textFieldInput: {
    color: variables.grayBase,
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  buttonRedeemTicket: {
    ...theme.redeemTicket.buttonRedeemTicket
  },
  timerWrapper: {
    textAlign: 'center'
  },
  whereIsMyRefCodeWrapper: {
    ...theme.redeemTicket.textByTheme,
    cursor: 'pointer'
  },
  quickCheckWrapper: {
    display: 'flex',
    maxWidth: '470px',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    gap: '18px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    }
  },
  quickCheckTopic: {
    width: '36px',
    height: '36px',
    fontSize: '18px',
    borderRadius: '50%',
    position: 'relative',
    '& svg': {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)'
    },
    ...theme.redeemTicket.iconTooltipWrapper
  },
  info: {
    width: '15px',
    height: '15px',
    fontSize: '15px',
    borderRadius: '50%',
    position: 'absolute',
    right: '-5px',
    bottom: '0px',
    ...theme.redeemTicket.textByTheme
  },
  titleTooltip: {
    ...theme.redeemTicket.tooltipTitle
  },
  timerIcon: {
    ...theme.redeemTicket.timeWrapperIcon
  },
  goToEventpop: {
    padding: '16px',
    ...theme.redeemTicket.goToEventpopWrapper
  }
}))

const RedeemTicketPage = ({ slug }) => {
  const location = useLocation()
  const [event, setEvent] = useState()
  const classes = useStyles({ event: event })
  const customClasses = customStyle()
  const context = useContext(AppContext.Context)
  const { user, flags } = context
  const [loading, setLoading] = useState(true)
  const [isOpenDialog, setIsOpenDialog] = useState(false)
  const [isOpenMyTicketModal, setIsOpenMyTicketModal] = useState(false)
  const { token } = GetUserTokenFromStorage()
  const [userTickets, setUserTickets] = useState([])
  const [loadingTicket, setloadingTicket] = useState(false)

  const loadInitialData = async () => {
    const eventData = await EventService.getEventWithSlug(slug)
    setEvent(eventData)

    context.actions.setFirstTimeRenderLobby(true)
    const membership = await EventService.getEventMembership(
      eventData.id,
      context.currentUser.uid
    )
    if (membership !== undefined) {
      // The user is already a member of this event, send them to the event page.
      navigate(PathHelper.event.lobbyPath(slug))
    }
  }

  const handleOpenMyTicketModal = () => {
    setIsOpenMyTicketModal(true)
  }

  const handdleCloseMyTicketModal = () => {
    setIsOpenMyTicketModal(false)
  }

  const scrollToEventOnline = () => {
    navigate(`${PathHelper.app.homePath()}?prevPath=${location.pathname}`)
  }

  const handlePublicEvent = async (eventId) => {
    if (context.refCode) {
      let data = { ticketRef: context.refCode }
      onSubmit(data)
    }

    const ticketTypeId = `${Math.floor(Math.random() * 1000)}-${Date.now()}`
    const ticket = await EventService.generatePublicTicket(
      eventId,
      ticketTypeId
    )

    if (ticket.success) {
      const result = await EventService.redeemTicket(event.id, ticket.refCode)
      if (result.data.success) {
        await context.actions.loadCurrentProfile(event.id, ticket.refCode)
        navigate(PathHelper.event.lobbyPath(slug))
        context.actions.setRefCode(null)
      }
    }
  }

  useEffect(() => {
    if (context.currentUser) {
      loadInitialData()
    }
  }, [])

  useEffect(() => {
    if (event && context.refCode) {
      setLoading(true)
      const data = { ticketRef: context.refCode }
      onSubmit(data)
    } else if (event && event.type === 'free') {
      handlePublicEvent(event.id)
    } else if (event) {
      const getTickets = async () => {
        setloadingTicket(true)
        const getTickets = await getTicketsByUserId(
          token,
          user.id,
          event.slug,
          event?.eventpopId
        )
        if (getTickets.data.success) {
          setUserTickets(getTickets.data.tickets)
        }
        setloadingTicket(false)
      }
      if (token && user) {
        getTickets()
      }
      setLoading(false)
    }
  }, [event, token, user])

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm()

  const handdleCloseDialogWhereIsMyRefCode = () => {
    setIsOpenDialog(false)
  }

  const onSubmit = async (data) => {
    if (typeof data.ticketRef === 'string') {
      const ticketRef = data.ticketRef.toUpperCase().trim()
      if (ticketRef !== '') {
        setLoading(true)
        try {
          const result = await EventService.redeemTicket(event.id, ticketRef)
          if (result.data.success === true) {
            await context.actions.loadCurrentProfile(event.id, ticketRef)
            navigate(
              `${PathHelper.event.lobbyPath(slug)}${
                flags ? `?flags=${flags}` : ''
              }`
            )
            context.actions.setRefCode(null)
          }
        } catch (e) {
          context.actions.setRefCode(null)
          switch (e.code) {
            case 'permission-denied':
              setError('ticketRef', {
                type: 'manual',
                message:
                  'This ticket reference code is not valid. Please check and try again.'
              })
              break

            case 'already-exists':
              alert(
                "You've already redeemed a ticket. Please contact customer support if you'd like to redeem another ticket instead."
              )
              navigate(PathHelper.event.lobbyPath(slug))
              break

            default:
              alert('There was an error redeeming your ticket.')
              break
          }
        } finally {
          setLoading(false)
        }
      }
    } else setLoading(false)
  }
  const tooltipBrowser = () => {
    return (
      <>
        <Typography variant="h6" className={classes.titleTooltip}>
          Recommended Browsers
        </Typography>
        <Typography variant="h6">
          Chrome
          <br />
          Firefox
          <br />
          Safari (Mac Only)
          <br />
          Edge
          <br />
        </Typography>
      </>
    )
  }

  const tooltipInternet = () => {
    return (
      <>
        <Typography variant="h6" className={classes.titleTooltip}>
          Recomanded Internet Connection
        </Typography>
        <Typography variant="h6">
          PopLive is best experienced with a 3-5 Mbps connection. We recommend a
          minimum upload /download bandwidth of 1 Mbps.
        </Typography>
      </>
    )
  }

  const Timer = ({ startAt, endAt }) => {
    const classes = useStyles()
    const startDate = moment(ConvertTimeStamp(startAt).seconds * 1000)
    const endDate = moment(ConvertTimeStamp(endAt).seconds * 1000)
    const checkSameDate = startDate.isSame(endDate, 'date')
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <FontAwesomeIcon
          icon={['far', 'calendar-alt']}
          size="lg"
          className={`${classes.timerIcon} ${customClasses.mr1}`}
        />
        <Typography variant="h6">
          {TimestampToFormatShotTime(startAt)}
        </Typography>
        <FontAwesomeIcon
          icon={['far', 'arrows-h']}
          className={`${classes.timerIcon} ${customClasses.mx1}`}
        />
        <Typography variant="h6">
          {checkSameDate
            ? moment(TimestampToFormatShotTime(endAt)).format('HH:mm')
            : TimestampToFormatShotTime(endAt)}
        </Typography>
      </Box>
    )
  }

  function renderRedeemTicket() {
    return (
      <React.Fragment>
        {loading ? (
          <CenterBlockLayout
            className={classes.root}
            containerClassName={classes.container}
          >
            <CircularProgress />
            <Typography> Please wait...</Typography>
          </CenterBlockLayout>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box className={classes.overlay} />
            <Box className={classes.eventRedeemTicketWrapper}>
              <Box className={classes.eventPosterWrapper}>
                <img
                  className={classes.eventPoster}
                  src={event.posterURL}
                  alt={event.id}
                />
                <Box className={classes.eventPosterOverlay} />
              </Box>
              <div className={classes.eventDescriptionWrapper}>
                <Box className={classes.eventRedeemTicket}>
                  <Typography variant="h2" className={classes.eventName}>
                    {event.title}
                  </Typography>
                  <Box className={classes.eventDescription}>
                    <Box className={classes.timerWrapper}>
                      <Timer startAt={event.startAt} endAt={event.endAt} />
                    </Box>
                    {event.source !== 'eventpop' && (
                      <>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          mt="24px"
                        >
                          <Typography
                            variant="h6"
                            className={classes.inputLabel}
                          >
                            Tickets Reference Code
                          </Typography>
                          {event.source !== 'poplive' && (
                            <Box
                              display="flex"
                              alignItems="center"
                              gridGap="8px"
                              onClick={() => handleOpenMyTicketModal()}
                              className={classes.whereIsMyRefCodeWrapper}
                            >
                              <Typography variant="h6">My Tickets</Typography>
                              <FontAwesomeIcon
                                icon={['far', 'chevron-right']}
                              />
                            </Box>
                          )}
                        </Box>
                        <TextField
                          size="small"
                          name="ticketRef"
                          className={classes.textFieldInputRoot}
                          inputProps={{ className: classes.textFieldInput }}
                          inputRef={register}
                          variant="outlined"
                          autoComplete="off"
                          fullWidth
                          autoFocus
                          error={errors.ticketRef ? true : false}
                          placeholder="กรุณากรอกรหัสเข้าใช้งาน"
                        />
                        {errors.ticketRef && (
                          <Box className={classes.textError}>
                            <FontAwesomeIcon
                              icon={['far', 'exclamation-circle']}
                              size="lg"
                            />
                            <Typography variant="h6">
                              {errors.ticketRef.message}
                            </Typography>
                          </Box>
                        )}
                        <Button
                          size="medium"
                          type="submit"
                          className={`${classes.buttonRedeemTicket} ${customClasses.my2}`}
                          color="primary"
                          variant="contained"
                          fullWidth
                        >
                          Join Event
                        </Button>
                        <Typography
                          className={classes.whereIsMyRefCodeWrapper}
                          onClick={() => setIsOpenDialog(true)}
                        >
                          Where’s my reference code?
                        </Typography>
                      </>
                    )}
                    {/* External link to event pop */}
                    {event.source !== 'poplive' && (
                      <>
                        {event.source === 'eventpop' && (
                          <Button
                            size="medium"
                            className={`${classes.buttonRedeemTicket} ${customClasses.mt2}`}
                            color="primary"
                            variant="contained"
                            fullWidth
                            onClick={handleOpenMyTicketModal}
                          >
                            Join Event
                          </Button>
                        )}
                        <a
                          href={PathHelper.event.eventPopEventDetail(
                            event.eventpopId
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: 'none' }}
                        >
                          <Box className={classes.goToEventpop}>
                            <Typography variant="h6">
                              View Event Details
                              <FontAwesomeIcon
                                className={customClasses.mx1}
                                icon="fas fa-external-link"
                              />
                            </Typography>
                            <Typography variant="h6" color="textSecondary">
                              This will take you to www.eventpop.me
                            </Typography>
                          </Box>
                        </a>
                      </>
                    )}
                  </Box>
                </Box>
                <Box className={classes.quickCheckWrapper}>
                  <Typography variant="h6">
                    A quick check to get the best experience before joining
                    events!
                  </Typography>
                  <Box display="flex" gridGap="18px">
                    <LightTooltip title={tooltipBrowser()}>
                      <Box className={classes.quickCheckTopic}>
                        <FontAwesomeIcon icon={['far', 'globe']} />
                        <Box className={classes.info}>
                          <FontAwesomeIcon icon={['fas', 'info-circle']} />
                        </Box>
                      </Box>
                    </LightTooltip>
                    <LightTooltip title={tooltipInternet()}>
                      <Box className={classes.quickCheckTopic}>
                        <FontAwesomeIcon icon={['fas', 'wifi']} />
                        <Box className={classes.info}>
                          <FontAwesomeIcon icon={['fas', 'info-circle']} />
                        </Box>
                      </Box>
                    </LightTooltip>
                  </Box>
                </Box>
              </div>
            </Box>
          </form>
        )}
        <WhereIsMyRefCodeModal
          isOpenDialog={isOpenDialog}
          handdleCloseDialog={handdleCloseDialogWhereIsMyRefCode}
        />
        <MyTicketEventModal
          isOpenDialog={isOpenMyTicketModal}
          handdleCloseDialog={handdleCloseMyTicketModal}
          tickets={userTickets}
          loadingTicket={loadingTicket}
          eventpopId={event?.eventpopId}
        />
      </React.Fragment>
    )
  }

  return (
    <CheckAuthenticated>
      <PopliveLayout scrollToEventOnline={scrollToEventOnline}>
        {renderRedeemTicket()}
      </PopliveLayout>
    </CheckAuthenticated>
  )
}

export default RedeemTicketPage
