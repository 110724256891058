import React, { useContext } from 'react'
import { Redirect, useLocation } from '@reach/router'

import AppContext from '../Services/AppContext'
import PathHelper from '../utils/PathHelper'

const CheckAuthenticated = ({ children }) => {
  const context = useContext(AppContext.Context)
  const location = useLocation()

  return (
    <>
      {context.currentUser ? (
        <>{children}</>
      ) : (
        <Redirect to={PathHelper.auth.authPath(location.pathname)} noThrow />
      )}
    </>
  )
}

export default CheckAuthenticated
