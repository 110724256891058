import React from 'react'
import {
  Box,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles
} from '@material-ui/core'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useStyles } from '../utils/styled'
import { TimestampToFormatShotTime, ConvertTimeStamp } from '../utils/helper'

const customeStyles = makeStyles((theme) => ({
  list: {
    alignItems: 'center',
    padding: 0,
    gap: '6px',
    '& .MuiListItemText-root': {
      margin: 0
    }
  },
  itemIcon: {
    ...theme.lobby.itemIcon,
    minWidth: 21,
    display: 'flex',
    justifyContent: 'center'
  }
}))

export default function Timer({ startAt, endAt }) {
  const startDate = moment(ConvertTimeStamp(startAt).seconds * 1000)
  const endDate = moment(ConvertTimeStamp(endAt).seconds * 1000)
  const checkSameDate = startDate.isSame(endDate, 'date')
  const classes = useStyles()
  const customClasses = customeStyles()

  const List = ({ icon, primary }) => {
    return (
      <ListItem component="div" className={customClasses.list}>
        <ListItemIcon className={customClasses.itemIcon}>
          <FontAwesomeIcon icon={['far', icon]} size={'lg'} />
        </ListItemIcon>
        <ListItemText primary={primary} />
      </ListItem>
    )
  }

  return (
    <List
      icon="calendar-alt"
      primary={
        <React.Fragment>
          <Box display="flex">
            <Box display="flex" alignItems="center">
              <Typography>{TimestampToFormatShotTime(startAt)}</Typography>
              <FontAwesomeIcon
                icon={['far', 'arrows-h']}
                className={`${customClasses.itemIcon} ${classes.mx1}`}
              />
            </Box>
            <Typography className={classes.mr1}>
              {checkSameDate
                ? moment(TimestampToFormatShotTime(endAt)).format('HH:mm')
                : TimestampToFormatShotTime(endAt)}
            </Typography>
          </Box>
        </React.Fragment>
      }
    />
  )
}
