import { useState, useContext } from 'react'
import moment from 'moment'
import {
  Box,
  makeStyles,
  createStyles,
  Typography,
  Collapse,
  Chip,
  Menu,
  MenuItem,
  Grid,
  Tooltip
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import RelativeTimer from './RelativeTimer'
import AppContext from '../../Services/AppContext'
import EventContext from '../../Services/EventContext'
import Avatar from '../Shares/Avatar'
import { useStyles } from '../../utils/styled'
import { parseEmoji } from '../../utils/helper'

const customStyle = makeStyles((theme) =>
  createStyles({
    root: {
      ...theme.pinMessage.root,
      borderRadius: '8px',
      padding: theme.spacing(1),
      borderColor: 'transparent'
    },
    menuPaper: {
      ...theme.pinMessage.menuPaper
    },
    listItem: {
      padding: 0
    },
    chip: {
      ...theme.chip.pinMessage,
      fontWeight: 'bold',
      fontSize: '0.8em',
      height: '1.8em'
    },
    btnAction: {
      ...theme.pinMessage.menu,
      cursor: 'pointer'
    },
    timestamp: {
      ...theme.pinMessage.timestamp,
      opacity: 0.64
    },
    displayName: {
      ...theme.pinMessage.displayName,
      fontWeight: 'bold',
      fontSize: '1em'
    },
    message: {
      fontSize: '14px',
      wordBreak: 'break-all'
    }
  })
)

function PinMessage({ pinMessage, handleUnPinMessage }) {
  const appContext = useContext(AppContext.Context)
  const eventContext = useContext(EventContext.Context)
  const { emojis } = eventContext
  const customClasses = customStyle()
  const style = useStyles()
  const [isOpenPinMessage, setIsOpenPinMessage] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const openMenu = Boolean(anchorEl)
  const ticketRole = appContext.ticketRole

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const displayIcon = () => {
    return isOpenPinMessage ? ['far', 'chevron-down'] : ['far', 'chevron-up']
  }

  const convertDate = (timestamp) => {
    return moment(timestamp).format('DD MMM YYYY, HH:mm')
  }

  return (
    <Box className={`${customClasses.root}`}>
      {isOpenPinMessage && (
        <Grid container alignItems="center" justifycontent="space-between">
          <Grid item xs={6}>
            <Box alignItems="center" display="flex" gridColumnGap={6}>
              <FontAwesomeIcon icon={['fas', 'thumbtack']} />
              <Typography variant="caption">Pinned</Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              gridColumnGap={6}
            >
              {(ticketRole === 'host' || ticketRole === 'invisibleHost') && (
                <Typography
                  className={customClasses.btnAction}
                  onClick={handleOpenMenu}
                >
                  <FontAwesomeIcon icon={['fal', 'ellipsis-h']} size="lg" />
                </Typography>
              )}

              <Typography
                className={customClasses.btnAction}
                onClick={() => setIsOpenPinMessage(!isOpenPinMessage)}
              >
                <FontAwesomeIcon icon={displayIcon()} size="xs" />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
      <Box display="flex" gridGap="16px" width="100%">
        <Avatar type="pinMessage" user={pinMessage} />
        <Box display="flex" width="100%" justifyContent="space-between">
          <Box display="flex" alignItems="center" gridGap="8px">
            <Typography className={customClasses.displayName}>
              {pinMessage.displayName}
            </Typography>
            {pinMessage.ticketRole === 'host' && (
              <Chip className={customClasses.chip} label="Host" size="small" />
            )}
            <Tooltip
              title={convertDate(pinMessage.timestamp)}
              placement="top"
              className={customClasses.timestamp}
            >
              <Typography variant="h6">
                <RelativeTimer timestamp={pinMessage.timestamp} />
              </Typography>
            </Tooltip>
          </Box>

          {!isOpenPinMessage && (
            <Box display="flex" alignItems="center" gridColumnGap={6}>
              {(ticketRole === 'host' || ticketRole === 'invisibleHost') && (
                <Typography
                  className={customClasses.btnAction}
                  onClick={handleOpenMenu}
                >
                  <FontAwesomeIcon icon={['fal', 'ellipsis-h']} size="lg" />
                </Typography>
              )}

              <Typography
                className={customClasses.btnAction}
                onClick={() => setIsOpenPinMessage(!isOpenPinMessage)}
              >
                <FontAwesomeIcon icon={displayIcon()} size="xs" />
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      {!isOpenPinMessage && (
        <Typography
          className={`${style.textEllipsis} ${customClasses.message}`}
          dangerouslySetInnerHTML={{
            __html: parseEmoji(pinMessage.message, emojis)
          }}
        />
      )}
      <Collapse in={isOpenPinMessage}>
        <Typography
          className={customClasses.message}
          dangerouslySetInnerHTML={{
            __html: parseEmoji(pinMessage.message, emojis)
          }}
        />
      </Collapse>

      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        classes={{
          paper: customClasses.menuPaper,
          list: customClasses.listItem
        }}
      >
        <MenuItem onClick={handleCloseMenu}>
          <Box
            display="flex"
            alignItems="center"
            gridColumnGap={6}
            onClick={() => handleUnPinMessage()}
          >
            <FontAwesomeIcon
              icon={['fal', 'thumbtack']}
              style={{ transform: 'rotate(45deg)' }}
            />
            <Typography>Un-pin comment</Typography>
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default PinMessage
