import { useContext, useState, useRef } from 'react'
import {
  Box,
  makeStyles,
  createStyles,
  IconButton,
  Button,
  Popper,
  ClickAwayListener
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import grey from '@material-ui/core/colors/grey'

import EventContext from '../../Services/EventContext'
import AppContext from '../../Services/AppContext'
import Avatar from '../Shares/Avatar'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      ...theme.chatBar.root,
      display: 'flex',
      position: 'fixed',
      bottom: 0,
      width: 360,
      maxWidth: 360,
      padding: '6px 14px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: '100%',
        padding: '4px 8px'
      }
    },
    formWrapper: {
      ...theme.chatBar.form,
      borderRadius: theme.spacing(0.5),
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      minHeight: '40px',
      width: '100%',
      position: 'relative',
      overflowY: 'auto'
    },
    formInput: {
      ...theme.chatBar.input,
      cursor: 'text',
      fontSize: '0.9em',
      padding: theme.spacing(1),
      borderRadius: theme.spacing(0.5)
    },
    iconButton: {
      ...theme.chatBar.emojiButton,
      padding: 0,
      marginTop: '10px',
      // color: grey[400],
      '&:hover': {
        // color: 'black',
      }
    },
    inputLimit: {
      flexBasis: '100%',
      fontSize: '0.5em',
      color: grey[500]
    },
    buttonSend: {
      marginLeft: theme.spacing(1),
      borderRadius: 4,
      padding: theme.spacing(1),
      cursor: 'pointer',
      ...theme.chatBar.buttonSend
    },
    avartarWrapper: {
      marginTop: '3px'
    },
    popper: {
      zIndex: 1000
    },
    popperPaper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, minmax(0, 1fr))',
      gridTemplateRows: 'repeat(2, minmax(0, 1fr))',
      border: '1px solid',
      padding: theme.spacing(1),
      ...theme.chatBar.emojiPaper,
      borderRadius: theme.spacing(0.5),
      zIndex: 1,
      gap: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(4, minmax(0, 1fr))'
      }
    },
    emoji: {
      height: 34,
      width: 34,
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        height: 36,
        width: 36
      }
    }
  })
)

const ChatBar = ({ onSend, disabled }) => {
  const classes = useStyles()
  const inputRef = useRef()
  const { ticketRole, currentProfile } = useContext(AppContext.Context)
  const { emojis } = useContext(EventContext.Context)
  const [inputLength, setInputLength] = useState(0)
  const inputLimit = ticketRole === 'viewer' ? 200 : 400
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClickEmojis = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleCloseEmojis = () => {
    setAnchorEl(null)
  }
  const openEmojisMenu = Boolean(anchorEl)
  const id = openEmojisMenu ? 'emojis-popper' : undefined

  const EmojiButton = () => (
    <IconButton
      aria-describedby={id}
      onClick={handleClickEmojis}
      size="medium"
      className={classes.iconButton}
    >
      <FontAwesomeIcon icon={['fal', 'smile-wink']} size="sm" />
    </IconButton>
  )
  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessages()
    } else if (
      inputLength >= inputLimit &&
      !['Backspace', 'Delete'].includes(e.key)
    ) {
      e.preventDefault()
    }
  }

  const handleInputChange = (e) => {
    const content = inputRef.current.innerHTML.trim()
    const string = convertStringWithImageTags(content)
    const count = customStringLength(string)
    setInputLength(count)
  }

  const handleAddEmoji = (emoji) => {
    const div = inputRef.current
    const img = document.createElement('img')
    img.style.width = '24px' // Set the width of the image to 24 pixels
    img.style.height = '24px' // Set the height of the image to 24 pixels
    img.style.margin = '2px 2px'
    img.style.verticalAlign = 'middle'
    img.src = emoji.imageURL
    img.alt = emoji.name

    if (inputLength >= inputLimit) {
      return
    } else {
      setInputLength((stInputLength) => (stInputLength += 10))
      div.appendChild(img)
      div.focus()
    }
  }
  function convertStringWithImageTags(inputString) {
    // Regular expression to match <img> tags and extract alt attributes
    const imgRegex = /<img[^>]*alt="([^"]*)"[^>]*>/g
    const outputString = inputString.replace(
      imgRegex,
      (match, alt) => ` ${alt} `
    )

    const encode = outputString
      .replace(/&nbsp;/g, ' ') // Replace &nbsp; with a regular space
      .replace(/&lt;/g, '<') // Replace &lt; with <
      .replace(/&gt;/g, '>') // Replace &gt; with >
      .replace(/&amp;/g, '&') // Replace &amp; with &
      .replace(/&quot;/g, '"') // Replace &quot; with "
      .replace(/&#039;/g, "'") // Replace &#039; with ' // Replace ' with &#039;

    const finalString = encode.trim().replace(/\s+/g, ' ')

    return finalString
  }

  function customStringLength(inputString) {
    // Regular expression to match :text: patterns
    const patternRegex = /:[^:]+:/g
    let totalLength = 0 // Start with a total length of 0

    // Find all matches for the pattern
    let matches = inputString.match(patternRegex)

    if (matches) {
      // Add 10 for each match
      totalLength += matches.length * 10
      inputString = inputString.replace(patternRegex, '')
    }
    totalLength += inputString.length
    return totalLength
  }

  const sendMessages = () => {
    const content = inputRef.current.innerHTML.trim()
    const string = convertStringWithImageTags(content)
    if (string.length > 0) {
      onSend(string)
      setInputLength(0)
      inputRef.current.innerHTML = ''
    }
  }

  return (
    <Box className={classes.root}>
      <Popper
        id={id}
        open={openEmojisMenu}
        anchorEl={anchorEl}
        placement="top"
        className={classes.popper}
      >
        <ClickAwayListener onClickAway={handleCloseEmojis}>
          <div className={classes.popperPaper}>
            {emojis.map((emoji) => {
              return (
                <img
                  onClick={() => handleAddEmoji(emoji)}
                  src={emoji.imageURL}
                  alt={emoji.name}
                  key={emoji.name}
                  className={classes.emoji}
                />
              )
            })}
          </div>
        </ClickAwayListener>
      </Popper>

      <div className={classes.avartarWrapper}>
        <Avatar user={currentProfile} />
      </div>
      <div className={classes.formWrapper}>
        <div
          ref={inputRef}
          contentEditable={!disabled ? 'plaintext-only' : false}
          className={classes.formInput}
          placeholder="Say something..."
          spellCheck={false}
          onInput={handleInputChange}
          onKeyDown={handleKeyDown}
        />

        {inputLength > inputLimit * 0.8 && (
          <div className={classes.inputLimit}>
            {inputLength <= inputLimit ? inputLength : inputLimit} /{' '}
            {inputLimit}
          </div>
        )}
      </div>
      {emojis.length > 0 && !disabled && <div>{EmojiButton()}</div>}

      <div>
        <Button
          type="button"
          onClick={() => sendMessages()}
          className={classes.buttonSend}
          disabled={!inputLength || disabled}
        >
          Send
        </Button>
      </div>
    </Box>
  )
}

export default ChatBar
