import React, { useState, useEffect, useContext } from 'react'
import { onSnapshot } from 'firebase/firestore'

import { generateRandomNumber } from '../utils/helper'
import AppContext from './AppContext'
import EventService from './EventService'

const SessionContext = React.createContext()

// HOC
const SessionContextProvider = ({ event, children }) => {
  const appContext = useContext(AppContext.Context)
  const { flags } = appContext
  const [eventViewers, setEventViewers] = useState([])

  useEffect(() => {
    let interval
    if (flags && flags.includes('test-viewers') && eventViewers.length > 0) {
      interval = setInterval(async () => {
        const eventViewerLoadedTest = eventViewers.map((viewer) => {
          const randomNumber = generateRandomNumber(-10, 20)
          const randomViewers =
            viewer.viewers + randomNumber <= 0 ? 1 : randomNumber

          return {
            ...viewer,
            viewers: (viewer.viewers += randomViewers)
          }
        })

        setEventViewers(eventViewerLoadedTest)
      }, 1000)
    }

    return () => clearInterval(interval)
  }, [flags, eventViewers])

  useEffect(() => {
    let unsubscribe = () => {}
    if (event?.id) {
      unsubscribe = onSnapshot(
        EventService.getSessionsViewersDoc(event.id),
        async (snapshot) => {
          if (snapshot.empty) {
          } else {
            const eventViewersDoc = snapshot.docs.map((doc) => {
              return { sessionId: doc.id, ...doc.data() }
            })
            setEventViewers(eventViewersDoc)
          }
        }
      )
    }
    return () => {
      unsubscribe()
    }
  }, [event?.id])

  return (
    <SessionContext.Provider
      value={{
        event,
        eventViewers
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}

export default {
  Context: SessionContext,
  Provider: SessionContextProvider
}
