import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PollDetails from '../PollDetails'
import { CustomButton, variables } from '../../../utils/styled'
import PollOption from './PollOption'

const customStyles = makeStyles((theme) => ({
  mySelected: {
    ...theme.poll.progressbar.mySelected,
    borderRadius: 8,
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2)
  },
  common: {
    ...theme.poll.progressbar.common,
    borderRadius: 8,
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2)
  },
  textBox: {
    alignItems: 'center',
    borderRadius: '8px',
    color: variables.primaryOrange
  }
}))

const Placeholder = ({ mySelected, poll, handleEditChoice, sessionId }) => {
  const customClasses = customStyles()

  const TextBox = ({ type }) => {
    if (type === 'public') return null

    let title = 'Poll result will be shown once it has been closed.'

    if (type === 'neverDisplay') {
      title =
        'The poll result will not be shown here. Organizer may release the poll results later.'
    }

    return (
      <Box mt="24px" mb="12px">
        <Alert
          classes={{
            root: customClasses.textBox
          }}
          severity="warning"
          iconMapping={{
            warning: <FontAwesomeIcon icon={['fal', 'info-circle']} size="xs" />
          }}
        >
          {title}
        </Alert>
      </Box>
    )
  }

  return (
    <React.Fragment>
      {mySelected.length > 0 && (
        <Box>
          {poll.type === 'public' ? (
            <PollDetails sessionId={sessionId} pollId={poll.id} poll={poll} />
          ) : (
            poll.choices
              .sort((a, b) => a.position - b.position)
              .map((choice) => {
                const selected = mySelected.findIndex(
                  (selectedChoice) => selectedChoice.id === choice.id
                )
                return (
                  <Box
                    key={choice.id}
                    className={
                      selected < 0
                        ? customClasses.common
                        : customClasses.mySelected
                    }
                    my={1}
                  >
                    <PollOption
                      imageRef={choice.imageRef}
                      label={choice.name}
                      mySelected={selected < 0 ? false : true}
                    />
                  </Box>
                )
              })
          )}
        </Box>
      )}

      <TextBox type={poll.type} />

      <Box mt="24px">
        {poll.isCanEditChoice ? (
          <CustomButton
            fullWidth
            variant="outlined"
            color="primary"
            onClick={handleEditChoice}
          >
            Change Answer
          </CustomButton>
        ) : (
          <CustomButton fullWidth variant="contained" color="primary" disabled>
            Submitted
          </CustomButton>
        )}
      </Box>
    </React.Fragment>
  )
}

export default Placeholder
