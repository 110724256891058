import React, { useState, useContext } from 'react'
import {
  Typography,
  Box,
  AppBar,
  Toolbar,
  makeStyles,
  SwipeableDrawer,
  useTheme,
  useMediaQuery,
  List,
  ListItem,
  Divider,
  IconButton,
  Avatar
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation, navigate } from '@reach/router'

import AppContext from '../../Services/AppContext'
import ThemeContext from '../../Theme/ThemeContext'
import { Logos } from '../../utils/images'
import { variables, CustomButton } from '../../utils/styled'
import PathHelper from '../../utils/PathHelper'
import { hexToRgba } from '../../utils/helper'
import AvatarDropDown from '../Navbar/AvatarDropDown'
import { GetUserTokenFromStorage } from '../../utils/helper'

const customStyles = makeStyles((theme) => ({
  rootToolbar: {
    ...theme.redeemTicket.navbarTheme
  },
  rootToolbarEventList: {
    background: 'white',
    position: 'static',
    color: variables.grayBase
  },
  toolbar: {
    height: '72px'
  },
  drawerPaper: {
    width: '60%'
  },
  menuWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '1080px',
    margin: '0 auto',
    ...theme.redeemTicket.eventName,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  imgLogo: {
    height: '40px',
    display: 'block',
    cursor: 'pointer'
  },
  buttonRedOutline: {
    borderColor: variables.popliveRed,
    color: variables.popliveRed
  },
  buttonHamburgur: {
    ...theme.redeemTicket.buttonHamburgur
  },
  listWrapper: {
    padding: '24px'
  },
  listItemWrapper: {
    padding: 0
  },
  divider: {
    ...theme.divider,
    ...theme.redeemTicket.divider,
    width: '100%'
  },
  signInButton: {
    ...theme.navBar.signInButton
  },
  signUpButton: {
    ...theme.navBar.signUpButton
  },
  textBecomeToHost: {
    ...theme.navBar.textBecomeToHost
  },
  textInMenuList: {
    fontSize: '14px',
    color: variables.grayBase
  },
  subtitleTextInMenuList: {
    fontSize: '12px',
    color: variables.grayDark
  },
  avatar: {
    ...theme.navBar.avatar
  }
}))

const DisplayMenu = ({
  signOut,
  user,
  token,
  checkPathIsEventListing,
  goToEventPoploginPage
}) => {
  const customClasses = customStyles()
  if (!token || !user) {
    return (
      <Box display="flex" gridGap="16px">
        <CustomButton
          variant="outlined"
          classes={{ root: customClasses.signUpButton }}
          style={{
            borderColor: checkPathIsEventListing ? variables.popliveRed : '',
            color: checkPathIsEventListing ? variables.popliveRed : '',
            backgroundColor: checkPathIsEventListing
              ? hexToRgba(variables.popliveRed, 4)
              : ''
          }}
          onClick={() => goToEventPoploginPage()}
        >
          Sign Up
        </CustomButton>
        <CustomButton
          variant="contained"
          color="primary"
          classes={{ root: customClasses.signInButton }}
          style={{
            backgroundColor: checkPathIsEventListing ? variables.popliveRed : ''
          }}
          onClick={() => goToEventPoploginPage()}
        >
          Log In
        </CustomButton>
      </Box>
    )
  } else {
    return (
      <AvatarDropDown
        signOut={signOut}
        user={user}
        checkPathIsEventListing={checkPathIsEventListing}
      />
    )
  }
}

export default function Navbar({ scrollToEventOnline }) {
  const location = useLocation()
  const { pathname } = location
  const checkPathIsEventListing = pathname === '/'
  const customClasses = customStyles({ location: pathname })
  const [openMenu, setOpenMenu] = useState(false)
  const theme = useTheme()
  const appContext = useContext(AppContext.Context)
  const { token } = GetUserTokenFromStorage()
  const { user } = appContext

  const themeContext = useContext(ThemeContext.Context)
  const { eventTheme, eventSetting } = themeContext
  const smSizeDown = useMediaQuery(theme.breakpoints.down('sm'))

  const goToEventPoploginPage = () => {
    appContext.actions.setRedirectUrl(pathname)
    navigate(PathHelper.auth.userSignIn())
  }

  const getLogoByTheme = () => {
    if (eventSetting?.redeemTicket) {
      if (eventSetting.redeemTicket.backgroundMode === 'backgroundColor') {
        if (eventSetting.redeemTicket.backgroundColor.theme === 'dark')
          return Logos.popliveHorizontalWhite
        else return Logos.popliveHorizontal
      } else if (eventSetting.redeemTicket.backgroundMode === 'backgroundImg') {
        if (eventSetting.redeemTicket.backgroundImage.theme === 'dark')
          return Logos.popliveHorizontalWhite
        else return Logos.popliveHorizontal
      } else {
        if (eventTheme?.layoutTheme === 'dark')
          return Logos.popliveHorizontalWhite
        return Logos.popliveHorizontal
      }
    } else {
      if (eventTheme?.layoutTheme === 'dark')
        return Logos.popliveHorizontalWhite
      return Logos.popliveHorizontal
    }
  }

  const closeDrawerMenuToEventOnline = () => {
    setOpenMenu(false)
    setTimeout(() => scrollToEventOnline(), 500)
  }

  const handleToPopliveWeb = () => {
    if (checkPathIsEventListing) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    } else scrollToEventOnline()
  }

  const signOut = async () => {
    navigate(PathHelper.auth.userSignOut())
    setOpenMenu(false)
  }

  const DisplayAvatarMobileSize = ({ token, checkPathIsEventListing }) => {
    if (token) {
      return (
        <IconButton
          onClick={() => setOpenMenu(true)}
          size="small"
          sx={{ ml: 2 }}
          aria-haspopup="dialog"
        >
          <Avatar
            className={customClasses.avatar}
            sx={{ width: 32, height: 32 }}
            style={{
              backgroundColor: checkPathIsEventListing
                ? variables.popliveRed
                : ''
            }}
          >
            {user.firstname[0]}
          </Avatar>
        </IconButton>
      )
    } else {
      return (
        <CustomButton
          variant="outlined"
          classes={{
            root: checkPathIsEventListing
              ? customClasses.buttonRedOutline
              : customClasses.buttonHamburgur
          }}
          onClick={() => setOpenMenu(true)}
        >
          <FontAwesomeIcon icon={['far', 'bars']} size={'xl'} />
        </CustomButton>
      )
    }
  }

  return (
    <AppBar
      elevation={0}
      classes={{
        root: checkPathIsEventListing
          ? customClasses.rootToolbarEventList
          : customClasses.rootToolbar
      }}
    >
      <Toolbar className={customClasses.toolbar}>
        <Box className={customClasses.menuWrapper}>
          <img
            src={
              checkPathIsEventListing
                ? Logos.popliveHorizontal
                : getLogoByTheme()
            }
            alt="POP LIVE"
            className={`${customClasses.imgLogo}`}
            onClick={() => handleToPopliveWeb()}
          />
          <Box display="flex" gridGap="36px" alignItems="center">
            {!smSizeDown ? (
              <>
                <Typography
                  variant="h6"
                  onClick={() => scrollToEventOnline()}
                  style={{
                    cursor: 'pointer',
                    color: pathname === '/' ? variables.grayBase : ''
                  }}
                >
                  <strong>Explore Online Events</strong>
                </Typography>
                <a
                  style={{
                    textDecoration: 'none',
                    color: 'inherit'
                  }}
                  href={PathHelper.app.popliveSale()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography
                    variant="h6"
                    className={customClasses.textBecomeToHost}
                    style={{
                      color: checkPathIsEventListing ? variables.popliveRed : ''
                    }}
                  >
                    <b>Become a Host</b>
                  </Typography>
                </a>

                <DisplayMenu
                  signOut={signOut}
                  user={user}
                  token={token}
                  checkPathIsEventListing={checkPathIsEventListing}
                  goToEventPoploginPage={goToEventPoploginPage}
                />
              </>
            ) : (
              <DisplayAvatarMobileSize
                token={token}
                checkPathIsEventListing={checkPathIsEventListing}
              />
            )}
          </Box>
        </Box>
      </Toolbar>
      <Divider className={customClasses.divider} />

      {/* Swipe Drawer */}
      <SwipeableDrawer
        anchor="right"
        open={openMenu && smSizeDown}
        onOpen={() => setOpenMenu(true)}
        onClose={() => setOpenMenu(false)}
        classes={{ paper: customClasses.drawerPaper }}
      >
        <List className={customClasses.listWrapper}>
          <ListItem className={customClasses.listItemWrapper}>
            <Box mb="24px" style={{ cursor: 'pointer' }}>
              <Typography
                variant="h5"
                onClick={() => closeDrawerMenuToEventOnline()}
              >
                <strong>Explore Online Events</strong>
              </Typography>
            </Box>
          </ListItem>
          <ListItem className={customClasses.listItemWrapper}>
            <a
              style={{
                textDecoration: 'none',
                color: 'inherit'
              }}
              href={PathHelper.app.popliveSale()}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography
                variant="h5"
                className={customClasses.textBecomeToHost}
                style={{
                  color: checkPathIsEventListing ? variables.popliveRed : ''
                }}
              >
                <b>Become a Host</b>
              </Typography>
            </a>
          </ListItem>
        </List>
        <Divider />

        {token ? (
          <List>
            <ListItem button>
              <div>
                <Typography
                  variant="h6"
                  className={customClasses.textInMenuList}
                  style={{ color: variables.grayDark }}
                >
                  {user.firstname} {user.lastname}
                </Typography>
                <Typography
                  variant="h6"
                  className={customClasses.subtitleTextInMenuList}
                >
                  {user.email}
                </Typography>
              </div>
            </ListItem>
            <ListItem button>
              <Box
                width="100%"
                display="flex"
                gridGap="12px"
                justifyContent="space-between"
              >
                <Box>
                  <Typography className={customClasses.textInMenuList}>
                    My Wallet
                  </Typography>
                  <Typography className={customClasses.subtitleTextInMenuList}>
                    This will take you to
                    <br /> www.eventpop.me
                  </Typography>
                </Box>
                <FontAwesomeIcon icon={['far', 'external-link']} size="1x" />
              </Box>
            </ListItem>
            <ListItem button onClick={signOut}>
              <Typography className={customClasses.textInMenuList}>
                Log out
              </Typography>
            </ListItem>
          </List>
        ) : (
          <List>
            <ListItem>
              <CustomButton
                variant="outlined"
                fullWidth
                classes={{ root: customClasses.signUpButton }}
                style={{
                  borderColor: checkPathIsEventListing
                    ? variables.popliveRed
                    : '',
                  color: checkPathIsEventListing ? variables.popliveRed : '',
                  backgroundColor: checkPathIsEventListing
                    ? hexToRgba(variables.popliveRed, 4)
                    : ''
                }}
                onClick={() => goToEventPoploginPage()}
              >
                Sign Up
              </CustomButton>
            </ListItem>
            <ListItem>
              <CustomButton
                fullWidth
                color="primary"
                variant="contained"
                classes={{ root: customClasses.signInButton }}
                style={{
                  backgroundColor: checkPathIsEventListing
                    ? variables.popliveRed
                    : '',
                  color: checkPathIsEventListing ? 'white' : ''
                }}
                onClick={() => goToEventPoploginPage()}
              >
                Log In
              </CustomButton>
            </ListItem>
          </List>
        )}
      </SwipeableDrawer>
    </AppBar>
  )
}
