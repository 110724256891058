import { useEffect, useContext, useState, useRef } from 'react'
import styled from '@material-ui/core/styles/styled'
import Layout, {
  Root,
  getDrawerSidebar,
  getSidebarContent,
  getContent
} from '@mui-treasury/layout'
import {
  Divider,
  ListItem,
  List,
  makeStyles,
  createStyles
} from '@material-ui/core'
import { v4 as uuidv4 } from 'uuid'
import randomstring from 'randomstring'
import { navigate } from '@reach/router'
import { onSnapshot, where, query } from 'firebase/firestore'

import AppContext from '../../Services/AppContext'
import EventContext from '../../Services/EventContext'
import ThemeContext from '../../Theme/ThemeContext'
import LivestreamContainer from '../../Components/LivestreamContainer'
import LivestreamChat from '../../Components/Chat/LivestreamChat'
import LivestreamPoll from '../../Components/Poll/LivestreamPoll'
import PathHelper from '../../utils/PathHelper'
import TabMenus from '../../Components/Livestream/TabMenus'
import {
  enterPresenceInSession,
  exitPresenceInSession,
  checkPresence,
  subscribePresenceChange
} from '../../Services/SessionService'
import { useLocalStorage } from '../../utils/useLocalStorage'
import ModalLayout from '../../Components/Shares/ModalLayout'
import {
  modalStyles,
  useStyles as useSharedStyles,
  CustomButton
} from '../../utils/styled'
import QuestionAnswerCrisp from '../../Components/QuestionAnswerCrisp'
import LiveStreamChapter from '../../Components/Chapters/LiveStreamChapter'
import { DoNotRenderWhenOffscreen } from './DoNotRenderWhenOffscreen'
import { getPollsDoc } from '../../Services/SessionService'

const scheme = Layout()

const DrawerSidebar = getDrawerSidebar(styled)
const SidebarContent = getSidebarContent(styled)
const Content = getContent(styled)

scheme.configureEdgeSidebar((builder) => {
  builder
    .create('sidebar', { anchor: 'right' })
    .registerTemporaryConfig('xs', {
      anchor: 'right',
      width: 'auto'
    })
    .registerPermanentConfig('md', {
      anchor: 'right',
      width: 360
    })
})

const useStyles = makeStyles((theme) =>
  createStyles({
    sidebar: {
      background: 'transparent',
      zIndex: 1,
      border: 'none'
    },
    divider: theme.divider,
    kickModalContainer: {
      textAlign: 'center',
      ...theme.modal.text
    }
  })
)

const LivestreamPage = ({ sessionId }) => {
  const classes = useStyles()
  const modalClasses = modalStyles()
  const sharedClasses = useSharedStyles()

  const themeContext = useContext(ThemeContext.Context)
  const theme = themeContext.theme
  const context = useContext(EventContext.Context)
  const event = context.event
  const [polls, setPolls] = useState([])
  const [alreadyPresent, setAlreadyPresent] = useState(false)
  const [openKickModal, setOpenKickModal] = useState(false)
  const [presenceId, setPresenceId] = useLocalStorage('presenceId', null)
  const session = context.actions.loadSession(sessionId)
  const appContext = useContext(AppContext.Context)
  const user = appContext.currentUser
  const { flags, currentProfile } = appContext
  const entered = useRef(false)
  // Check if user has permission
  const authenticated = context.actions.checkSessionPermission(sessionId)

  useEffect(() => {
    let interval
    if (flags?.includes('test-polls-stream')) {
      let pollsTest = []

      const words = ['active', 'ended']
      for (let index = 0; index < 3; index++) {
        const poll = {
          id: uuidv4(),
          question: uuidv4(),
          status: words[Math.floor(Math.random() * 2)],
          isCanEditChoice: false,
          multipleChoices: false,
          choiceWithImage: false,
          startAt: null,
          endAt: null,
          openAndCloseOptions: 'manually',
          description: '',
          type: 'public',
          votedCount: 1,
          choices: [
            {
              id: `C-${randomstring.generate(6).toUpperCase()}`,
              imageRef: null,
              name: `C-${randomstring.generate(6).toUpperCase()}`,
              position: 0,
              votedCount: 0,
              voter: []
            },
            {
              id: `C-${randomstring.generate(6).toUpperCase()}`,
              imageRef: null,
              name: `C-${randomstring.generate(6).toUpperCase()}`,
              position: 0,
              votedCount: 1,
              voter: [currentProfile.id]
            }
          ]
        }
        pollsTest.push(poll)
      }

      setPolls(pollsTest)
      interval = setInterval(() => {
        const randomPollIndex = Math.floor(Math.random() * pollsTest.length)
        const selectedPoll = pollsTest[randomPollIndex]
        if (selectedPoll.status === 'active') {
          const randomChoiceIndex = Math.floor(
            Math.random() * selectedPoll.choices.length
          )
          const selectedChoice = selectedPoll.choices[randomChoiceIndex]

          selectedChoice.votedCount += 1
          selectedPoll.votedCount += 1

          setPolls((prevPolls) => {
            const updatedPolls = [...prevPolls]
            updatedPolls[randomPollIndex] = selectedPoll
            return updatedPolls
          })
        }
      }, 1000)
    }

    return () => clearInterval(interval)
  }, [flags])

  // Updating our presence
  useEffect(() => {
    if (authenticated) {
      async function checkAndEnterPresence() {
        let currentPresenceId = presenceId

        if (!currentPresenceId) {
          currentPresenceId = uuidv4()
          setPresenceId(currentPresenceId)
        }

        const presence = await checkPresence(
          event.id,
          sessionId,
          user,
          currentPresenceId
        )
        setAlreadyPresent(presence)

        if (presence) {
          setOpenKickModal(true)
          context.actions.setStart(false)
        } else {
          //User have join session
          enterPresence(event.id, sessionId, user, currentPresenceId)
        }
      }

      checkAndEnterPresence()
    }

    return () => {
      if (authenticated && entered) {
        exitPresenceInSession(event.id, sessionId, user)
        entered.current = false
      }
    }
  }, [sessionId, authenticated])

  useEffect(() => {
    if (event.crispWebsiteId) {
      window.$crisp = []
      window.CRISP_WEBSITE_ID = event.crispWebsiteId
      ;(function () {
        var d = document
        var s = d.createElement('script')

        s.src = 'https://client.crisp.chat/l.js'
        s.async = 1
        d.getElementsByTagName('head')[0].appendChild(s)
        window.$crisp.push(['do', 'chat:hide'])
      })()
    }
  }, [event.crispWebsiteId])

  useEffect(() => {
    let unsubscribe = () => {}
    if (event?.id && !flags?.includes('test-polls-stream')) {
      const q = query(
        getPollsDoc(event.id, sessionId),
        where('status', '!=', 'draft')
      )

      unsubscribe = onSnapshot(q, (snapshot) => {
        if (snapshot.empty) {
          setPolls([])
        } else {
          const tempDoc = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          }))
          setPolls(tempDoc)
        }
      })
    }

    return () => unsubscribe()
  }, [event, sessionId, flags])

  const proceedKick = () => {
    enterPresence(event.id, sessionId, user, presenceId, true)
    setOpenKickModal(false)
  }

  const cancelKick = () => {
    setOpenKickModal(false)
  }

  const enterPresence = async (
    eventId,
    sessionId,
    user,
    presenceId,
    force = false
  ) => {
    entered.current = true
    setAlreadyPresent(false)
    await enterPresenceInSession(eventId, sessionId, user, presenceId)

    subscribePresenceChange(eventId, sessionId, user, (data) => {
      if (force) {
        force = false
      } else if (data.presenceId && data.presenceId !== presenceId) {
        context.actions.setStart(false)
        setAlreadyPresent(true)
        setOpenKickModal(true)
      }
    })
  }

  if (authenticated && session) {
    return (
      <Root theme={theme} scheme={scheme}>
        <Content>
          <LivestreamContainer
            event={event}
            session={session}
            alreadyPresent={alreadyPresent}
            chat={
              <LivestreamChat
                authenticated={authenticated}
                sessionId={session.sessionId}
                alreadyPresent={alreadyPresent}
              />
            }
            poll={
              <LivestreamPoll
                eventId={event.id}
                eventSlug={event.slug}
                sessionId={session.sessionId}
                polls={polls}
              />
            }
            qa={<QuestionAnswerCrisp />}
            chapter={
              <LiveStreamChapter
                sessionId={session.sessionId}
                eventPoster={event.posterURL}
              />
            }
          />
        </Content>
        <DrawerSidebar
          sidebarId="sidebar"
          PaperProps={{ className: classes.sidebar }}
        >
          {
            // DrawerSidebar renders children twice, but only one of them is visible.
            // This caused the chat panel to be rendered in two separate places, which hurts performance.
            // This is a bug in a 3rd-party library `@mui-treasury/layout`,
            // but it seems like the project has not been maintained for a while.
            //
            // To optimize performance in this case, a component `DoNotRenderWhenOffscreen` is used.
            // It uses an `IntersectionObserver` to detect whether the element is visible,
            // and only renders its children when intersecting.
          }
          <DoNotRenderWhenOffscreen>
            <SidebarContent>
              <TabMenus
                session={session}
                alreadyPresent={alreadyPresent}
                chat={
                  <LivestreamChat
                    sessionId={session.sessionId}
                    alreadyPresent={alreadyPresent}
                    authenticated={authenticated}
                  />
                }
                poll={
                  <LivestreamPoll
                    eventId={event.id}
                    eventSlug={event.slug}
                    sessionId={session.sessionId}
                    polls={polls}
                    alreadyPresent={alreadyPresent}
                  />
                }
                qa={<QuestionAnswerCrisp />}
                chapter={
                  <LiveStreamChapter
                    sessionId={session.sessionId}
                    eventPoster={event.posterURL}
                  />
                }
              />
            </SidebarContent>
          </DoNotRenderWhenOffscreen>
        </DrawerSidebar>

        <ModalLayout notClosable={true} open={openKickModal}>
          <div className={`${modalClasses.xs} ${classes.kickModalContainer}`}>
            <div className={sharedClasses.spacer} />
            <h2 className={`${sharedClasses.fontWeightNormal}`}>
              User already present
            </h2>
            <p className={sharedClasses.mb3}>
              Click proceed to continue to the session, the previous user /
              device will be disconnected.
            </p>

            <List className={sharedClasses.py0}>
              <ListItem onClick={() => proceedKick()}>
                <CustomButton fullWidth>Proceed</CustomButton>
              </ListItem>
              <Divider className={classes.divider} />
              <ListItem onClick={() => cancelKick()}>
                <CustomButton fullWidth> Cancel</CustomButton>
              </ListItem>
            </List>

            <Divider className={classes.divider} />
            <div className={sharedClasses.spacer} />
          </div>
        </ModalLayout>
      </Root>
    )
  } else {
    navigate(PathHelper.event.lobbyPath(event.slug))

    return <p>Not found</p>
  }
}

export default LivestreamPage
