import React, { useContext, useState } from 'react'
import { Select, InputLabel, FormControl, makeStyles } from '@material-ui/core'
import {
  modalStyles,
  useStyles as helperStyles,
  CustomInput,
  CustomButton,
  variables
} from '../../utils/styled'
import { useForm, Controller } from 'react-hook-form'
import AppContext from '../../Services/AppContext'
import Avatar from '../Shares/Avatar'
import CountriesCode from '../../utils/CountriesCode'

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main
  },
  titleFirstStep: {
    fontWeight: 'normal',
    textAlign: 'center',
    marginTop: 0
  },
  errorsMessage: {
    color: variables.brandDanger,
    fontSize: 12,
    marginTop: theme.spacing(0.5)
  },
  customSelect: {
    '& label': {
      background: '#fff'
    },
    '& select': {
      paddingTop: '10.5px',
      paddingBottom: '10.5px'
    }
  },
  hint: {
    fontSize: 12,
    marginTop: theme.spacing(0.5),
    color: variables.gray
  },
  inputLabel: {
    fontSize: '14px',
    marginBottom: 8
  }
}))

const SetupProfile = (props) => {
  const { user, handleClose, isFirstStep } = props
  const modalClasses = modalStyles()
  const appContext = useContext(AppContext.Context)
  const { control, handleSubmit, register, errors, getValues } = useForm({
    defaultValues: user
  })
  const [currentUser, setCurrentUser] = useState(user)
  const classes = useStyles()
  const helperClasses = helperStyles()

  const [isUpdateProfile, setIsUpdateProfile] = useState(false)

  const inputLimit = {
    bio: 150,
    displayName: 50
  }

  const onSubmit = async (data) => {
    setIsUpdateProfile(() => true)
    data.country = currentUser.country === '' ? 'TH' : currentUser.country
    await appContext.actions.setProfile(data)
    setIsUpdateProfile(false)
    handleClose()
  }

  const handleChange = (data) => {
    setCurrentUser({
      ...user,
      country: data.target.value
    })
  }

  const displayInputLimit = (type) => {
    if (getValues(type)) {
      return `${getValues(type).length}/${inputLimit[type]}`
    } else if (user[type]) {
      return `${user[type].length}/${inputLimit[type]}`
    } else {
      return `0/${inputLimit[type]}`
    }
  }

  return (
    <div
      className={`${modalClasses.sm} ${helperClasses.px4} ${helperClasses.py4}`}
    >
      {isFirstStep && (
        <div className={`${helperClasses.textCenter} ${classes.title}`}>
          Let’s get to know you better
        </div>
      )}

      <h1 className={classes.titleFirstStep}>
        {isFirstStep ? 'Setup Your Profile' : 'Edit Your Profile'}
      </h1>
      <Avatar user={user} type="profile" />
      <div className={helperClasses.spacerXs}></div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="file"
          name="imageProfile"
          ref={register}
          className={helperClasses.mb3}
        />

        <InputLabel className={classes.inputLabel}>Display Name</InputLabel>
        <Controller
          as={CustomInput}
          control={control}
          variant="outlined"
          size="small"
          name="displayName"
          placeholder="Fill Your Name Here"
          error={!!errors.displayName}
          className={errors.displayName ? `has-error` : ''}
          rules={{
            required: true,
            maxLength: inputLimit['displayName']
          }}
        />
        <div className={classes.hint}>{displayInputLimit('displayName')}</div>
        {errors.displayName && errors.displayName.type === 'maxLength' && (
          <div className={classes.errorsMessage}>Maxinum 50 Characters</div>
        )}
        {errors.displayName && errors.displayName.type === 'required' && (
          <div className={classes.errorsMessage}>
            The display name can't be blank.
          </div>
        )}

        <InputLabel className={`${classes.inputLabel} ${helperClasses.mt1}`}>
          Short Biography
        </InputLabel>
        <Controller
          as={CustomInput}
          control={control}
          variant="outlined"
          size="small"
          name="bio"
          defaultValue=""
          error={!!errors.bio}
          className={errors.bio ? `has-error ${helperClasses.mt2}` : null}
          rules={{
            maxLength: inputLimit['bio']
          }}
        />
        <div className={classes.hint}>{displayInputLimit('bio')}</div>
        {errors.bio && errors.bio.type === 'maxLength' && (
          <div className={classes.errorsMessage}>Maxinum 150 Characters.</div>
        )}

        <InputLabel className={`${classes.inputLabel} ${helperClasses.mt1}`}>
          Country
        </InputLabel>
        <FormControl variant="outlined" className={helperClasses.customSelect}>
          <Select
            native
            size="small"
            value={currentUser.country}
            onChange={handleChange}
            inputProps={{
              name: 'country',
              id: 'filled-country'
            }}
          >
            {CountriesCode.map((item, index) => (
              <option value={item.code} key={index}>
                {item.name}
              </option>
            ))}
          </Select>
        </FormControl>

        <CustomButton
          variant="outlined"
          fullWidth
          color="primary"
          className={helperClasses.mt3}
          type="submit"
          disabled={isUpdateProfile}
        >
          {isFirstStep ? 'Setup Profile' : 'Update Profile'}
        </CustomButton>
      </form>
    </div>
  )
}

export default SetupProfile
