import { useEffect, useRef } from 'react'
import moment from 'moment'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

export const FormatTimeNumber = (number) => {
  return ('0' + number).slice(-2)
}

export const FormatRelativeTime = (time) => {
  if (ConvertTimeStamp(time).seconds) {
    const timeFormat = moment(ConvertTimeStamp(time).seconds * 1000).format(
      'YYYY/MM/DD HH:mm:ss'
    )
    const relativeTime = moment(timeFormat, 'YYYY/MM/DD HH:mm:ss').fromNow()
    return relativeTime
  }

  return '-'
}

export const TimestampToFormatShotTime = (timestamp) => {
  if (ConvertTimeStamp(timestamp).seconds) {
    const format = 'DD MMM YYYY HH:mm'
    const date = moment(ConvertTimeStamp(timestamp).seconds * 1000).format(
      format
    )
    return date
  } else {
    return null
  }
}

export const CheckStateSession = (
  isLive = false,
  startAt,
  endAt,
  currentTime
) => {
  if (isLive) {
    return 'start'
  } else if (currentTime < startAt) {
    return 'wait'
  } else if (currentTime >= startAt && currentTime < endAt) {
    return 'start'
  } else {
    return 'ended'
  }
}

export const TimeIsBetweenStarAtAndEndAt = (startAt, endAt) => {
  let isBeforeStart = null
  let isBetween = null
  let isEnded = null

  if (startAt && endAt) {
    const startAtFormat = ConvertTimeStamp(startAt)
    const endedFormat = ConvertTimeStamp(endAt)
    var format = 'YYYY/MM/DD HH:mm:ss'
    const timeFormat = (date) => moment(date).format(format)

    const now = moment().format(format)
    const startTime = timeFormat(startAtFormat.toDate())
    const endTime = timeFormat(endedFormat.toDate())

    isBeforeStart = moment(now).isBefore(moment(startTime))
    isBetween = moment(now, format).isBetween(
      moment(startTime, format),
      moment(endTime, format)
    )
    isEnded = moment(now).isAfter(moment(endTime))
  }

  return { isBeforeStart, isBetween, isEnded }
}

export const formatTimeNumber = (number) => {
  return ('0' + number).slice(-2)
}

export const UseInterval = (callback, delay) => {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current()
    }, delay)

    return () => clearInterval(id)
  }, [delay])
}

export const ConvertTime = (startAt, timecurrent = moment().millisecond()) => {
  let then = moment(ConvertTimeStamp(startAt).seconds * 1000)
  let now = moment(timecurrent)
  let time = moment.duration(then - now)
  let seconds = time.seconds()
  let minutes = time.minutes()
  let hours = time.hours()
  let days = time.days()

  let date = null
  let dateAndTime = null
  let relativeTime = null
  let timeNumber = null

  if (days > 0) {
    date = {
      full: then.format('DD MMMM YYYY'),
      short: then.format('DD/MM/YY')
    }

    dateAndTime = {
      full: then.format('DD MMMM YYYY, HH:mm'),
      short: then.format('DD/MM/YY HH:mm')
    }

    relativeTime = then.endOf('day').fromNow()
  } else {
    timeNumber = `${formatTimeNumber(hours)}:${formatTimeNumber(
      minutes
    )}:${formatTimeNumber(seconds)}`
  }

  return {
    days,
    hours,
    minutes,
    seconds,
    format: { date, dateAndTime, timeNumber, relativeTime }
  }
}

export const ConvertTimeStamp = (timeStamp) => {
  if (timeStamp.seconds) {
    return timeStamp
  } else if (timeStamp._seconds) {
    return {
      seconds: timeStamp._seconds
    }
  }
}

export const SecuredUrl = (link) => {
  const regEx = /^http/
  return regEx.test(link) ? link : `https://${link}`
}

export const hexToRgba = (hex, opacity = 100) => {
  const opacities = {
    100: '1',
    64: '0.60',
    48: '0.48',
    32: '0.32',
    16: '0.16',
    8: '0.08',
    4: '0.04'
  }
  const rgbRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i

  if (hex) {
    const rgb = hex
      .replace(rgbRegex, (m, r, g, b) => '#' + r + r + g + g + b + b)
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16))

    if (opacity <= 1) {
      return `rgba(${rgb[0]},${rgb[1]},${rgb[2]}, ${1 - opacity})`
    } else return `rgba(${rgb[0]},${rgb[1]},${rgb[2]}, ${opacities[opacity]})`
  }

  return null
}

export const GetUserTokenFromStorage = () => {
  let token = cookies.get('token')
  return {
    token
  }
}

export function generateRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const convertSecondsToTimeFormat = (time) => {
  const hours = Math.floor(time / 3600)
  const minutes = Math.floor((time % 3600) / 60)
  const seconds = time % 60

  var timeString = ''

  if (hours > 0) {
    timeString += hours.toString().padStart(2, '0') + ':'
  }

  timeString +=
    minutes.toString().padStart(2, '0') +
    ':' +
    seconds.toString().padStart(2, '0')

  return timeString
}

export function parseEmoji(text, emojis) {
  const regex = /:([a-z-]+):/g
  const decode = text
    .replace(/&/g, '&amp;') // Replace & with &amp;
    .replace(/</g, '&lt;') // Replace < with &lt;
    .replace(/>/g, '&gt;') // Replace > with &gt;
    .replace(/"/g, '&quot;') // Replace " with &quot;
    .replace(/'/g, '&#039;') // Replace ' with &#039;
  const emojiMap = new Map(emojis.map((emoji) => [emoji.name, emoji]))
  const parsedText = decode.replace(regex, (match) => {
    const emoji = emojiMap.get(match)
    if (emoji) {
      return `<img src="${emoji.imageURL}" style="vertical-align:middle; width: 30px; margin: 2px; height: 30px" alt="${match}" />`
    } else {
      return match
    }
  })

  return parsedText
}
