import React from 'react'
import { Box } from '@material-ui/core'

import Navbar from '../SalePage/Navbar'
import Footer from '../SalePage/Footer'

function PopliveLayout({ children, scrollToEventOnline }) {
  return (
    <Box position="relative">
      <Navbar scrollToEventOnline={scrollToEventOnline} />
      {children}
      <Footer />
    </Box>
  )
}

export default PopliveLayout
