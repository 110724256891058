import React from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'

import { useStyles } from '../../../utils/styled'

const customStyles = makeStyles((theme) => ({
  root: {
    height: '320px',
    textAlign: 'center'
  },
  description: {
    ...theme.poll.description
  }
}))

const PollEmptyState = () => {
  const helperClasses = useStyles()
  const customClasses = customStyles()

  return (
    <Box
      className={customClasses.root}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography
        variant="h5"
        className={`${customClasses.description} ${helperClasses.mb1}`}
      >
        There is no poll at the moment
      </Typography>
      <Typography
        variant="caption"
        className={`${customClasses.description}  ${helperClasses.px1}`}
      >
        You can cast your vote here when the host creates a poll.
      </Typography>
    </Box>
  )
}

export default PollEmptyState
