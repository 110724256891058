import googleLogo from '../assets/images/gogole-logo.svg'
import eventpopLogo from '../assets/images/eventpop-logo.svg'
import popliveLogo from '../assets/images/poplive-logo.svg'
import popliveLogoWhite from '../assets/images/poplive-logo-white.svg'
import popliveHorizontal from '../assets/images/logo.png'
import popliveHorizontalWhite from '../assets/images/logo-white.png'
import pageNotFound from '../assets/images/404.svg'

const Logos = {
  google: googleLogo,
  eventpop: eventpopLogo,
  poplive: popliveLogo,
  popliveWhite: popliveLogoWhite,
  popliveHorizontal: popliveHorizontal,
  popliveHorizontalWhite: popliveHorizontalWhite
}

const Images = {
  pageNotFound: pageNotFound
}

export { Images, Logos }
