import React from 'react'
import { modalStyles, useStyles } from '../../utils/styled'
import { Modal, Backdrop, Fade, Paper } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function TransitionsModal(props) {
  const { open, handleClose, children, notClosable } = props
  const classes = useStyles()
  const modalClasses = modalStyles()

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        disableEscapeKeyDown={notClosable}
        className={modalClasses.modal}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Paper className={modalClasses.paper}>
            {!notClosable && (
              <div
                className={`${modalClasses.closeButton} ${classes.cursorPointer}`}
                onClick={() => handleClose()}
              >
                <FontAwesomeIcon icon={['far', 'times']} />
              </div>
            )}
            {children}
          </Paper>
        </Fade>
      </Modal>
    </div>
  )
}
