import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core'
import EventContext from '../../Services/EventContext'
import { variables } from '../../utils/styled'

const useStyles = makeStyles((theme) => ({
  root: {},
  posterBox: {
    position: 'relative',
    overflow: 'hidden',
    height: '100%',

    [theme.breakpoints.down('sm')]: {
      paddingTop: '100%'
    },

    '&.ratio-sixteen-nine': {
      paddingTop: '56.25%'
    }
  },

  placeholderPosterEvent: {
    background: `linear-gradient(${variables.gradientPoster})`,
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: '-1'
  },

  posterEventBox: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: '1',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,

    '& img': {
      maxWidth: '80%',
      maxHeight: '420px',

      '&.has-shadow': {
        boxShadow: '0px 4px 64px rgba(0, 0, 0, 0.2)'
      }
    }
  },

  titleBoxWithGradient: {
    position: 'absolute',
    zIndex: '1',
    opacity: '0.5',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transition: 'background 0.3s, border-radius 0.3s, opacity 0.3s',
    background: variables.grayBase
  },

  titleBox: {
    position: 'absolute',
    zIndex: '2',
    bottom: '8px',
    left: '24px',
    right: '24px',
    color: '#fff',
    textAlign: 'center',
    padding: '0 40px'
  },
  backgroundEventOnBlur: {
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    position: 'absolute',
    top: '-30px',
    left: '-30px',
    right: '-30px',
    bottom: '-30px',
    filter: 'blur(15px)',
    zIndex: '-1'
  },
  placeholerMessages: {
    color: '#fff',
    textShadow: '0px 4px 64px rgba(0, 0, 0, 0.2)'
  }
}))

const PlaceholderBox = ({ event, children }) => {
  const classes = useStyles()
  if (event && event.posterURL) {
    return (
      <React.Fragment>
        <div
          className={`${classes.backgroundEventOnBlur}`}
          style={{ backgroundImage: `url(${event.posterURL})` }}
        ></div>

        <div className={classes.titleBoxWithGradient}></div>
        <div className={classes.posterEventBox}>
          <div className={classes.placeholerMessages}>{children}</div>
        </div>
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        <div className={classes.placeholderPosterEvent}></div>
        <div className={classes.posterEventBox}>
          <div className={classes.placeholerMessages}>{children}</div>
        </div>
      </React.Fragment>
    )
  }
}

export default function PlaceholderStates({ children }) {
  const eventContext = useContext(EventContext.Context)
  const { event } = eventContext

  const classes = useStyles()

  return (
    <div className={`${classes.posterBox} ratio-sixteen-nine`}>
      <PlaceholderBox event={event} children={children} />
    </div>
  )
}
