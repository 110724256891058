import { useEffect, useState, useContext } from 'react'
import {
  ButtonBase,
  Box,
  Typography,
  makeStyles,
  Badge,
  Chip,
  createStyles
} from '@material-ui/core'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment, { Moment } from 'moment'
import Countdown, { CountdownRenderProps } from 'react-countdown'
import { navigate, useLocation } from '@reach/router'
import numeral from 'numeral'

import {
  formatTimeNumber,
  CheckStateSession,
  ConvertTimeStamp
} from '../../../utils/helper'
import EventContext from '../../../Services/EventContext'
import SessionContext from '../../../Services/SessionContext'
import { ThemeSessionType, SessionViewersType } from '../../../utils/type'

interface ViewersCountProps {
  viewersIncremental: number
  sessionId: string
}
interface NavButtonProp extends ViewersCountProps {
  icon: IconName
  text: string
  link: string
  isLive: boolean
  startAt: Moment
  endAt: Moment
}

const useStyles = makeStyles((theme: ThemeSessionType) =>
  createStyles({
    button: {
      width: '100%',
      margin: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: 0,
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
      borderRadius: theme.spacing(1)
    },
    liveBadgeRoot: {
      width: '100%'
    },
    liveBadge: {
      fontSize: 10,
      fontWeight: 'bold',
      top: theme.spacing(1) + 5,
      right: theme.spacing(3) + 5
    },
    label: {
      fontWeight: 'normal'
    },
    labelActive: {
      fontWeight: 'bold'
    },
    buttonTheme: theme.eventSidebar.navButton.normal,
    buttonThemeActive: theme.eventSidebar.navButton.active,
    buttonThemeActiveWithHighlight:
      theme.eventSidebar.navButton.activeWithHighlight,
    icon: {
      marginBottom: theme.spacing(1)
    },
    iconItme: {
      marginRight: 4
    },
    chip: {
      marginTop: 3,
      fontWeight: 'bold',
      fontSize: 10,
      height: '1.8em'
    },
    chipThemeEnded: theme.chip.liveEnded,
    chipThemeEndedActive: theme.chip.pinMessage,
    viewers: {
      marginTop: 3,
      fontWeight: 'normal',
      fontFamily: 'Arial'
    }
  })
)

const ViewersCount = ({ viewersIncremental, sessionId }: ViewersCountProps) => {
  const classes = useStyles()
  const sessionContext = useContext(SessionContext.Context)
  const { eventViewers } = sessionContext
  const findSessionViewers = eventViewers.find(
    (sessionViewers: SessionViewersType) =>
      sessionViewers.sessionId === sessionId
  )

  const sumViewers = (findSessionViewers?.viewers ?? 0) + viewersIncremental

  let viewers = sumViewers

  if (sumViewers > 999) {
    viewers = numeral(Math.floor(sumViewers)).format('0.0a')
  }

  return (
    <div className={classes.viewers}>
      <FontAwesomeIcon icon={['fal', 'user-friends']} /> {viewers}
    </div>
  )
}

const NavButton = ({
  icon,
  text,
  link,
  isLive,
  viewersIncremental,
  startAt,
  endAt,
  sessionId
}: NavButtonProp) => {
  const classes = useStyles()
  const location = useLocation()
  const eventContext = useContext(EventContext.Context)
  const [liveStreamState, setLiveStreamState] = useState('wait')
  const active = decodeURIComponent(location.pathname) === link
  const [duration, setDuration] = useState<number>()

  useEffect(() => {
    const start = ConvertTimeStamp(startAt).seconds * 1000
    const end = ConvertTimeStamp(endAt).seconds * 1000

    let then = moment(start).seconds()
    let now = moment(eventContext.timeCurrent).seconds()
    let time = moment.duration(then - now)
    setDuration(time.asMilliseconds())

    const state = CheckStateSession(
      isLive,
      start,
      end,
      eventContext.timeCurrent
    )
    setLiveStreamState(state)
  }, [startAt, endAt])

  const handleClick = () => {
    if (location.pathname !== link) {
      navigate(link)
    }
  }

  const buttonStyle = () => {
    if (active) return classes.buttonThemeActiveWithHighlight
    if (!active && liveStreamState === 'ended') return classes.buttonTheme

    return classes.buttonThemeActive
  }

  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed
  }: CountdownRenderProps) => {
    if (completed) {
      return (
        <ViewersCount
          sessionId={sessionId}
          viewersIncremental={viewersIncremental}
        />
      )
    } else {
      let now = moment(eventContext.timeCurrent)
      let start = moment(ConvertTimeStamp(startAt).seconds * 1000)
      let time = start.endOf('day').from(now)
      // Render a countdown
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          <FontAwesomeIcon
            className={classes.iconItme}
            icon={['fal', 'clock']}
          />
          {days <= 0 ? (
            <Typography variant="h6">{`${formatTimeNumber(
              hours
            )}:${formatTimeNumber(minutes)}:${formatTimeNumber(
              seconds
            )}`}</Typography>
          ) : (
            <Typography variant="h6">{time}</Typography>
          )}
        </Box>
      )
    }
  }

  return (
    <Badge
      invisible={!isLive}
      overlap="circular"
      classes={{ root: classes.liveBadgeRoot, badge: classes.liveBadge }}
      color="secondary"
      variant="dot"
    >
      <ButtonBase
        className={`${classes.button} ${buttonStyle()}`}
        onClick={handleClick}
      >
        <Box>
          {icon && (
            <FontAwesomeIcon
              className={classes.icon}
              icon={['fal', icon]}
              size="3x"
            />
          )}
          <Typography
            variant="h6"
            className={active ? classes.labelActive : classes.label}
          >
            {text}
          </Typography>
          {liveStreamState === 'wait' && duration && (
            <Countdown
              date={eventContext.timeCurrent + duration}
              renderer={renderer}
              onComplete={() => setLiveStreamState('start')}
            />
          )}
          {liveStreamState === 'start' && (
            <ViewersCount
              sessionId={sessionId}
              viewersIncremental={viewersIncremental}
            />
          )}
          {liveStreamState === 'ended' && (
            <Chip
              className={`${classes.chip} ${
                active ? classes.chipThemeEndedActive : classes.chipThemeEnded
              }`}
              label="ENDED"
              size="small"
            />
          )}
        </Box>
      </ButtonBase>
    </Badge>
  )
}

export default NavButton
