import React from 'react'
import { Box, Typography, Grid, Container, Hidden } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Images } from '../utils/images'
import { useStyles } from '../utils/styled'

const customStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  innerContent: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      alignItems: 'center'
    }
  },
  description: {
    ...theme.pageNotFound.description
  },
  errorCode: {
    ...theme.pageNotFound.errorCode
  }
}))

const PageNotFound = () => {
  const customClasses = customStyles()
  const classes = useStyles()

  return (
    <div className={customClasses.root}>
      <div className={customClasses.wrapper}>
        <div className={customClasses.contentContainer}>
          <div className={customClasses.innerContent}>
            <Box height="100%" display="flex" alignItems="center">
              <Container>
                <Grid container>
                  <Grid item xs={12} md={5}>
                    <Box display="flex" justifyContent="center">
                      <img
                        alt="Page not found"
                        src={Images.pageNotFound}
                        className={classes.imgResponsive}
                      />
                    </Box>

                    <Hidden mdUp>
                      <div className={classes.spacer}></div>
                    </Hidden>
                  </Grid>

                  <Grid item xs={12} md={7}>
                    <Box height="100%" className={customClasses.content}>
                      <Typography
                        variant="h1"
                        className={classes.textPopliveRed}
                      >
                        Oops!
                      </Typography>
                      <div className={classes.spacer}></div>
                      <Typography
                        variant="h3"
                        className={customClasses.description}
                      >
                        We can’t find the page
                        <Hidden xsDown> you’re looking for.</Hidden>
                      </Typography>
                      <div className={classes.spacer}></div>
                      <Typography
                        variant="h5"
                        className={customClasses.errorCode}
                      >
                        Error code: 404
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageNotFound
