import React, { useContext, useEffect, useState } from 'react'

import { Box, Typography, makeStyles, Hidden } from '@material-ui/core'

import AppContext from '../../../Services/AppContext'
import { useStyles } from '../../../utils/styled'
import Avatar from '../../../Components/Shares/Avatar'

const customeStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 16,
    paddingRight: 16,
    paddingBottom: 24,
    paddingLeft: 64,

    [theme.breakpoints.down('md')]: {
      paddingLeft: 24
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 16
    }
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontWeight: 'bold'
    }
  }
}))

const LobbyBar = ({ event }) => {
  const classes = useStyles()
  const customClasses = customeStyles()
  const appContext = useContext(AppContext.Context)
  const [user, setUser] = useState()

  useEffect(() => {
    if (appContext.currentProfile) {
      setUser(appContext.currentProfile)
    }
  }, [appContext])

  return (
    <Box
      className={customClasses.root}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box display="flex" alignItems="center">
        <Hidden mdUp>
          <Box mr={2}>
            <a
              target="_blank"
              href={event.posterURL}
              pa={0}
              rel="noopener noreferrer"
            >
              <img
                src={event.posterURL}
                alt={event.title}
                className={classes.imgShowTitle}
              />
            </a>
          </Box>
        </Hidden>

        <div>
          <Typography variant="h6" className={classes.textGrayDark}>
            Welcome to
          </Typography>
          <Typography variant="h3" className={customClasses.title}>
            {event.title}
          </Typography>
        </div>
      </Box>

      <Hidden only={['xs', 'sm']}>
        <Avatar user={user} />
      </Hidden>
    </Box>
  )
}

export default LobbyBar
