import React from 'react'
import { Link } from '@reach/router'
import SignInUpLayout from '../Components/Pages/SignInUpLayout'
import SignUp from '../Components/Users/SignUp'
import { useStyles } from '../utils/styled'
import PathHelper from '../utils/PathHelper'

const SignUpPage = () => {
  const classes = useStyles()

  return (
    <SignInUpLayout>
      <SignUp />
      <div className={classes.borderLineBox}>
        <div className="border-line"></div>
        <div className={classes.mx5}>or</div>
        <div className="border-line"></div>
      </div>
      <div className={classes.textCenter}>
        <Link to={PathHelper.auth.signInPath()} className={classes.textLink}>
          Sign In
        </Link>
      </div>
    </SignInUpLayout>
  )
}

export default SignUpPage
