import React from 'react'
import {
  DialogTitle as MuiDialogTitle,
  Typography,
  Box,
  IconButton,
  withStyles,
  makeStyles
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CloseIcon from '@material-ui/icons/Close'

import { DialogLayout } from '../../utils/styled'

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(1)
  }
}))(({ children, classes }) => {
  return <MuiDialogTitle className={classes.root}>{children}</MuiDialogTitle>
})

const customeStyles = makeStyles((theme) => ({
  paper: {
    ...theme.pollResultModal.paper
  },
  iconButton: {
    ...theme.pollResultModal.iconButton
  },
  myTicketPaper: {
    maxWidth: '500px',
    maxHeight: '500px',
    minHeight: '500px',
    ...theme.pollResultModal.paper,
    position: 'relative'
  },
  titleText: {
    marginLeft: 8
  }
}))

const Dialog = ({
  icon,
  open,
  handleClose,
  title,
  children,
  error,
  showCloseIcon,
  variant
}) => {
  const customStyle = customeStyles()
  return (
    <DialogLayout
      fullWidth
      aria-labelledby="alert-dialog"
      onClose={handleClose}
      open={open}
      classes={{
        paper:
          variant === 'myTickets'
            ? customStyle.myTicketPaper
            : customStyle.paper
      }}
    >
      <DialogTitle disableTypography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h3" color={error ? 'error' : 'inherit'}>
            {icon && <FontAwesomeIcon icon={['far', icon]} />}
            <span className={customStyle.titleText}>{title}</span>
          </Typography>
          {showCloseIcon && (
            <IconButton
              size="medium"
              aria-label="close"
              className={customStyle.iconButton}
              onClick={handleClose}
            >
              <CloseIcon style={{ fontSize: '24px' }} />
            </IconButton>
          )}
        </Box>
      </DialogTitle>
      {children}
    </DialogLayout>
  )
}

export default Dialog
