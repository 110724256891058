import { FC, ReactNode, useEffect, useRef, useState } from 'react'

export interface DoNotRenderWhenOffscreen {
  children: ReactNode
}

/**
 * Prevents rendering children components when they are offscreen.
 * In order to reliably and performantly detect whether the component
 * is offscreen or not, it will fill the offset parent, i.e.,
 * it will be rendered with `position: absolute; inset: 0;`.
 */
export const DoNotRenderWhenOffscreen: FC<DoNotRenderWhenOffscreen> = (
  props
) => {
  const div = useRef<HTMLDivElement>(null)
  const [intersecting, setIntersecting] = useState(false)
  useEffect(() => {
    if (!div.current) {
      return
    }
    // Use an intersection observer to hide the element when it's offscreen
    const observer = new IntersectionObserver((entries) => {
      setIntersecting(entries[0].isIntersecting)
    })
    observer.observe(div.current)
    return () => {
      observer.disconnect()
    }
  }, [])
  return (
    <div
      style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
      ref={div}
    >
      {intersecting ? props.children : null}
    </div>
  )
}
