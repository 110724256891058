import React, { useContext, useRef } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { makeStyles, Badge, Typography, Box } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AppContext from '../../../Services/AppContext'
import EventContext from '../../../Services/EventContext'

const useStyles = makeStyles((theme) => ({
  badgeRoot: {
    width: '100%'
  },
  badge: {
    top: '-3px',
    right: '-13px'
  }
}))

const PollsLabel = ({ icon, currentTab, polls }) => {
  const classes = useStyles()
  const eventContext = useContext(EventContext.Context)
  const appContext = useContext(AppContext.Context)
  const idsRef = useRef(null)

  const setBadgeShow = (ids, show = false) => {
    idsRef.current = ids
    let action = show

    if (currentTab === 'Poll') {
      action = false
    }

    eventContext.actions.setIsShowBadgeOnPollTab(action)
  }

  const comparisonIds = (polls) => {
    if (!idsRef.current) {
      setBadgeShow(polls, true)
    } else {
      const intersecIds = idsRef.current.filter((id) => polls.includes(id))

      if (intersecIds.length < polls.length) {
        setBadgeShow(polls, true)
      } else if (intersecIds.length < idsRef.current.length) {
        setBadgeShow(polls)
      }
    }
  }

  const handleBadgeShow = (polls) => {
    if (polls.length > 0) {
      comparisonIds(polls)
    } else if (idsRef.current && idsRef.current.length > 0) {
      setBadgeShow(null)
    }
  }

  useDeepCompareEffect(() => {
    handleBadgeShow(polls)
  }, [polls])

  const invisible = polls.length === 0 || !eventContext.isShowBadgeOnPollTab

  return (
    <Badge
      invisible={invisible}
      classes={{ root: classes.badgeRoot, badge: classes.badge }}
      overlap="circular"
      color="secondary"
      variant="dot"
    >
      <Box display="flex" gridGap="8px">
        <FontAwesomeIcon icon={icon} />
        {currentTab === 'Poll' && appContext.isToggleTabMenus && (
          <Typography>
            <b>Polls</b>
          </Typography>
        )}
      </Box>
    </Badge>
  )
}

export default PollsLabel
