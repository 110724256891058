import React, { useEffect, useContext } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Alert } from '@material-ui/lab'
import AppContext from '../../Services/AppContext'
import { useStyles, CustomButton, CustomInput } from '../../utils/styled'

const SignUpComponent = () => {
  const classes = useStyles()
  const appContext = useContext(AppContext.Context)
  const { control, handleSubmit, register, errors } = useForm()

  useEffect(() => {
    register({ name: 'email' }, { required: true })
    register({ name: 'password' }, { required: true })
  })

  useEffect(() => {
    if (appContext.hasOwnProperty('isSignedIn') && appContext.isSignedIn) {
      if (appContext.redirectTo) {
        appContext.actions.navigateRedirectUrl()
      } else {
        appContext.actions.navigateToHome()
      }
    }
  }, [appContext.isSignedIn])

  const onSubmit = (data) => {
    appContext.actions.signUpWithMail(data)
  }

  const renderErrorMessages = (errors) => {
    if (errors) {
      return (
        <React.Fragment>
          <Alert variant="filled" severity="error">
            {errors.message}
          </Alert>
          <div className={classes.spacerXs}></div>
        </React.Fragment>
      )
    }
  }

  return (
    <React.Fragment>
      <h1 className={classes.textCenter}>Create Your Account</h1>
      {renderErrorMessages(appContext.errors)}
      <form
        className=""
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          as={CustomInput}
          control={control}
          variant="outlined"
          size="small"
          name="displayName"
          defaultValue=""
          label="Full name"
          error={!!errors.displayName}
          className={errors.displayName ? 'has-error' : ''}
        />
        <Controller
          as={CustomInput}
          name="email"
          control={control}
          variant="outlined"
          size="small"
          defaultValue=""
          type="email"
          label="Email Address"
          className={classes.mt2}
          error={!!errors.email}
        />
        <Controller
          as={CustomInput}
          control={control}
          variant="outlined"
          size="small"
          name="password"
          defaultValue=""
          label="Password"
          type="password"
          className={classes.mt2}
          error={!!errors.password}
        />
        <CustomButton
          variant="contained"
          fullWidth
          color="primary"
          className={classes.mt2}
          type="submit"
        >
          Create Account
        </CustomButton>
      </form>
    </React.Fragment>
  )
}

export default SignUpComponent
