import React, { useContext } from 'react'
import {
  makeStyles,
  Typography,
  Box,
  Divider,
  useTheme,
  useMediaQuery,
  CircularProgress
} from '@material-ui/core'
import { navigate, useLocation } from '@reach/router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AppContext from '../../Services/AppContext'
import DialogLayout from './DialogLayout'
import { DialogContent, CustomButton, variables } from '../../utils/styled'
import { GetUserTokenFromStorage } from '../../utils/helper'
import PathHelper from '../../utils/PathHelper'

const useCustomStyles = makeStyles((theme) => ({
  dialogContent: {
    width: '100%',
    position: 'relative'
  },
  content: {
    textAlign: 'center'
  },
  buttonLoginWrapper: {
    marginTop: theme.spacing(3),
    ...theme.redeemTicket.buttonByThemeWrapper
  },
  buttonLogin: {
    width: '150px'
  },
  buttonBgcolorBytheme: {
    ...theme.redeemTicket.buttonByTheme,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '96px'
    }
  },
  noTicketsWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: '40%',
    transform: 'translateY(-50%)',
    marginLeft: '32px',
    marginRight: '32px'
  },
  ticketWrapper: {
    ...theme.redeemTicket.ticketWrapper,
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: '12px',
    display: 'flex',
    minHeight: '94px',
    alignItems: 'center'
  },
  ticketIcon: {
    fontSize: theme.spacing(3),
    padding: theme.spacing(2),
    borderRadius: '12px',
    ...theme.redeemTicket.ticketIcon
  },
  getTicketWrapper: {
    textAlign: 'center',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    '& a': {
      textDecoration: 'none'
    }
  },
  getTicketButton: {
    ...theme.redeemTicket.ticketWrapper
  },
  ticketsList: {
    display: 'flex',
    flexDirection: 'column',
    gridGap: '8px',
    height: '286px',
    overflowY: 'auto'
  },
  loadingWrapper: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}))

const GetTickets = ({ eventpopId }) => {
  const customClasses = useCustomStyles()

  return (
    <Box className={customClasses.getTicketWrapper}>
      <Divider />
      <Box my="24px">
        <a
          href={PathHelper.event.eventPopEventDetail(eventpopId)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <CustomButton
            color="primary"
            variant="outlined"
            className={customClasses.getTicketButton}
          >
            <Typography variant="h6">
              <strong>Get Ticket</strong>
            </Typography>
          </CustomButton>
        </a>
        <Typography variant="h6" color="textSecondary">
          This will take you to www.eventpop.me
        </Typography>
      </Box>
    </Box>
  )
}

const Login = () => {
  const customClasses = useCustomStyles()
  const appContext = useContext(AppContext.Context)
  const { pathname } = useLocation()
  const handdleLoginWithEventpop = () => {
    appContext.actions.setRedirectUrl(pathname)
    const loginUrl = `${process.env.REACT_APP_EP_OA_AUTH_URL}?client_id=${process.env.REACT_APP_EVENTPOP_APP_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code`
    navigate(loginUrl)
  }

  return (
    <Box className={customClasses.content}>
      <Box className={customClasses.noTicketsWrapper}>
        <Box mb="8px">
          <Typography variant="h3">
            <strong>Log in to see tickets</strong>
          </Typography>
        </Box>
        <Typography variant="h5" color="textSecondary">
          Once you log in, you’ll find the ticket you purchased from Eventpop
          for this event here.
        </Typography>
        <Box className={customClasses.buttonLoginWrapper}>
          <CustomButton
            onClick={handdleLoginWithEventpop}
            variant="contained"
            classes={{
              root: `${customClasses.buttonLogin} ${customClasses.buttonBgcolorBytheme}`
            }}
          >
            <Typography variant="h6">
              <strong>Login</strong>
            </Typography>
          </CustomButton>
        </Box>
      </Box>
    </Box>
  )
}

const NoTickets = ({ eventpopId }) => {
  const customClasses = useCustomStyles()

  return (
    <Box className={customClasses.content}>
      <Box className={customClasses.noTicketsWrapper}>
        <Typography variant="h3">
          <strong>No tickets</strong>
        </Typography>
        <Typography variant="h5" color="textSecondary">
          You’ll find the ticket you purchased from Eventpop for this event
          here.
        </Typography>
      </Box>
      {eventpopId && <GetTickets eventpopId={eventpopId} />}
    </Box>
  )
}

const ListTickets = ({ tickets, eventpopId }) => {
  const customClasses = useCustomStyles()
  const appContext = useContext(AppContext.Context)
  const theme = useTheme()
  const xsSizeDown = useMediaQuery(theme.breakpoints.down('xs'))

  const handleJoinEventFromTicket = (url) => {
    appContext.actions.setRedirectUrl(url)
    navigate(PathHelper.app.redirect())
  }

  return (
    <>
      <Box className={customClasses.ticketsList}>
        {tickets.map((ticket) => {
          return (
            <Box className={customClasses.ticketWrapper} key={ticket.id}>
              <Box className={customClasses.ticketIcon}>
                <FontAwesomeIcon icon={['fas', 'ticket']} />
              </Box>
              <Box
                flexDirection={xsSizeDown ? 'column' : 'row'}
                display="flex"
                justifyContent="space-between"
                width="100%"
                gridRowGap="8px"
              >
                <Box>
                  <Typography variant="h6">{ticket.reference_code}</Typography>
                  <Typography
                    variant="h6"
                    style={{ color: variables.grayDark }}
                  >
                    {ticket.ticket_type.name}
                  </Typography>
                </Box>
                <CustomButton
                  variant="contained"
                  color="primary"
                  classes={{ root: customClasses.buttonBgcolorBytheme }}
                  onClick={() =>
                    handleJoinEventFromTicket(ticket.online_streaming_url)
                  }
                >
                  <Typography variant="h6">
                    <strong>Join Event </strong>
                  </Typography>
                </CustomButton>
              </Box>
            </Box>
          )
        })}
      </Box>
      {eventpopId && <GetTickets eventpopId={eventpopId} />}
    </>
  )
}

function MyTicketEvent({
  isOpenDialog,
  handdleCloseDialog,
  tickets = [],
  loadingTicket,
  eventpopId
}) {
  const { token } = GetUserTokenFromStorage()
  const customClasses = useCustomStyles()

  const RenderMyticket = () => {
    if (!token) {
      return <Login />
    } else if (!tickets || tickets.length === 0) {
      return <NoTickets eventpopId={eventpopId} />
    } else {
      return <ListTickets tickets={tickets} eventpopId={eventpopId} />
    }
  }

  return (
    <Box className={customClasses.dialogLayoutWrapper}>
      <DialogLayout
        open={isOpenDialog}
        handleClose={handdleCloseDialog}
        title="My Tickets"
        showCloseIcon={true}
        variant="myTickets"
      >
        <DialogContent classes={{ root: customClasses.dialogContent }}>
          {loadingTicket ? (
            <Box className={customClasses.loadingWrapper}>
              <CircularProgress color="primary" size={100} />
            </Box>
          ) : (
            <RenderMyticket />
          )}
        </DialogContent>
      </DialogLayout>
    </Box>
  )
}

export default MyTicketEvent
