import React, { useRef, useState, useEffect, useContext, useMemo } from 'react'
import {
  Box,
  Grid,
  FormControlLabel,
  makeStyles,
  Divider,
  RadioGroup,
  createStyles
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { useForm, Controller } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { votePoll, editChoiceVotePoll } from '../../../Services/SessionService'
import PollContext from '../../../Services/PollContext'
import {
  CustomButton,
  PollCheckbox,
  PollRadio,
  variables
} from '../../../utils/styled'
import PollOption from './PollOption'
import Placeholder from './Placeholder'

const customStyles = makeStyles((theme) =>
  createStyles({
    optionRoot: {
      paddingLeft: theme.spacing(2)
    },
    warningRoot: {
      marginTop: '24px',
      alignItems: 'center',
      borderRadius: '8px',
      color: variables.primaryOrange
    },
    selected: {
      ...theme.poll.option.selected,
      borderRadius: 8
    },
    formControlLabel: {
      width: '100%'
    },
    divider: {
      ...theme.divider,
      width: '100%',
      marginBottom: theme.spacing(2)
    }
  })
)

const FormVotePoll = ({
  profileId,
  eventId,
  sessionId,
  isEndedMode = false
}) => {
  const customClasses = customStyles()
  const voted = useRef(false)
  const [isEditChoice, setIsEditChoice] = useState(false)
  const pollContext = useContext(PollContext.Context)
  const { poll } = pollContext
  const mySelected = useMemo(() => {
    return poll.choices.filter((c) => c.voter.includes(profileId))
  }, [poll.choices])

  const selectedChoice = () => {
    if (!poll.multipleChoices) {
      return mySelected.map((choice) => choice.id).join(', ') ?? ''
    } else {
      const result = {}
      for (const choice of poll.choices) {
        if (choice.voter.includes(profileId)) {
          result[choice.id] = true
        } else result[choice.id] = false
      }
      return result
    }
  }

  const {
    watch,
    handleSubmit,
    register,
    control,
    setValue,
    formState: { isDirty }
  } = useForm({
    defaultValues: {
      choiceIds: {},
      choice: ''
    }
  })
  const watchChoiceIds = watch('choiceIds')
  const watchChoice = watch('choice')

  useEffect(() => {
    if (isEditChoice) {
      if (!poll.multipleChoices) {
        setValue('choice', selectedChoice(), { shouldDirty: false })
      } else {
        setValue('choiceIds', selectedChoice(), { shouldDirty: false })
      }

      voted.current = false
    }
  }, [isEditChoice])

  const handleEditChoice = () => {
    setIsEditChoice(true)
    voted.current = false
  }

  const cancelEditChoice = () => {
    setIsEditChoice(false)
  }

  const onSubmit = async (data) => {
    let ids = []
    if (poll.multipleChoices) {
      ids = Object.entries(data.choiceIds)
        .map((c) => {
          if (c[1] === true) return c[0]
          return null
        })
        .filter((c) => c)
    } else {
      ids = [data.choice]
    }

    if (isEditChoice) {
      await editChoiceVotePoll({
        profileId,
        eventId,
        sessionId,
        pollId: poll.id,
        choicesIds: ids
      })
      setIsEditChoice(false)
    } else {
      await votePoll({
        profileId,
        pollId: poll.id,
        choicesIds: ids,
        eventId,
        sessionId
      })
    }
    voted.current = true
  }
  const buttonTemplate = () => {
    let disabled = true

    if (!poll.multipleChoices && watchChoice) {
      disabled = false
    } else if (watchChoiceIds) {
      for (const value of Object.values(watchChoiceIds)) {
        if (value === true) {
          disabled = false
          break
        }
      }
    }

    return (
      <Grid container spacing={1}>
        {isEditChoice ? (
          <>
            <Grid item xs={6}>
              <CustomButton
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() => cancelEditChoice()}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={6}>
              <CustomButton
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                disabled={disabled || !isDirty || voted.current || disabled}
              >
                Submit
              </CustomButton>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <CustomButton
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={disabled || voted.current}
            >
              Submit
            </CustomButton>
          </Grid>
        )}
      </Grid>
    )
  }

  const getVotedIds = (choices) => {
    return choices
      .map((c) => {
        if (c.voter.includes(profileId)) return c.id
        return null
      })
      .filter((c) => c)
  }

  const votedIds = getVotedIds(poll.choices)

  const checkSelectedChoice = (choice) => {
    if (watchChoiceIds[`${choice.id}`] === true) return customClasses.selected
    else if (watchChoiceIds[`${choice.id}`] === false) {
      return null
    } else if (votedIds.includes(choice.id)) return customClasses.selected

    return null
  }

  return (
    <React.Fragment>
      <Box mb={1}>
        {(votedIds.length > 0 || isEndedMode) && !isEditChoice ? (
          <Placeholder
            mySelected={mySelected}
            poll={poll}
            handleEditChoice={handleEditChoice}
            sessionId={sessionId}
          />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            {poll.multipleChoices ? (
              poll.choices
                .sort((a, b) => a.position - b.position)
                .map((choice, idx) => {
                  return (
                    <Box key={idx} width="100%">
                      <Box
                        className={`${
                          customClasses.optionRoot
                        } ${checkSelectedChoice(choice)}`}
                        mt={idx === 0 ? 0 : '12px'}
                        data-testid={`choice-${idx}`}
                      >
                        <FormControlLabel
                          classes={{
                            root: customClasses.formControlLabel,
                            label: customClasses.formControlLabel
                          }}
                          control={
                            <PollCheckbox
                              defaultChecked={choice.voter.includes(profileId)}
                            />
                          }
                          name={`choiceIds[${choice.id}]`}
                          inputRef={register}
                          label={
                            <PollOption
                              imageRef={choice.imageRef}
                              label={choice.name}
                            />
                          }
                          disabled={isEndedMode}
                        />
                      </Box>

                      {choice.imageRef && idx < poll.choices.length - 1 && (
                        <Divider />
                      )}
                    </Box>
                  )
                })
            ) : (
              <Controller
                rules={{ required: true }}
                control={control}
                name="choice"
                defaultValue={'C-HNKJQB'}
                as={
                  <RadioGroup>
                    {poll.choices
                      .sort((a, b) => a.position - b.position)
                      .map((choice, idx) => {
                        return (
                          <Box key={idx} width="100%">
                            <Box
                              className={`${customClasses.optionRoot} ${
                                watchChoice === choice.id &&
                                customClasses.selected
                              }`}
                              mt={idx === 0 ? 0 : '12px'}
                              data-testid={`choice-${idx}`}
                            >
                              <FormControlLabel
                                key={idx}
                                classes={{
                                  root: customClasses.formControlLabel,
                                  label: customClasses.formControlLabel
                                }}
                                value={choice.id}
                                control={<PollRadio />}
                                label={
                                  <PollOption
                                    imageRef={choice.imageRef}
                                    label={choice.name}
                                  />
                                }
                                disabled={isEndedMode}
                              />
                            </Box>
                            {choice.imageRef &&
                              idx < poll.choices.length - 1 && <Divider />}
                          </Box>
                        )
                      })}
                  </RadioGroup>
                }
              />
            )}
            {!poll.isCanEditChoice && (
              <Alert
                classes={{
                  root: customClasses.warningRoot
                }}
                severity="warning"
                iconMapping={{
                  warning: (
                    <FontAwesomeIcon icon={['fal', 'info-circle']} size="xs" />
                  )
                }}
              >
                After submitting the answer, You will no longer be able to
                change.
              </Alert>
            )}
            <Box mt={!poll.isCanEditChoice ? '12px' : '24px'}>
              {buttonTemplate(poll.type, isEndedMode)}
            </Box>
          </form>
        )}
      </Box>
    </React.Fragment>
  )
}

export default FormVotePoll
